import { Input, Select } from 'antd';
import { useUser } from 'contexts/userContext';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagIcon from '../../components/Styled/Flag';
import { COUNTRIES_LIST } from '../../constants/countries';
import { COUNTRIES_PHONE_CODE } from '../../constants/countries-phone-code';
import getAddress from '../../services/getAddress';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const usePersonalAddressInputs = (initialValues = {}) => {
  const [foundZipcode, setFoundZipcode] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [birthDate, setBirthDate] = useState({
    day: '',
    month: '',
    year: '',
  });

  let dateInitialValues = '';
  let monthInitialValues = '';
  let yearInitialValues = '';
  let yearsOptions = [];

  for (let i = new Date().getFullYear() - 20; i >= 1900; i -= 1) {
    yearsOptions.push(i);
  }

  if (initialValues && typeof initialValues.birthDate === 'string') {
    const [year, month, date] = initialValues.birthDate.split('-');
    dateInitialValues = date || '';
    monthInitialValues = month || '';
    yearInitialValues = year || '';
  } else if (initialValues && typeof initialValues.birthDate === 'object') {
    const momentDate = initialValues.birthDate.toString();
    const [year, month, date] = momentDate.split('-');
    dateInitialValues = date || '';
    monthInitialValues = month || '';
    yearInitialValues = year || '';
  }

  const { registerFieldsHelper, setRegisterFieldsHelper } = useUser();
  const { t } = useTranslation();

  function calculateAge(year, month, day) {
    if (!year || !month || !day) return 0;

    const birthDateComplete = new Date(`${year}-${month}-${day}`);
    const today = new Date();
    let age = today.getFullYear() - birthDateComplete.getFullYear();
    const m = today.getMonth() - birthDateComplete.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDateComplete.getDate())) {
      age -= 1;
    }
    return age;
  }

  const checkDate = (year, month, day) => {
    const isValidDate = moment(
      `${year}-${month}-${day}`,
      'YYYY-MM-DD'
    ).isValid();

    return isValidDate;
  };

  const validateDate = ({ month, day, year }) => {
    const inputYear = year || birthDate?.year || yearInitialValues;
    const inputMonth = birthDate?.month || month || monthInitialValues;
    let inputDay = birthDate?.day || day || dateInitialValues;
    if (inputDay && inputDay.length === 1) {
      inputDay = `0${inputDay}`;
    }
    if (!inputYear || !inputMonth || !inputDay) return;
    const isValidDate = checkDate(inputYear, inputMonth, inputDay);

    if (!isValidDate) {
      setRegisterFieldsHelper({
        ...registerFieldsHelper,
        isAdult: true,
        isValidDate,
      });
      return;
    }

    const age = calculateAge(inputYear, inputMonth, inputDay);

    setRegisterFieldsHelper({
      ...registerFieldsHelper,
      isAdult: age >= 18,
      isValidDate,
    });
  };

  const handleMonthChange = (e) => {
    setBirthDate({
      ...birthDate,
      month: e,
    });

    validateDate({ month: e, day: birthDate?.day, year: birthDate?.year });
  };

  const handleYearChange = (e) => {
    setBirthDate({
      ...birthDate,
      year: e,
    });
    validateDate({
      month: birthDate?.month,
      day: birthDate?.day,
      year: e,
    });
  };

  const handleZipcodeChange = async (cep) => {
    setIsSearching(true);
    getAddress({ cep })
      .then((response) => {
        setFoundZipcode(response);
      })
      .catch(() => {
        setFoundZipcode(null);
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  const inputs = [
    {
      name: 'name',
      label: t('guides.name'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.nameRequired'),
      col: {
        xs: 12,
        sm: 12,
        lg: 12,
      },
    },
    {
      name: 'surname',
      label: t('guides.surname'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.surnameRequired'),
      col: {
        xs: 12,
        sm: 12,
        lg: 12,
      },
    },
    {
      name: 'day',
      className: 'is-number-input',
      label: t('guides.birthday'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.dayRequired'),
      placeholder: t('guides.day'),
      input: (
        <Input
          maxLength={2}
          className="is-number-input"
          placeholder={t('guides.day')}
          onBlur={(e) =>
            validateDate({
              month: birthDate?.month,
              day: e.target.value,
              year: birthDate?.year,
            })
          }
          onChange={(e) => setBirthDate({ ...birthDate, day: e.target.value })}
        />
      ),
      col: {
        xs: 8,
        sm: 8,
        lg: 3,
      },
    },

    {
      name: 'month',
      label: ' ',
      isRequired: true,
      placeholder: t('guides.month'),
      col: {
        xs: 8,
        sm: 8,
        lg: 4,
      },
      input: (
        <Select
          placeholder={t('guides.month')}
          onChange={(value) => handleMonthChange(value)}
        >
          <Select.Option value="01">{t('months.January')}</Select.Option>
          <Select.Option value="02">{t('months.February')}</Select.Option>
          <Select.Option value="03">{t('months.March')}</Select.Option>
          <Select.Option value="04">{t('months.April')}</Select.Option>
          <Select.Option value="05">{t('months.May')}</Select.Option>
          <Select.Option value="06">{t('months.June')}</Select.Option>
          <Select.Option value="07">{t('months.July')}</Select.Option>
          <Select.Option value="08">{t('months.August')}</Select.Option>
          <Select.Option value="09">{t('months.September')}</Select.Option>
          <Select.Option value="10">{t('months.October')}</Select.Option>
          <Select.Option value="11">{t('months.November')}</Select.Option>
          <Select.Option value="12">{t('months.December')}</Select.Option>
        </Select>
      ),
      message: t('guides.monthRequired'),
    },
    {
      name: 'year',
      placeholder: t('guides.year'),
      label: ' ',
      isRequired: true,
      hasFeedback: true,
      message: t('guides.yearRequired'),
      input: (
        <Select
          placeholder={t('guides.year')}
          onSelect={(e) => handleYearChange(e)}
        >
          {yearsOptions.map((year) => (
            <Select.Option key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
      ),
      col: {
        xs: 8,
        sm: 8,
        lg: 3,
      },
    },
    {
      name: 'ddi',
      label: t('guides.ddi'),
      isRequired: true,
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="code"
          filterOption={(input, option) =>
            String(option.value)
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_PHONE_CODE.map((phoneCode) => (
            <Select.Option key={phoneCode.iso} value={phoneCode.code}>
              <FlagIcon code={phoneCode.iso.toLocaleLowerCase()} size="lg" />{' '}
              <span className="is-number-input">{phoneCode.code}</span>
            </Select.Option>
          ))}
        </Select>
      ),
      message: t('guides.ddiRequired'),
      col: {
        xs: 10,
        sm: 5,
        lg: 4,
      },
    },
    {
      name: 'mobilePhone',
      label: t('guides.mobilePhone'),
      isRequired: true,
      hasFeedback: true,
      className: 'is-number-input',
      message: t('guides.mobilePhoneRequired'),
      style: {
        height: '52px',
      },
      col: {
        xs: 14,
        sm: 8,
        lg: 5,
      },
    },
    {
      name: 'nationality',
      label: t('guides.nationality'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.nationalityRequired'),
      col: {
        xs: 24,
        sm: 10,
        lg: 5,
      },
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((country) => (
            <Select.Option key={country.id} value={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  const addressInputs = [
    {
      name: 'country',
      label: t('guides.residenceCountry'),
      isRequired: true,
      col: 12,
      message: t('guides.residenceCountryRequired'),
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          onChange={(value, e) => {
            setCountry(e?.children);
          }}
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((country) => (
            <Select.Option key={country.id} value={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'zipcode',
      label: t('guides.zipcode'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.zipcodeRequired'),
      className: 'is-number-input',
      onBlur: (e) => {
        if (country.length && country === 'Brazil') {
          handleZipcodeChange(e.target.value);
          return;
        }
        if (
          !country.length &&
          (initialValues?.country === 1 ||
            initialValues?.address?.country?.id === 1)
        ) {
          handleZipcodeChange(e.target.value);
        }
      },
    },
    {
      name: 'state',
      label: t('guides.state'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.stateRequired'),
      disabled: isSearching,
      value: foundZipcode?.state,
    },
    {
      name: 'city',
      label: t('guides.city'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.cityRequired'),
      value: foundZipcode?.city,
      disabled: isSearching,
    },
    {
      name: 'street',
      label: t('guides.street'),
      isRequired: true,
      col: 12,
      input: (
        <div className="google-input">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            selectProps={{
              defaultInputValue: initialValues?.address?.street,
              placeholder: t('common.searchPlace'),
              onChange: (value) => {
                setAddress({
                  placeId: value.value.place_id,
                  label: value.label,
                });
              },
            }}
            className="google-input"
          />
        </div>
      ),
    },
    {
      name: 'complement',
      label: t('guides.complement'),
      hasFeedback: true,
      col: 12,
    },
  ];

  return {
    inputs,
    addressValue: address,
    addressInputs,
    zipcodeValues: foundZipcode,
    address,
  };
};

export default usePersonalAddressInputs;
