import { authService } from 'services/Auth0Service';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaCard } from 'components/Styled/ContentaCard';
import { ContentaInput } from 'components/Styled/ContentaInput';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { FlagMenu } from 'components/FlagMenu';
import { Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarouselTexts from '../shared/CarouselTexts';
import logo from '../../../assets/images/logo-white.png';
import {
  LoginBoxHeader,
  LoginContainer,
  LoginImage,
  SloganText,
} from '../shared/styled';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = window.innerWidth >= 1024;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({ isSuccess }) => {
    api.open({
      message: t(`resetPassword.${isSuccess ? 'success' : 'error'}.title`),
      description: t(
        `resetPassword.${isSuccess ? 'success' : 'error'}.description`
      ),
      showProgress: true,
      pauseOnHover: true,
    });
  };

  const resetPassword = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      authService.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email: form.getFieldValue('username'),
        },
        (err, result) => {
          if (err) {
            console.error('Error sending password reset email:', err);
            reject(err);
            openNotification({ isSuccess: false });
            setLoading(false);
          } else {
            openNotification({ isSuccess: true });
            resolve(result);
            setLoading(false);
          }
        }
      );
    });
  };

  return (
    <LoginContainer>
      {contextHolder}
      <div className="login-traveller__content login-traveller">
        <LoginImage src={logo} alt="Contenta 360 logo" />
        <ContentaCard className="login-form">
          <LoginBoxHeader>
            <h1>recuperar senha</h1>
            <FlagMenu isLogin />
          </LoginBoxHeader>

          <Form form={form} layout="vertical">
            <ContentaInputGroup>
              <Form.Item
                label={t('login.user')}
                name="username"
                className="initial-font-transform"
                rules={[
                  { required: true, message: t('login.usernameRequired') },
                ]}
              >
                <ContentaInput
                  placeholder="email@email.com"
                  onPressEnter={resetPassword}
                />
              </Form.Item>
            </ContentaInputGroup>

            <ContentaButtonPrimary
              className="w-100"
              loading={loading}
              onClick={resetPassword}
              disabled={loading}
            >
              {t('login.login')}
            </ContentaButtonPrimary>
          </Form>
          <SloganText>{t('login.sloganTraveler')}</SloganText>
        </ContentaCard>
      </div>
      {isDesktop && (
        <div className="login-traveller__carousel">
          <CarouselTexts />
        </div>
      )}
    </LoginContainer>
  );
};

export default ForgotPassword;
