import { Divider } from 'antd';

import SpecialtiesInput from './SpecialtiesInput';
import TellYourStory from './TellYourStory';

export function FourthStep({ updateFourthStepForm }) {
  const handleChange = (value) => {
    updateFourthStepForm({
      specialties: value,
    });
  };

  return (
    <>
      <SpecialtiesInput
        handleChange={handleChange}
        updateFourthStepForm={updateFourthStepForm}
      />

      <Divider />
      <TellYourStory updateFourthStepForm={updateFourthStepForm} />
    </>
  );
}
