import * as React from 'react';

function IconUSA(props) {
  return (
    <svg
      width={22}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width={22} height={16} rx={2} fill="#fff" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0h9.429v7.467H0V0Z"
            fill="#1A47B8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.429 0v1.067H22V0H9.429Zm0 2.133V3.2H22V2.133H9.429Zm0 2.134v1.066H22V4.267H9.429Zm0 2.133v1.067H22V6.4H9.429ZM0 8.533V9.6h22V8.533H0Zm0 2.134v1.066h22v-1.066H0ZM0 12.8v1.067h22V12.8H0Zm0 2.133V16h22v-1.067H0Z"
            fill="#F93939"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.048 1.067v1.066h1.047V1.067H1.048Zm2.095 0v1.066H4.19V1.067H3.143Zm2.095 0v1.066h1.048V1.067H5.238Zm2.095 0v1.066h1.048V1.067H7.333ZM6.286 2.133V3.2h1.047V2.133H6.286Zm-2.096 0V3.2h1.048V2.133H4.19Zm-2.095 0V3.2h1.048V2.133H2.095ZM1.048 3.2v1.067h1.047V3.2H1.048Zm2.095 0v1.067H4.19V3.2H3.143Zm2.095 0v1.067h1.048V3.2H5.238Zm2.095 0v1.067h1.048V3.2H7.333ZM1.048 5.333V6.4h1.047V5.333H1.048Zm2.095 0V6.4H4.19V5.333H3.143Zm2.095 0V6.4h1.048V5.333H5.238Zm2.095 0V6.4h1.048V5.333H7.333ZM6.286 4.267v1.066h1.047V4.267H6.286Zm-2.096 0v1.066h1.048V4.267H4.19Zm-2.095 0v1.066h1.048V4.267H2.095Z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width={22} height={16} rx={2} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconUSA;
