import { Divider } from 'antd';
import RatingSingleStar from 'components/RatingSingleStar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssessmentService from 'services/AssessmentService';
import { MentorTabContainer } from './styles';

function EvaluateList({ ratings }) {
  return ratings.map((rating) => (
    <>
      <div className="mentor-tab__evaluation" key={rating.id}>
        <header className="mentor-tab__evaluation-header">
          <span className="mentor-tab__evaluation-header__user-name">
            {rating?.traveler || rating?.advisor}
          </span>
          <RatingSingleStar rating={rating.note} />
        </header>
        <p>{rating.description}</p>
      </div>
      <Divider />
    </>
  ));
}

function Ratings({ mentor }) {
  const [ratings, setRatings] = useState([]);
  const { t } = useTranslation();

  const getRating = async () => {
    try {
      const res = await AssessmentService.getAssessmentList({
        userId: mentor.id,
        page: 0,
        size: 10,
      });
      setRatings(res.content);
    } catch (error) {
      setRatings([]);
    }
  };

  useEffect(() => {
    getRating();
  }, []);
  return (
    <MentorTabContainer>
      <div className="mentor-tab__content">
        {ratings?.length > 0 ? (
          <EvaluateList ratings={ratings} />
        ) : (
          <p>{t('common.noRate')}</p>
        )}
      </div>
    </MentorTabContainer>
  );
}

export default Ratings;
