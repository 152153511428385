const YoutubeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.85}
      fillRule="evenodd"
      d="M11.999 6c-1.515 0-3.652.068-6.417.206a2 2 0 0 0-1.894 1.83 47.653 47.653 0 0 0-.189 3.926c0 1.123.064 2.449.194 3.981a2 2 0 0 0 1.862 1.827c2.352.154 4.5.23 6.444.23 1.945 0 4.093-.076 6.446-.23a2 2 0 0 0 1.863-1.828c.128-1.52.191-2.846.191-3.98 0-1.12-.061-2.428-.187-3.925a2 2 0 0 0-1.893-1.831C15.65 6.068 13.514 6 11.999 6ZM5.482 4.208C8.259 4.07 10.434 4 11.999 4c1.565 0 3.74.07 6.52.208a4 4 0 0 1 3.786 3.662c.129 1.537.194 2.902.194 4.092 0 1.205-.067 2.588-.198 4.148a4 4 0 0 1-3.725 3.655c-2.389.157-4.581.235-6.577.235-1.996 0-4.187-.078-6.574-.235A4 4 0 0 1 1.7 16.112a49.867 49.867 0 0 1-.201-4.15c0-1.18.066-2.546.196-4.094a4 4 0 0 1 3.787-3.66Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.85}
      fillRule="evenodd"
      d="M9.5 9.805c0-1.116 1.267-1.83 2.226-1.186l3.298 2.218c.83.558.847 1.812-.009 2.377l-3.298 2.18c-.927.613-2.217-.04-2.217-1.191V9.805Zm2 1.072 1.697 1.142L11.5 13.14v-2.263Z"
      clipRule="evenodd"
    />
  </svg>
);
export default YoutubeIcon;
