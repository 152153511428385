import { useState } from 'react';

function useFilterMentors() {
  const [tagFilters, setTagFilters] = useState([]);

  function toggleTagFilter(tag) {
    // todo Temporarily disabled, because we only want to allow one tag filter at a time
    // if (typeof tag === 'object') {
    //   const index = tagFilters.findIndex((obj) => obj.id === tag.id);
    //   if (index > -1) {
    //     setTagFilters(tagFilters.filter((obj) => obj.id !== tag.id));

    //     return;
    //   }
    //   setTagFilters([...tagFilters, tag]);
    // }

    if (!tag) return;
    let index = tagFilters.findIndex((obj) => obj.id === tag.id);
    if (tagFilters.includes(tag.id) || index > -1) {
      setTagFilters([]);
    } else {
      setTagFilters([tag]);
    }
  }

  return {
    tagFilters,
    toggleTagFilter,
  };
}

export default useFilterMentors;
