import HttpClient from './utils/HttpClient';

class SpecialtiesService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getSpecialties() {
    return this.httpClient.get('/specialty');
  }

  async getTopics() {
    return this.httpClient.get('/topic');
  }
}

export default new SpecialtiesService();
