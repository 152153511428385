import { ClockCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PaymentService from 'services/PaymentService';

import { ConfirmedPaymentContainer } from '../styles';
import { useScheduleBriefingContext } from '../useScheduleContext';
import ScrollToTop from 'components/ScrollToTop';

function ProcessingPayment() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { redirectSuccessPayment, redirectPayment, setPaidBooking } =
    useScheduleBriefingContext();
  const [isMounted, setIsMounted] = useState(true);

  const checkPaymentStatus = async () => {
    if (!state?.protocol || !isMounted) return;

    setTimeout(async () => {
      const { status, bookingId } = await PaymentService.checkStatus({
        protocolId: state?.protocol,
      });
      if (status === 'PAID') {
        setPaidBooking(bookingId);
        redirectSuccessPayment();
      } else if (status === 'FAILED') {
        redirectPayment();
      } else {
        checkPaymentStatus();
      }
    }, 5000);
  };

  useEffect(() => {
    checkPaymentStatus();

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <ConfirmedPaymentContainer>
      <ScrollToTop />
      <ClockCircleOutlined
        style={{
          color: '#FFF',
          fontSize: '64px',
          marginBottom: '16px',
        }}
      />
      <h2>{t('traveler.scheduleBriefing.processingPayment.title')}</h2>
      <p>{t('traveler.scheduleBriefing.processingPayment.text')}</p>
    </ConfirmedPaymentContainer>
  );
}

export default ProcessingPayment;
