import { CURRENCIES } from 'constants/currencies';
import { useLanguage } from 'contexts/languageContext';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';
const StyledContentaCurrency = styled(NumericFormat)`
  border: 1px solid rgb(74, 70, 71) !important;
  padding: 0 12px;
  width: calc(100% - 24px);
  width: -webkit-fill-available;
`;

function ContentaCurrencyInput({ currency, ...props }) {
  const { currentLanguage } = useLanguage();

  const decimalSeparator = currentLanguage === 'PT-BR' ? ',' : '.';

  const selectedCurrency =
    CURRENCIES.find((curr) => curr.code === currency)?.symbol || '$';

  return (
    <StyledContentaCurrency
      prefix={`${selectedCurrency} `}
      {...props}
      decimalScale={2}
      decimalSeparator={decimalSeparator}
    />
  );
}

export default ContentaCurrencyInput;
