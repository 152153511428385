export const CONTENTA_LOGIN_BTN = '@contenta-login-btn';
export const CONTENTA_SELECTED_SCHEDULE = '@contenta-selected-schedule';
export const CONTENTA_REDIRECT = '@contenta-redirect';
export const CONTENTA_USER = '@contenta-user';
export const CONTENTA_TOKEN = '@contenta-token';
export const CONTENTA_TIMER_CHECKOUT = '@contenta-timer-checkout';
export const STATE_SCHEDULE = '@contenta-state-schedule';

export const CONTENTA_GIFT_CARD = '@contenta-gift-card';
export const CONTENTA_REGISTER_USER = '@contenta-register-user';
