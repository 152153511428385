import { MEETING_TYPES } from 'constants/types';
import { useUser } from 'contexts/userContext';
import { differenceInHours } from 'date-fns';
import { useState } from 'react';
import BookingService from 'services/BookingService';
import { errorHandler } from 'utils/errorHandler';
import errorLogger from 'utils/errorLogger';
import { successHandler } from 'utils/successHandler';

function useRescheduleAgenda({
  currentScheduleToReschedule = null,
  bookingId = null,
  onClose,
  confirmRescheduleAskedByTravellerAdvisor = false,
}) {
  const { userRole } = useUser();
  const [showCancelAlertModal, setShowCancelAlertModal] = useState(false);
  const [scheduleModalContent, setScheduleModalContent] = useState(null);

  const rescheduleBooking = async (schedule) => {
    try {
      await BookingService.rescheduleBooking({
        bookingId,
        data: {
          startsAt: schedule?.startAt,
          timeZone: schedule?.timeZone,
        },
        role: userRole,
      });
      successHandler({
        message: 'common.successUpdate',
      });
      onClose();
    } catch (error) {
      errorLogger({
        error,
        message: '[USE_RESCHEDULE_AGENDA] error on reschedule booking',
      });
      errorHandler({
        error,
        typeError: 'GENERIC',
        keyMessage: 'rescheduleMeeting',
      });
    }
  };

  const handleCloseCancelAlertModal = () => {
    setShowCancelAlertModal(false);
  };

  const checkCanReschedule = async (newSchedule) => {
    if (!currentScheduleToReschedule) return false;
    if (confirmRescheduleAskedByTravellerAdvisor) {
      rescheduleBooking(newSchedule);
      return;
    }

    const { schedulingType, startAtUTC } = currentScheduleToReschedule;
    const startsAt = new Date(startAtUTC);
    const now = new Date();
    const diff = differenceInHours(startsAt, now);

    if (schedulingType === MEETING_TYPES.online && diff < 48) {
      setScheduleModalContent({
        isOnline: true,
        isLessThan48h: true,
        schedule: newSchedule,
      });
      setShowCancelAlertModal(true);
      return;
    }

    if (schedulingType === MEETING_TYPES.presential && diff < 72) {
      setScheduleModalContent({
        isOnline: false,
        isLessThan72h: true,
        schedule: newSchedule,
      });
      setShowCancelAlertModal(true);
      return;
    }

    rescheduleBooking(newSchedule);
  };

  return {
    checkCanReschedule,
    showCancelAlertModal,
    handleCloseCancelAlertModal,
    scheduleModalContent,
  };
}

export default useRescheduleAgenda;
