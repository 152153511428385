export const LANGUAGES_COUNTRIES = [
  {
    value: 'DE',
    name: 'german',
    key: 'de',
    name_pt: 'alemão',
  },
  {
    key: 'it',
    name: 'italian',
    value: 'IT',
    name_pt: 'italiano',
  },
  {
    value: 'KO',
    key: 'ko',
    name: 'korean',
    name_pt: 'coreano',
  },
  {
    value: 'JA',
    key: 'ja',
    name: 'japanese',
    name_pt: 'japonês',
  },
  {
    value: 'ES',
    key: 'es',
    name: 'spanish',
    name_pt: 'espanhol',
  },
  {
    value: 'PT-BR',
    key: 'pt-BR',
    name: 'portugueseBrazil',
    name_pt: 'português',
  },
  {
    value: 'FR',
    name: 'french',
    key: 'fr',
    name_pt: 'francês',
  },
  {
    value: 'PT-PT',
    name: 'portuguese',
    key: 'pt-PT',
    name_pt: 'português',
  },
  {
    value: 'EN',
    key: 'en-US',
    name: 'english',
    name_pt: 'inglês',
  },
];
