import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const MentorSpecialtiesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 20px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: 16px;
  }

  .mentor-tab__badge {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 30px;
    justify-content: center;
    .ant-badge-count {
      align-items: center;
      background: ${({ theme }) => theme.colors.purple[30]};
      border-radius: 8px;
      display: flex;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-weight: 800;
      height: 28px;
    }
  }
`;
