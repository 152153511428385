import { Row } from 'antd';
import styled, { css } from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

const CommonStyles = css`
  padding: 0 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 24px ${(props) => props.theme.spacing.lg};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 24px ${(props) => props.theme.spacing.xl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding: 24px ${(props) => props.theme.spacing.xxl};
  }
`;

export const MentorDetailsContainer = styled(Row)`
  padding: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 24px ${(props) => props.theme.spacing.lg};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 24px ${(props) => props.theme.spacing.xl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding: 24px ${(props) => props.theme.spacing.xxl};
  }

  .ant-tabs {
    max-width: 100%;
  }

  .interest-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: ${convertPxToRem(16)};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    &.inline {
      flex-direction: row;
      gap: 8px;
    }

    &__title {
      color: ${(props) => props.theme.colors.neutralBlack[20]};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__paragraph {
      font-style: normal;
      font-weight: 400;
      color: ${(props) => props.theme.colors.neutralBlack[20]};

      line-height: 22px;
      letter-spacing: 1.25px;
    }
  }
`;

export const MentorHeader = styled.header`
  ${CommonStyles}
  background-color:  ${({ theme }) => theme.colors.neutralWhite[10]};
  border-top: 1px solid ${({ theme }) => theme.colors.neutralWhite[40]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralWhite[40]};
  width: 100vw;

  h2,
  h3 {
    font-family: ${({ theme }) => theme.fonts.texts};
    color: ${(props) => props.theme.colors.neutralBlack[30]};
    margin: 0;
    font-weight: 700;
  }

  h2 {
    font-size: 32px;
    line-height: 32px;
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
  }
`;
