/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { InputNumber } from 'antd';

export function ContentaInputNumber({ currency, locale, onChange, ...rest }) {
  const currencyFormatter = (selectedCurrOpt) => (value) =>
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: selectedCurrOpt || 'USD',
    }).format(value);

  const currencyParser = (val) => {
    try {
      if (typeof val === 'string' && !val.length) {
        val = '0.0';
      }

      const group = new Intl.NumberFormat(locale)
        .format(1111)
        .replace(/1/g, '');
      const decimal = new Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');

      reversedVal = reversedVal.replace(/[^0-9.]/g, '');

      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <InputNumber
      controls={false}
      formatter={currencyFormatter(currency)}
      parser={currencyParser}
      className="is-number-input"
      onChange={onChange}
      {...rest}
    />
  );
}
