import { ContentaText } from 'components/Styled/ContentaText';
import { useMentor } from '../../mentorContext';
import MentorSpecialties from '../../MentorSpecialties';
import { useTranslation } from 'react-i18next';

const MotivateToBeExpert = () => {
  const { advisor } = useMentor();
  const { t } = useTranslation();

  const { consultRegionDetails, summary } = advisor;
  const videoUrl = advisor?.videoUrl || null;

  return (
    <section>
      {/* video */}
      <video src={videoUrl} controls></video>

      {/* especialidades */}
      <MentorSpecialties />

      {/* about me */}
      <ContentaText color="secondary">{summary}</ContentaText>

      {/* about my area */}
      <ContentaText color="primary" size="large" bold>
        {t('mentorProfile.aboutMyArea')}
      </ContentaText>

      <ContentaText color="secondary">{consultRegionDetails}</ContentaText>
    </section>
  );
};

export default MotivateToBeExpert;
