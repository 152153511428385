import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Card, Skeleton } from 'antd';
import { useLanguage } from 'contexts/languageContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckoutForm from '../CheckoutForm';
import { useUser } from 'contexts/userContext';
import getStripeKey from 'utils/getStripeKey';

function CreditCardSection({
  briefingValue,
  isEditingAddress,
  paymentValue,
  checkoutState,
  totalCheckoutValue,
}) {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [reloadElements, setReloadElements] = useState(false);
  const { currentLanguage } = useLanguage();
  const { whoAmI } = useUser();

  useEffect(() => {
    if (whoAmI?.address) {
      const isBrazilian = checkoutState.advisor.addressCountry.acronym === 'BR';
      setStripePromise(
        loadStripe(getStripeKey({ country: isBrazilian ? 'BR' : 'US' }))
      );
      setClientSecret(checkoutState.clientSecret);
    }
  }, [
    checkoutState.clientSecret,
    checkoutState.country,
    whoAmI?.address?.country?.acronym,
  ]);

  // on totalCheckoutValue change, update Elements
  useEffect(() => {
    if (totalCheckoutValue) {
      setReloadElements(true);
      setTimeout(() => {
        setReloadElements(false);
      }, 300);
    }
  }, [totalCheckoutValue]);

  return (
    <Card>
      <h2 className="card__title">
        {t('traveler.scheduleBriefing.paymentDetails')}
      </h2>
      {reloadElements ? (
        <Skeleton active paragraph={{ rows: 1 }} title />
      ) : (
        stripePromise &&
        clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, locale: currentLanguage }}
          >
            <CheckoutForm
              advisor={checkoutState?.advisor}
              briefingValue={briefingValue}
              isEditingAddress={isEditingAddress}
              paymentValue={paymentValue}
              selectedSchedule={checkoutState?.selectedSchedule}
              totalCheckoutValue={totalCheckoutValue}
            />
          </Elements>
        )
      )}
    </Card>
  );
}

export default CreditCardSection;
