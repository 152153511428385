import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import PaddingContainer from '../../styles/paddingContainer';

export const BreadcrumbContainer = styled(Breadcrumb)`
  ${PaddingContainer};
  padding-bottom: 16px;
  padding-top: 16px;
  margin-top: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  a {
    color: ${(props) => props.theme.colors.neutralBlack[40]};
  }

  li {
    align-items: center;
    display: flex;
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: 1rem;
  }

  .ant-breadcrumb-link a {
    color: ${(props) => props.theme.colors.purple[20]};

    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colors.purple[30]};
    }
  }

  .ant-breadcrumb-separator {
    font-size: 8px;
    position: relative;
    top: 0;
  }
`;
