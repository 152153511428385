import { Form } from 'antd';
import {
  CONTENTA_GIFT_CARD,
  CONTENTA_REDIRECT,
  CONTENTA_SELECTED_SCHEDULE,
  CONTENTA_TIMER_CHECKOUT,
} from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentService from 'services/PaymentService';
import checkIsRegistered from 'utils/checkIsRegistered';
import errorLogger from 'utils/errorLogger';

const TYPES_GIFT = {
  CHOOSE_OPTION: 'wantChooseAdvisor',
  GIFTED_CHOOSE: 'chooseOption',
};

const useGiftCardModal = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isAuthenticated, userCurrency, whoAmI } = useUser();

  const [currentTab, setCurrentTab] = useState('giftCardData');
  const [howToGift, setHowToGift] = useState(null);

  const redirectToNextPage = ({ state }) => {
    const isRegistered = checkIsRegistered({ whoAmI });
    sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, new Date().getTime());
    if (isAuthenticated && isRegistered) {
      navigate('/checkout-gift', { state });
      return;
    } else {
      sessionStorage.setItem(CONTENTA_REDIRECT, '/checkout-gift');
      navigate('/login');
    }
  };

  const createClientSecretStripe = async () => {
    const isRegistered = checkIsRegistered({ whoAmI });
    sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, new Date().getTime());

    if (!isAuthenticated || !isRegistered) {
      sessionStorage.setItem(CONTENTA_REDIRECT, '/');
      navigate('/login');
      return;
    }

    const sessionData = JSON.parse(sessionStorage.getItem(CONTENTA_GIFT_CARD));

    const formData = {
      ...form.getFieldsValue(),
      howToGift,
    };
    const data = {
      name: formData.name || sessionData.name,
      email: formData.email || sessionData.email,
      remark: formData.message || sessionData.message,
      amount: formData.value || sessionData.value,
      currency: userCurrency?.currencyStandard || sessionData.currency,
      scheduledTo: new Date(formData?.sendDate || sessionData.sendDate)
        .toISOString()
        .split('T')[0],
      ...(formData.advisorId && { advisorId: formData.advisorId }),
    };

    try {
      const response = await PaymentService.getClientSecretGiftCard({ data });
      redirectToNextPage({
        state: {
          data,
          clientSecret: response.clientSecret,
          protocol: response.protocol,
        },
      });
    } catch (error) {
      errorLogger({
        error,
        message: `[GiftCardModal] Error creating client secret stripe`,
      });
    }
  };

  const handlePaymentOrChooseOption = ({ data }) => {
    sessionStorage.setItem(
      CONTENTA_GIFT_CARD,
      JSON.stringify({ ...data, withHomeRedirect: true })
    );

    if (howToGift === TYPES_GIFT.CHOOSE_OPTION) {
      navigate('/search-advisor');
      return;
    }

    createClientSecretStripe();
  };

  const handleGiftClickButton = () => {
    const formData = form.getFieldsValue();
    const isEmailOrNameEmpty = !formData.email || !formData.name;

    if (currentTab === 'giftCardData' && isEmailOrNameEmpty) {
      return;
    }

    if (currentTab === 'giftCardData') {
      setCurrentTab('scheduleData');
    } else {
      const formData = {
        ...form.getFieldsValue(),
        howToGift,
      };
      handlePaymentOrChooseOption({ data: formData });
    }
  };

  const checkIsDisabled = () => {
    const formData = form.getFieldsValue();
    if (currentTab === 'scheduleData') {
      return !formData.value || !formData.sendDate;
    }

    return false;
  };

  useEffect(() => {
    const dataFromStorage = JSON.parse(
      sessionStorage.getItem(CONTENTA_GIFT_CARD)
    );
    const isRegistered = checkIsRegistered({ whoAmI });
    if (dataFromStorage?.withHomeRedirect && isRegistered) {
      sessionStorage.setItem(
        CONTENTA_GIFT_CARD,
        JSON.stringify({ ...dataFromStorage, withHomeRedirect: false })
      );
      sessionStorage.removeItem(CONTENTA_REDIRECT);
      sessionStorage.removeItem(CONTENTA_SELECTED_SCHEDULE);
      createClientSecretStripe();
    }
  }, [whoAmI]);

  return {
    currentTab,
    form,
    howToGift,
    checkIsDisabled,
    handleGiftClickButton,
    setCurrentTab,
    setHowToGift,
  };
};

export default useGiftCardModal;
