import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import * as S from './styles';

function SeparatorIcon(props) {
  return (
    <svg
      width={6}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1.207 9.471-.94-.943L3.793 5 .267 1.47 1.21.528l3.523 3.529a1.333 1.333 0 0 1 0 1.885L1.207 9.471Z"
        fill="#868484"
      />
    </svg>
  );
}

/**
 * This function is a React component that renders a breadcrumb navigation bar.
 * @param {Object} items - An array of objects that contains the label and link of each breadcrumb item.
 * @Param {string} items.label - The label of the breadcrumb item.
 * @Param {string} items.link - The link of the breadcrumb item.
 * @returns {JSX.Element} - A breadcrumb navigation bar.
 */
export function ContentaBreadcrumb({ items }) {
  if (!items) {
    return null;
  }

  const { t } = useTranslation();
  const lastItem = items.pop();

  return (
    <S.BreadcrumbContainer separator={<SeparatorIcon />}>
      {items.map((item) => (
        <Breadcrumb.Item key={item?.link}>
          <Link to={item?.link}>{t(item?.label)}</Link>
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item>{t(lastItem?.label)}</Breadcrumb.Item>
    </S.BreadcrumbContainer>
  );
}

ContentaBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};
