import { Radio } from 'antd';
import styled from 'styled-components';

export const ContentaRadio = styled(Radio)`
  .ant-radio {
    &:hover,
    &:focus,
    &:active {
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.purple[30]};
      }
    }
  }
  .ant-radio-inner {
    border: 2px solid #f0f0ef;
  }

  .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.colors.purple[30]};
    display: block;
    height: 24px;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 24px;
  }
`;
