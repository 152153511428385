import { Tabs } from 'antd';
import RedirectLoader from 'components/RedirectingLoader';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import TablesContainer from './Components/TablesContainer';
import * as S from './styles';

function AdminPrices() {
  const { t } = useTranslation();

  const items = [
    {
      key: 'USD',
      label: 'USD',
      children: <TablesContainer currency="USD" />,
    },
    {
      key: 'BRL',
      label: 'BRL',
      children: <TablesContainer currency="BRL" />,
    },
  ];

  return (
    <Suspense fallback={<RedirectLoader />}>
      <S.Container>
        <header>
          <h1>{t('adminPages.prices.title')}</h1>
        </header>
        <main>
          <Tabs defaultActiveKey="USD" items={items} />
        </main>
      </S.Container>
    </Suspense>
  );
}

export default AdminPrices;
