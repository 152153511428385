import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import useCurrencyParse from 'hooks/useCurrencyParse';
import { useTranslation } from 'react-i18next';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import isMobile from 'utils/isMobile';
import { useMentor } from '../mentorContext';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import * as S from './styles';

const ScheduleMeetCard = ({ info }) => {
  const { t } = useTranslation();
  const { parseCurrencyValue } = useCurrencyParse();
  const { openModal } = useMentor();

  return (
    <div className="schedule-meet-card">
      <span className="schedule-meet-card__title">{t(info.meetingType)}</span>
      <span className="schedule-meet-card__price">
        {parseCurrencyValue(info.value)}
      </span>
      <span className="schedule-meet-card__type-price">
        {t(info.valueType)}
      </span>

      <div className="schedule-meet-card__button">
        <ContentaButtonPrimary onClick={openModal}>
          {t('mentorProfile.bookTime')}
        </ContentaButtonPrimary>
      </div>
    </div>
  );
};

const MentorScheduleMeet = () => {
  const { advisor } = useMentor();

  const options = [
    advisor?.valueHourVirtual,
    advisor?.valueFourHours,
    advisor?.valueSixHours,
    advisor?.valueEightHours,
    advisor?.valueTenHours,
  ].filter(Boolean);

  const populateOptions = () => {
    return options.map((option, i) => {
      const isFirst = i === 0;
      const labelsIndex = {
        0: 'mentorProfile.time.package1h',
        1: 'mentorProfile.time.package4h',
        2: 'mentorProfile.time.package6h',
        3: 'mentorProfile.time.package8h',
        4: 'mentorProfile.time.package10h',
      };

      return {
        meetingType: isFirst
          ? 'mentorProfile.meetingType.online'
          : 'mentorProfile.meetingType.presential',
        value: option,
        valueType: labelsIndex[i],
      };
    });
  };

  const cards = populateOptions();

  const swiperModules = isMobile() ? [] : [Navigation, Pagination];

  return (
    <S.ScheduleMeetCardContainer>
      <Swiper
        spaceBetween={16}
        slidesPerView={isMobile() ? 1.2 : 2.7}
        navigation
        modules={swiperModules}
        pagination={{
          dynamicBullets: !isMobile(),
        }}
        className="custom-swiper"
      >
        {cards.map((card, i) => (
          <SwiperSlide className={i === cards.length - 1 ? '--last' : ''}>
            <ScheduleMeetCard info={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </S.ScheduleMeetCardContainer>
  );
};

export default MentorScheduleMeet;
