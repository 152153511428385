import AdminPrices from 'pages/Admin/Prices';
import { lazy } from 'react';
import { Route } from 'react-router-dom';
const MentorList = lazy(() =>
  import(/* webpackChunkName: "adminChunk" */ '../pages/Admin/MentorList')
);
const MentorDetail = lazy(() =>
  import(/* webpackChunkName: "adminChunk" */ '../pages/Admin/MentorDetail')
);

export default [
  <Route path="admin" exact element={<MentorList />} key={'admin-dashboard'} />,
  <Route
    path="admin/advisor/:id"
    exact
    element={<MentorDetail />}
    key={'admin-advisor-detail'}
  />,
  <Route
    path="admin/prices"
    exact
    element={<AdminPrices />}
    key={'admin-prices'}
  />,
];
