import { ContainerApp } from 'components/ContainerApp';
import { CONTENTA_GIFT_CARD } from 'constants/storages-values';
import { Outlet, useNavigate } from 'react-router-dom';

export function GiftRoute({ children }) {
  const navigate = useNavigate();

  const giftDataStorage = sessionStorage.getItem(CONTENTA_GIFT_CARD);
  const giftData = giftDataStorage ? JSON.parse(giftDataStorage) : null;

  if (!giftData) {
    navigate('/');
  }

  return <ContainerApp>{children || <Outlet />}</ContainerApp>;
}
