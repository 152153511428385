import { Input } from 'antd';
import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';
import commonTexts from 'styles/commonTexts';

const { Search } = Input;

export const Container = styled.main`
  .search-header {
    align-items: flex-start;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    margin: 24px 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: center;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  .search-home__content {
    margin-top: ${({ isSearchPage }) => (isSearchPage ? '0' : '24px')};
    margin-bottom: 0 !important;
  }

  .search-header__filters {
    margin-bottom: 16px;
    .ant-tag {
      ${commonTexts.CommonParagraph};
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
      background: ${({ theme }) => theme.colors.neutralBlack[40]};
      border-radius: 8px;
      border: none;
      padding: 4px 12px;

      span {
        color: ${({ theme }) => theme.colors.neutralWhite[10]};
        font-size: ${convertPxToRem(12)};
      }
    }
  }
`;

export const Header = styled.header`
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 32px 0;
  position: relative;

  &:after {
    background: ${({ theme }) => theme.colors.linearGradient.pinkPurple};
    content: '';
    height: 100%;
    left: 0;
    max-height: auto;
    mix-blend-mode: multiply;
    position: absolute;
    width: 100%;
  }
`;

export const SearchInput = styled(Search)`
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  display: flex;
  font-family: ${({ theme }) => theme.fonts.texts};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 48px;
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  .ant-input-group-addon {
    background-color: #ffffff;
    padding: 0 8px !important;
  }

  .ant-input-wrapper,
  input,
  button {
    border: none;
    border-radius: 8px;
  }

  button,
  input {
    height: 48px;
  }

  .anticon-search {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  svg {
    width: 23px;
    height: 24px;
  }
`;

export const PageContent = styled.div`
  ${PaddingContainer};
`;
