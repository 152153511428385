import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';

export function ProtectedRoute({ children }) {
  const { whoAmI, whoAmILoading, isAuthenticated } = useUser();
  const [isRegistered, setIsRegistered] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!whoAmILoading) {
      if (Object.keys(whoAmI).length > 0) {
        setIsRegistered(checkIsRegistered({ whoAmI }));
      }
      setLoading(false);
    }
  }, [whoAmILoading, whoAmI]);

  if (loading || isRegistered === null) {
    return <RedirectLoader />;
  }

  const isRegisterRoute = window.location.pathname.includes('register');

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!isRegistered && !isRegisterRoute && isRegistered !== null) {
    return <Navigate to="/register" replace />;
  }

  return <ContainerApp isMentor>{children || <Outlet />}</ContainerApp>;
}
