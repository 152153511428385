import { useEffect, useState } from 'react';
import MembershipService from 'services/MembershipService';
import { isUserGuide } from 'utils/isRole';

const useSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [subscriptionError, setError] = useState(null);

  const getSubscription = async (role) => {
    if (!role) {
      return;
    }

    if (isUserGuide(role)) {
      try {
        const response = await MembershipService.getMembership();
        setSubscription(response);
        setError(null);
      } catch (error) {
        setError(error);
        if (error.response.status === 404) {
          setSubscription(null);
        }
      }
    } else {
      setSubscription(null);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return {
    subscription,
    subscriptionError,
    getSubscription,
  };
};

export default useSubscription;
