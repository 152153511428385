import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const ScheduleMeetCardContainer = styled.div`
  width: calc(100% + 20px);

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding-left: 16px;
    position: relative;
    top: -40px;
    width: 100%;
  }

  .schedule-meet-card {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    span {
      display: block;
      width: 100%;
    }

    .schedule-meet-card__title {
      color: ${(props) => props.theme.colors.neutralBlack[30]};
      font-weight: 500;
      font-size: ${convertPxToRem(14)};
    }

    .schedule-meet-card__price {
      color: ${(props) => props.theme.colors.pink[30]};
      font-weight: 800;
      font-size: ${convertPxToRem(38)};
    }

    .schedule-meet-card__type-price {
      color: ${(props) => props.theme.colors.neutralBlack[30]};
      font-size: ${convertPxToRem(16)};
      font-weight: 700;
      margin-bottom: 8px;
    }

    .schedule-meet-card__button,
    .schedule-meet-card__button button {
      width: 100%;
    }

    .schedule-meet-card__button button {
    }
  }

  /* Posicionar a navegação e paginação abaixo */
  .custom-swiper {
    position: relative;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
      padding-bottom: 40px; /* Espaço para as bolinhas e setas */
    }
  }

  .custom-swiper .swiper-pagination {
    position: absolute;
    bottom: 10px; /* Ajuste a altura conforme necessário */
    left: 50%;
    transform: translateX(-70%);
    text-align: center;
  }

  .custom-swiper .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.colors.pink[30]};
  }

  .custom-swiper .swiper-button-next,
  .custom-swiper .swiper-button-prev {
    @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
      display: none;
    }
    color: ${(props) => props.theme.colors.neutralBlack[30]};
    background: transparent;
    border: none;
    bottom: -15px; /* Mesma altura das bolinhas */
    box-shadow: none;
    position: absolute;
    top: auto;
  }

  .custom-swiper .swiper-button-next {
    right: 40%; /* Distância da borda direita */
  }

  .custom-swiper .swiper-button-prev {
    left: 40%; /* Distância da borda esquerda */
  }

  /* .swiper-wrapper last child */
  .--last {
    padding-right: 20px;
  }
`;
