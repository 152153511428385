import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const HeaderContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px 0;

  .header-container__title {
    color: ${(props) => props.theme.colors.neutralBlack[30]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(28)};
    font-weight: 800 !important;
    letter-spacing: 1px;
    text-align: center;
  }

  .ant-input-group-wrapper {
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 500px;
    }

    input,
    button {
      height: 50px;
      background: ${(props) => props.theme.colors.neutralWhite[10]};
      border: 1px solid ${(props) => props.theme.colors.pink[40]};
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.1);
      border-radius: 8px;
    }

    .header__search {
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
        padding: 0 24px;
      }
    }

    input::placeholder {
      font-family: ${(props) => props.theme.fonts.texts};
      font-style: normal;
      font-size: ${convertPxToRem(14)};
    }

    button {
      border-left: transparent;
      border-radius: 0 8px 8px 0 !important;
      box-shadow: none;
      width: 50px;

      svg {
        fill: ${(props) => props.theme.colors.pink[30]};
      }
    }
  }
`;
