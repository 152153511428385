import styled from 'styled-components';

export const ActionsFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    justify-content: flex-end;
  }

  button {
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: auto;
    }
  }
`;
