import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';
import PaddingContainer from 'styles/paddingContainer';

export const MostAskedContainer = styled.section`
  ${PaddingContainer};
  h1 {
    color: ${(props) => props.theme.colors.neutralBlack[40]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: ${convertPxToRem(28)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    text-align: center;
    width: 100%;
  }

  .ant-collapse-header-text {
    color: ${(props) => props.theme.colors.neutralBlack[40]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(18)};
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
  }

  .ant-collapse-arrow {
    svg {
      fill: ${(props) => props.theme.colors.pink[30]};
      height: 24px;
      width: 16px;
      stroke-width: 2px;
    }
  }

  .ant-collapse-item {
    margin-bottom: 8px;
  }

  .ant-collapse-content-box {
    padding-top: 0;
    padding-left: 48px;
    max-width: calc(100% - 48px);
    p {
      color: ${(props) => props.theme.colors.neutralBlack[40]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
    }
  }

  .most-asked__another-question {
    color: ${(props) => props.theme.colors.neutralBlack[40]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(18)};
    font-weight: bold;
    letter-spacing: -0.02em;
    line-height: 28px;
    text-align: center;
    margin: 108px 0 56px 0;

    a {
      color: ${(props) => props.theme.colors.pink[40]};
    }
  }

  .ant-collapse-header {
    padding: 8px 0 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 12px 40px 12px 16px;
    }
  }

  .ant-collapse-content-active {
    background-color: transparent;
    border-top: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 12px 40px 0 16px;
    }
  }

  .ant-collapse-content-box {
    padding: 0;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 55%;
    }

    p {
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    }
  }
`;
