import styled from 'styled-components';
import commonTexts from 'styles/commonTexts';
import PaddingContainer from 'styles/paddingContainer';

import convertPxToRem from 'utils/convert-px-rem';

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.neutralWhite[40]};
  /* margin-top: 24px; */
  box-sizing: border-box;
  padding: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 32px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    /* height: calc(500px + 85px); */
    padding: 32px 48px;
  }

  .footer__top {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: center;
      flex-flow: row wrap;
    }

    .footer__top--right {
      display: flex;
      flex-direction: row nowrap;
      gap: 1rem;
      margin-top: 24px;
      justify-content: space-between;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 0;
        gap: 5rem;
      }

      address {
        color: ${({ theme }) => theme.colors.neutralBlack[40]};
        display: flex;
        flex-direction: column;
        font-family: ${({ theme }) => theme.fonts.texts};
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
        letter-spacing: 1.25px;

        h3 {
          font-weight: 800;
        }
      }

      .footer__links {
        ${commonTexts.CommonParagraph};
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-right: 24px;
        a {
          color: ${({ theme }) => theme.colors.neutralBlack[40]};
          font-size: ${convertPxToRem(16)};
          font-weight: 400;
        }
      }
    }
  }

  .footer__logo {
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: auto;
    }
    .footer__logo-contact {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: 16px;
    }

    a {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(18)};
      font-weight: 400;
    }
  }

  .ant-divider {
    border-top: 1px solid ${({ theme }) => theme.colors.neutralBlack[40]};
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 32px 0;
    }
  }

  .footer__content {
    ${commonTexts.CommonParagraph};
    font-weight: 700;
    ${PaddingContainer}
    padding: 0;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-flow: row wrap;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      /* left: 0; */
      /* padding-b÷ottom: 24px; */
      /* padding-top: 36px; */
      padding: 36px 0 24px 0;
      /* position: absolute; */
      /* width: 100vw; */
    }
  }

  .footer__icon-container {
    align-items: center;
    display: flex;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-right: 0;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }

    &.instagram,
    &.facebook,
    &.currency,
    &.language {
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.neutralBlack[40]};
        }
      }
    }

    &.currency {
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 16px;
      }
    }

    &.instagram {
      svg {
        width: 24px;
      }
    }

    &.extra-margin {
      margin-right: 32px;
    }

    &.--last {
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-right: 54px;
      }
    }

    span {
      align-items: center;
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      display: flex;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      margin-left: 8px;
    }

    .footer__icon-container__link {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 24px;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        margin-top: 8px;
        margin-left: 16px;
      }
    }
  }

  .footer__social-links {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 16px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    gap: 8px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      gap: 16px;
      justify-content: initial;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: auto;
    }

    span,
    a {
      font-size: ${convertPxToRem(12)};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.24px;
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
        text-align: center;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      &:hover {
        color: ${({ theme }) => theme.colors.pink[20]};
      }
    }
  }

  .footer__social-icons {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      justify-content: center;
      width: 100%;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-top: 0;
      gap: 0;
      width: auto;
    }
  }

  .footer__social-language {
    margin-right: 16px;
    display: flex;
    margin-bottom: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 0;
    }

    .ant-space-item {
      height: 25px;
    }

    .flag-menu__language {
      color: ${({ theme }) => theme.colors.light};
    }
  }

  .footer__affiliates {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    justify-content: flex-start;
    margin-top: 16px;

    p {
      color: ${({ theme }) => theme.colors.neutralBlack[30]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 400;
      margin: 0%;
    }

    &-logos {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
    }
  }
`;
