import { useEffect, useState } from 'react';

const useTagManager = () => {
  const [tagManager, setTagManager] = useState(null);

  // push data to dataLayer
  const pushDataLayer = (data) => {
    if (tagManager) {
      tagManager.push(data);
    }
  };

  const pushEvent = (event) => {
    pushDataLayer(event);
  };

  // push pageview to dataLayer
  const pushPageview = (page) => {
    pushDataLayer({
      event: 'pageview',
      page,
    });
  };

  useEffect(() => {
    if (window && window.dataLayer) {
      setTagManager(window.dataLayer);
    }
  }, []);

  return {
    pushDataLayer,
    pushEvent,
    pushPageview,
  };
};

export default useTagManager;
