import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_LANGUAGES } from '../../constants/app-languages';
import { useLanguage } from '../../contexts/languageContext';

import IconBrazil from 'components/Icons/Flags/brazil';
import IconUSA from 'components/Icons/Flags/USA';
import * as S from './styles';

const FlagMenuChevron = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0533 4L8.46733 10.586C8.34058 10.7077 8.17169 10.7756 7.996 10.7756C7.82031 10.7756 7.65142 10.7077 7.52467 10.586L0.942667 4.00333L0 4.946L6.582 11.5287C6.96323 11.892 7.46968 12.0947 7.99633 12.0947C8.52298 12.0947 9.02943 11.892 9.41067 11.5287L15.996 4.94267L15.0533 4Z"
      fill="#D82EA1"
    />
  </svg>
);

export function FlagMenu(isLogin = false) {
  const { languagesOptions, onLanguageMenuClick } = useLanguage();
  const [flagObject, setFlagObject] = useState({});
  const { i18n, t } = useTranslation();

  const renderFlagIcon = (flag) => {
    switch (flag) {
      case 'brazil':
      case 'br':
        return isLogin ? (
          <IconBrazil />
        ) : (
          <img
            src="/BRASIL.webp"
            alt="Brazil"
            className="flag-menu__language-flag"
          />
        );

      case 'us':
      default:
        // <img src="/EUA.webp" alt="USA" className="flag-menu__language-flag" />
        return isLogin ? (
          <IconUSA />
        ) : (
          <img src="/EUA.webp" alt="USA" className="flag-menu__language-flag" />
        );
    }
  };

  useEffect(() => {
    const languageObject = APP_LANGUAGES.find(
      (language) => language.key === i18n?.language
    );
    setFlagObject(languageObject);
  }, [i18n.language]);

  return (
    <S.DropdownFlag
      className="flag-menu"
      menu={{
        items: languagesOptions,
        onClick: (e) => onLanguageMenuClick(e, i18n),
      }}
    >
      <Space>
        {renderFlagIcon(flagObject?.flag)}
        <span className="flag-menu__language">
          {t(`common.languages.${flagObject?.key || 'en-US'}`)}
        </span>
        {/* <DownOutlined /> */}
        <FlagMenuChevron />
      </Space>
    </S.DropdownFlag>
  );
}
