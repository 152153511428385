export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const isValidFormat = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    return false;
  }

  return true;
};

export const isValidSize = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    return false;
  }

  return true;
};
