import 'swiper/css';
import 'swiper/css/effect-fade';

import { SearchMentor } from 'components/SearchMentor';
import { MEETING_TYPES } from 'constants/types';
import { useLanguage } from 'contexts/languageContext';
import { useSearch } from 'contexts/searchContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import isMobile from 'utils/isMobile';

function RandomTextWithFade() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showText, setShowText] = useState(true);

  const texts = [
    {
      title: t('traveler.home.randomTexts.title1'),
      text: t('traveler.home.randomTexts.text1'),
    },
    {
      title: t('traveler.home.randomTexts.title2'),
      text: t('traveler.home.randomTexts.text2'),
    },
    {
      title: t('traveler.home.randomTexts.title3'),
      text: t('traveler.home.randomTexts.text3'),
    },
    {
      title: t('traveler.home.randomTexts.title4'),
      text: t('traveler.home.randomTexts.text4'),
    },
    {
      title: t('traveler.home.randomTexts.title5'),
      text: t('traveler.home.randomTexts.text5'),
    },
    {
      title: t('traveler.home.randomTexts.title6'),
      text: t('traveler.home.randomTexts.text6'),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setShowText(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setShowText(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [texts]);

  return (
    <S.FadeTextContainer showText={showText}>
      <h1>{texts[currentIndex].title}</h1>
      <p>{texts[currentIndex].text}</p>
      <div className="about-us__container">
        <ContentaButtonPrimary
          className="about-us__button"
          onClick={() => navigate('/about-us')}
        >
          {t('footer.aboutUs')}
        </ContentaButtonPrimary>
      </div>
    </S.FadeTextContainer>
  );
}

export function HeaderHome() {
  const { handleRedirectWithFilters, handleSearchClick } = useSearch();
  const { currentLanguage } = useLanguage();
  const [languageChanged, setLanguageChanged] = useState(false);

  useEffect(() => {
    setLanguageChanged(true);
  }, [currentLanguage]);

  useEffect(() => {
    if (languageChanged) {
      setLanguageChanged(false);
    }
  }, [languageChanged]);

  return (
    <S.HeaderContent>
      <video
        autoPlay
        muted
        loop
        playsInline
        className="header-content__video"
        height={isMobile() ? '650px' : '500px'}
      >
        <source src="/bg-contenta.mp4" type="video/mp4" />
      </video>

      <S.HeaderContainer>
        {!languageChanged && <RandomTextWithFade />}

        <SearchMentor
          handleFilterClick={handleRedirectWithFilters}
          handleSearchClick={handleSearchClick}
          initialValues={{
            travel_destination: '',
            name: '',
            scheduling_type: MEETING_TYPES.online,
          }}
        />
      </S.HeaderContainer>
    </S.HeaderContent>
  );
}
