import { Col, Divider, Form, Input, Row, Select } from 'antd';
import ContentaCurrencyInput from 'components/ContentaCurrencyInput';
import { CardTitle } from 'components/IdiomsProfile/styles';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencyAvailableInCountry } from 'utils/getCurrencyAvailableInCountry';
import { v4 as uuidv4 } from 'uuid';
import { useRegister } from '../context/useRegister';

function FinancialInputs() {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState('BRL');
  const { formValues } = useRegister();

  const handleCurrenyChange = (value) => {
    setCurrency(value);
  };

  const financialInputs = [
    {
      type: 'title',
      title: t('mentorProfile.financialData.onlineConsultation'),
      divider: true,
    },
    {
      name: 'currency',
      col: 24,
      label: t('guides.currency'),
      isRequired: true,
      hasFeedback: false,
      message: t('guides.currencyRequired'),
      input: (
        <Select
          allowClear
          placeholder={t('guides.currencyPlaceholder')}
          optionFilterProp="name"
          onChange={handleCurrenyChange}
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {getCurrencyAvailableInCountry(formValues).map((crrncy) => (
            <Select.Option key={crrncy.code} value={crrncy.code}>
              {crrncy.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'valueHourVirtual',
      label: t('mentorProfile.financialData.valueHour'),
      divider: false,
      col: 24,
      isRequired: true,
      hasFeedback: false,
      message: t('guides.valueHourRequired'),
      input: <ContentaCurrencyInput currency={currency} />,
    },
    {
      type: 'title',
      title: t('mentorProfile.financialData.presentialConsultation'),
    },
    {
      name: 'valueFourHours',
      label: t('mentorProfile.financialData.valueFourHours'),
      divider: false,
      col: 12,
      isRequired: false,
      hasFeedback: false,
      input: <ContentaCurrencyInput currency={currency} />,
    },
    {
      name: 'valueSixHours',
      label: t('mentorProfile.financialData.valueSixHours'),
      divider: false,
      title: t('mentorProfile.financialData.onlineConsultation'),
      col: 12,
      isRequired: false,
      hasFeedback: false,
      message: t('guides.valueHourRequired'),
      input: <ContentaCurrencyInput currency={currency} />,
    },
    {
      name: 'valueEightHours',
      label: t('mentorProfile.financialData.valueEightHours'),
      divider: false,
      title: false,
      col: 12,
      isRequired: false,
      hasFeedback: false,
      message: t('guides.valueHourRequired'),
      input: <ContentaCurrencyInput currency={currency} />,
    },
    {
      name: 'valueTenHours',
      label: t('mentorProfile.financialData.valueTenHours'),
      divider: false,
      title: false,
      col: 12,
      isRequired: false,
      hasFeedback: false,
      message: t('guides.valueHourRequired'),
      input: <ContentaCurrencyInput currency={currency} />,
    },
  ];

  return (
    <Row gutter={24}>
      {financialInputs.map((input) => {
        if (input.type === 'title') {
          return (
            <Col span={24} key={uuidv4()}>
              {input.divider && <Divider />}
              <CardTitle>{input.title}</CardTitle>
            </Col>
          );
        }
        return (
          <Col key={uuidv4()} xs={24} md={input.col}>
            {input.divider && <Divider />}
            <ContentaInputGroup>
              <Form.Item
                hasFeedback={input.hasFeedback}
                label={input.label}
                name={input.name}
                placeholder={input?.placeholder}
                rules={[
                  {
                    required: input.isRequired,
                    message: input?.message,
                  },
                ]}
              >
                {input.input || <Input className={input?.className} />}
              </Form.Item>
            </ContentaInputGroup>
          </Col>
        );
      })}
    </Row>
  );
}

export default memo(FinancialInputs);
