import { Route } from 'react-router-dom';
import { lazy } from 'react';

const InProgress = lazy(() =>
  import(/* webpackChunkName: "travellerChunk" */ 'pages/Common/InProgress')
);
const Home = lazy(() =>
  import(/* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/Home')
);
const Resume = lazy(() =>
  import(/* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/Resume')
);
const FindMentor = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/FindMentor'
  )
);
const FormBriefing = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/FormBriefing'
  )
);
const HistoryCalls = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/HistoryCalls/index'
  )
);
const PendingBriefings = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/PendingBriefings'
  )
);
const TravellerProfile = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/Profile'
  )
);
const ScheduleBriefing = lazy(() =>
  import(
    /* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/Checkout'
  )
);

const ChatPage = lazy(() =>
  import(/* webpackChunkName: "travellerChunk" */ 'pages/TravellerPages/Chat')
);

export default [
  <Route
    path="history-calls"
    element={<HistoryCalls />}
    key={'history-calls'}
  />,
  <Route path="home" element={<Home />} key={'traveller-home'} />,
  <Route
    path="in-progress"
    element={<InProgress />}
    key={'traveller-in-progress'}
  />,
  <Route
    path="profile"
    element={<TravellerProfile />}
    key={'traveller-profile'}
  />,
  <Route
    path="checkout"
    element={<ScheduleBriefing />}
    key={'traveller-checkout'}
  />,
  <Route
    path="search-mentor"
    element={<FindMentor />}
    key={'traveller-search-mentor'}
  />,
  <Route
    path="resume/:id"
    exact
    element={<Resume />}
    key={'traveller-resume'}
  />,
  <Route
    path="pending-briefings"
    exact
    element={<PendingBriefings />}
    key={'traveller-pending-briefings'}
  />,
  <Route
    path="briefing/:id"
    exact
    element={<FormBriefing />}
    key={'traveller-briefing'}
  />,
  <Route path="chat" exact element={<ChatPage />} key={'traveller-briefing'} />,
];
