import { MEETING_TYPES } from 'constants/types';

import ConfirmedPayment from '../ConfirmedPayment';
import HeaderBriefing from '../HeaderBriefing';
import PaymentBriefing from '../PaymentBriefing';
import ProcessingPayment from '../ProcessingPayment';
import { useScheduleBriefingContext } from '../useScheduleContext';
import { useCallback } from 'react';

function PaymentContainer({ checkoutState }) {
  const { currentStep } = useScheduleBriefingContext();
  const isPresential =
    checkoutState?.schedulingType === MEETING_TYPES.presential;

  const isGiftCardAndChooseByGifter =
    checkoutState?.howToGift === 'chooseOption';

  const getValuePerHour = useCallback(
    ({ amountHours }) => {
      const hourValues = {
        4: 'valueFourHours',
        6: 'valueSixHours',
        8: 'valueEightHours',
        10: 'valueTenHours',
      };

      return (
        checkoutState?.advisor?.[hourValues[amountHours]] ||
        checkoutState?.advisor?.valueHourVirtual
      );
    },
    [checkoutState]
  );

  let value;
  if (isPresential) {
    value = getValuePerHour({
      amountHours: checkoutState?.selectedSchedule?.presentialTour?.amountHours,
    });
  } else {
    value = checkoutState?.advisor?.valueHourVirtual;
  }

  return (
    <>
      <HeaderBriefing
        advisor={checkoutState?.advisor}
        currentStep={currentStep}
        fetchedBriefing={checkoutState?.fetchedBriefing}
        selectedSchedule={checkoutState?.selectedSchedule}
        showTimer={currentStep === 'PAYMENT'}
      />

      {currentStep === 'PAYMENT_SUCCESS' && (
        <ConfirmedPayment isPresential={isPresential} />
      )}

      {currentStep === 'PAYMENT_PROCESSING' && <ProcessingPayment />}

      {currentStep === 'PAYMENT' && (
        <PaymentBriefing
          paymentValue={
            isGiftCardAndChooseByGifter ? checkoutState?.value : value
          }
          checkoutState={checkoutState}
        />
      )}
    </>
  );
}

export default PaymentContainer;
