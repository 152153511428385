import styled from 'styled-components';

export const MentorDetailsContainer = styled.div`
  padding: 0 20px;
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 64px;
    padding-right: 64px;
  }

  .mentor-details__cta {
    margin-top: 20px;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
