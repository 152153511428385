import styled from 'styled-components';
import commonTexts from 'styles/commonTexts';
import convertPxToRem from 'utils/convert-px-rem';

export const PaymentBriefingContainer = styled.div`
  margin-inline: auto;
  width: min(100% - 24px, 920px);

  .payment-briefing__cards {
    display: flex;
    gap: 24px;
    flex-flow: column wrap;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: flex-start;
      flex-flow: row nowrap;
    }

    button {
      width: 100%;
    }
  }

  .ant-card {
    margin-bottom: 24px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    flex: 1 1 100%;
    .card__title {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(18)};
      font-weight: 800;
      letter-spacing: 1px;
      line-height: ${convertPxToRem(28)};
      margin-bottom: 24px;
    }

    .card__gift-card {
      .ant-form-item-control-input-content {
        display: flex;
        flex-flow: row nowrap;

        input {
          border-radius: 8px 0 0 8px !important;
          height: 48px !important;
          min-height: 42px !important;
          width: 100%;
        }

        button {
          border-radius: 0 8px 8px 0;
          height: 42px;
          width: auto;
        }
      }
    }

    address {
      ${commonTexts.CommonParagraph};

      border-radius: 8px;
      border: 1px ${({ theme }) => theme.colors.dark} dashed;
      display: flex;
      flex-flow: column wrap;
      font-feature-settings: 'pnum' on, 'lnum' on;
      padding: 16px;

      .card__address-title {
        color: ${({ theme }) => theme.colors.neutralBlack[20]};
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
        font-size: ${convertPxToRem(16)};
        letter-spacing: 1.25px;
        font-weight: 800;
      }

      span {
        font-weight: 400;
        margin-bottom: 8px;
      }
    }

    .card__actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .payment-briefing__action {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    button {
      min-width: 55%;
    }
  }
`;

export const BannerPaymentBriefingContainer = styled.div`
  background-image: ${({ theme }) => theme.colors.linearGradient.bluePink};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.neutralWhite[10]};
  margin-bottom: 24px;
  margin-inline: auto;
  padding: 24px;

  h1 {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(24)};
    font-weight: 800;
    margin-bottom: 4px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 16px;
    }
  }

  .banner__payment-investment {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(14)};
    font-weight: 400;
    margin-bottom: 4px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 16px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(40)};
    font-weight: 400;
    margin-bottom: 4px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 16px;
    }
  }

  .banner__payment-details {
    font-family: ${({ theme }) => theme.fonts.texts};
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    display: block;
    font-size: ${convertPxToRem(16)};
    margin-bottom: 8px;
    font-weight: 400;
  }

  .banner__payment-gift {
    font-family: ${({ theme }) => theme.fonts.texts};
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralWhite[10]};
    margin-bottom: 16px;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 125% */
    letter-spacing: 1.25px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    padding-bottom: 16px;
  }
`;
