import { Col } from 'antd';
import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const LanguageColumn = styled(Col)`
  max-width: 260px;
  display: flex;

  > div {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    span {
      color: ${(props) => props.theme.colors.neutralBlack[40]};
      font-family: ${(props) => props.theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
    }

    // select second span
    span:nth-child(2) {
      margin-right: auto;
    }
  }

  .languages-options {
    display: flex;
    flex-flow: row wrap;
  }

  .input-languages {
    width: 100%;
    margin-top: 24px;
  }

  .form-languages {
    margin-bottom: 0;

    .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
      input[type='checkbox'] {
      margin: 0;
      min-height: 20px !important;
    }
  }
`;

export const CardTitle = styled.h3`
  align-items: center;
  color: ${(props) => props.theme.colors.neutralBlack[20]};
  display: flex;
  font-family: ${(props) => props.theme.fonts.texts};
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-size: ${convertPxToRem(18)};
  font-style: normal;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 16px;
`;
