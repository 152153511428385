import { Modal } from 'antd';
import styled, { css } from 'styled-components';

export const ContentaModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    background-color: ${({ customColor }) => customColor || '#fff'};
    padding-bottom: ${({ hasFooter }) => (hasFooter ? '0' : '24px')};

    ${({ contentCentered }) =>
      contentCentered &&
      css`
        &.centered {
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: column wrap;
          align-items: center;
        }
      `}

    &.centered {
      text-align: center;
      justify-content: center;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }
  }

  .ant-modal-header {
    background-color: ${({ customColor }) => customColor || '#fff'};
    border-radius: 16px;
    border-bottom: none;
    padding: 0 0 16px;
  }

  .ant-modal-footer {
    border-top: none;
    border-radius: 0 0 16px 16px;
    padding: 32px;
  }

  ${({ theme, danger }) =>
    danger &&
    css`
      .ant-modal-footer {
        background-color: ${theme.colors.danger.light};
      }
    `};
`;
