import { Col, Divider, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function RegisteredLicense({ setIsRequiredLicense, isRequiredLicense }) {
  const { t } = useTranslation();

  const requiredLicensedOptions = {
    true: 'true',
    false: 'false',
    depends: 'depends',
  };

  function handleSelectChange(value) {
    setIsRequiredLicense(value);
  }

  return (
    <Row gutter={24}>
      <Col xs={24}>
        <p className="label-title">{t('guides.isRequiredLicense')}</p>
      </Col>

      <Col xs={24}>
        <ContentaInputGroup>
          <Form.Item
            name="isRequiredLicense"
            label={t('guides.chooseAOption')}
            rules={[
              { required: true, message: t('guides.chooseAOptionRequired') },
            ]}
          >
            <Select allowClear onChange={(value) => handleSelectChange(value)}>
              <Option value="true">{t('guides.yes')}</Option>
              <Option value="false">{t('guides.no')}</Option>
              <Option value="depends">{t('guides.depends')}</Option>
            </Select>
          </Form.Item>
        </ContentaInputGroup>
      </Col>

      {isRequiredLicense === requiredLicensedOptions.true && (
        <Col xs={24}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.registerNumber')}
              name="registerNumber"
              rules={[
                {
                  required: isRequiredLicense === requiredLicensedOptions.true,
                  message: t('guides.registerNumberRequired'),
                },
              ]}
            >
              <Input
                disabled={!isRequiredLicense}
                className="is-number-input"
              />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      )}

      {isRequiredLicense === requiredLicensedOptions.depends && (
        <Col xs={24}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.registerNumberDetails')}
              name="requiredLicenseDetails"
              rules={[
                {
                  required:
                    isRequiredLicense === requiredLicensedOptions.depends,
                  message: t('guides.registerNumberDetailsRequired'),
                },
              ]}
            >
              <TextArea rows={4} maxLength={2000} showCount />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      )}

      <Divider />

      <Col xs={24}>
        <p className="label-title">{t('guides.profilePhrase')}</p>
      </Col>

      <Col xs={24}>
        <ContentaInputGroup>
          <Form.Item
            label=""
            name="profilePhrase"
            rules={[
              {
                required: true,
                message: t('guides.profilePhraseRequired'),
              },
            ]}
            className={!isRequiredLicense ? 'is-disabled' : ''}
          >
            <TextArea rows={2} maxLength={50} showCount />
          </Form.Item>
        </ContentaInputGroup>
      </Col>
    </Row>
  );
}

export default RegisteredLicense;
