import { useTranslation } from 'react-i18next';
import ScheduleSlot from './ScheduleSlot';
import { v4 as uuidv4 } from 'uuid';

const ScheduleSlots = ({
  schedule,
  index,
  handleClick,
  presentialTour,
  availableTimesWithDays,
  selected,
}) => {
  const { t } = useTranslation();
  if (!schedule.times.length) {
    return (
      <div className="text-center contenta-text">
        <span>{t('common.noAvailableSchedules')}</span>
      </div>
    );
  }

  return (
    <div className={`available-schedules`}>
      {schedule.times.map((time, indexHour) => (
        <ScheduleSlot
          availableTimesWithDays={availableTimesWithDays}
          handleClick={handleClick}
          index={index}
          indexHour={indexHour}
          key={uuidv4()}
          presentialTour={presentialTour}
          selected={selected}
          time={time}
        />
      ))}
    </div>
  );
};

export default ScheduleSlots;
