import { message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegister } from '../context/useRegister';
import { getBase64 } from 'utils/upload-image';

const useImageUploader = ({ setSelectedFile }) => {
  const { t } = useTranslation();
  const { form } = useRegister();
  const [messageError, setMessageError] = useState('');
  const [imageUrl, setImageUrl] = useState();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      setMessageError(t('guides.errorTypeUpload'));
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error(t('errors.errorSizeUpload'));
      setMessageError(t('errors.errorSizeUpload'));
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
      form.setFieldsValue({ avatar: 'hasImage' });
    });
  };

  function handleSelectFile(e) {
    setSelectedFile(e.file);
  }

  return {
    imageUrl,
    messageError,
    beforeUpload,
    handleChange,
    handleSelectFile,
  };
};

export default useImageUploader;
