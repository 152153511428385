import { Col, Form, Input, Row } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import useConsultancyInputs from 'hooks/Register/useConsultancyInputs';
import { useTranslation } from 'react-i18next';

/* eslint-disable consistent-return */
function ConsultancyInputs() {
  const { consultancyInputs } = useConsultancyInputs();
  const { t } = useTranslation();

  return (
    <>
      <p className="label-title">{t('guides.aboutDestination')}</p>
      <Row gutter={24}>
        {consultancyInputs.map((input) => (
          <Col key={input.name} xs={24} sm={input.col}>
            <ContentaInputGroup>
              <Form.Item
                className={input.className || ''}
                hasFeedback={input.hasFeedback}
                label={input.label}
                name={input.name}
                rules={[
                  { required: input.isRequired, message: input?.message },
                ]}
              >
                {input.input || <Input />}
              </Form.Item>
            </ContentaInputGroup>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default ConsultancyInputs;
