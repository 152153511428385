import { Form } from 'antd';
import React, { createContext, useMemo, useState } from 'react';

const RegisterContext = createContext(null);

export function RegisterProvider({ setRegisterSuccess, setSaving, children }) {
  const [form] = Form.useForm();
  const [languageError, setLanguageError] = useState(false);
  const [formValues, setFormValues] = useState({ languages: [] });
  const formOptions = {
    form,
    layout: 'vertical',
    requiredMark: true,
  };

  const value = useMemo(
    () => ({
      form,
      formOptions,
      formValues,
      languageError,
      setFormValues,
      setLanguageError,
      setRegisterSuccess,
      setSaving,
    }),
    [languageError, formValues]
  );

  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
}

export const useRegister = () => React.useContext(RegisterContext);
