const getStripeKey = ({ country }) => {
  const isDev =
    window.location.href.includes('contenta-ui-dev.herokuapp.com') ||
    window.location.href.includes('localhost');
  if (isDev) {
    return country === 'BR'
      ? process.env.REACT_APP_DEV_PK_STRIPE_BR
      : process.env.REACT_APP_DEV_PK_STRIPE_US;
  }

  if (process.env.NODE_ENV === 'production') {
    return country === 'BR'
      ? process.env.REACT_APP_PK_STRIPE_PROD_BR
      : process.env.REACT_APP_PK_STRIPE_PROD_US;
  }

  return country === 'BR'
    ? process.env.REACT_APP_DEV_PK_STRIPE_BR
    : process.env.REACT_APP_DEV_PK_STRIPE_US;
};

export default getStripeKey;
