import { Typography } from 'antd';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import aboutUsImage7 from '../../../assets/images/about-us/about-us-7.webp';
import * as S from './styles';

const { Title } = Typography;

const AboutUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <S.Container>
      <Title level={3} className="text-center">
        {t('aboutUs.subtitle')}
      </Title>

      <Title>{t('aboutUs.title')}</Title>

      <Title level={5}>{t('aboutUs.mission.title')}</Title>

      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.mission.paragraph1') }}
      />
      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.mission.paragraph2') }}
      />

      <Title level={2}>{t('aboutUs.whoWeAre.heading')}</Title>
      <p>{t('aboutUs.whoWeAre.paragraph')}</p>

      <img
        src={aboutUsImage7}
        alt="About us"
        className="about-us__image bigger"
      />

      <p
        dangerouslySetInnerHTML={{
          __html: t('aboutUs.whoWeAre.paragraph2'),
        }}
      />
      <h3>{t('aboutUs.whoWeAre.paragraph3')}</h3>
      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.whoWeAre.paragraph4') }}
      />
      <p>{t('aboutUs.whoWeAre.paragraph5')}</p>

      <ContentaButtonPrimary onClick={() => navigate('/about-us')}>
        {t('aboutUs.homeButton')}
      </ContentaButtonPrimary>
    </S.Container>
  );
};

export default AboutUs;
