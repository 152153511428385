import { CloseCircleOutlined } from '@ant-design/icons';
import { ContentaModal } from 'components/Styled/ContentaModal';

const ModalReadMore = ({ about, open, onClose }) => {
  return (
    <ContentaModal
      open={open}
      onClose={onClose}
      title="Why I love being a Travel Expert"
      footer={null}
      centered
      closeIcon={
        <CloseCircleOutlined
          style={{
            color: '#D82EA1',
          }}
          onClick={onClose}
        />
      }
    >
      <p>{about}</p>
    </ContentaModal>
  );
};

export default ModalReadMore;
