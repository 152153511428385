import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Experiences from './Experiences';
import InvestmentValue from './InvestmentValue';
import Ratings from './Ratings';
import { MentorTabs } from './styles';
import WhyAmI from './WhyAmI';

function MentorDetailsTabs({ mentor }) {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t('mentorProfile.whyAmI'),
      content: <WhyAmI mentor={mentor} />,
      key: '1',
    },
    {
      title: t('mentorProfile.experience'),
      content: <Experiences />,
      key: '2',
    },
    {
      title: t('mentorProfile.rating'),
      content: <Ratings mentor={mentor} />,
      key: '3',
    },
    {
      title: t('mentorProfile.investmentValue'),
      content: <InvestmentValue />,
      key: '4',
    },
  ];

  return (
    <MentorTabs>
      {tabs.map((tab) => (
        <Tabs.TabPane tab={tab.title} key={tab.key}>
          {tab.content}
        </Tabs.TabPane>
      ))}
    </MentorTabs>
  );
}

export default MentorDetailsTabs;
