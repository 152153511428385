function AfroTourismIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={90}
      height={50}
      viewBox="0 0 19084.54 19905.12"
    >
      <defs>
        <style>{'.fil0{fill:#8d8b8d;fill-rule:nonzero}'}</style>
      </defs>
      <g id="Camada_x0020_1">
        <g id="_1728843355008">
          <path
            d="M9542.24 482.6c2614.85 0 4982.49 1060.02 6696.23 2773.76 1713.74 1713.74 2773.76 4081.32 2773.76 6696.17s-1060.02 4982.49-2773.76 6696.23c-1713.74 1713.74-4081.38 2773.76-6696.23 2773.76-2614.85 0-4982.43-1060.02-6696.17-2773.76C1132.33 14935.02 72.31 12567.38 72.31 9952.53c0-2614.85 1060.02-4982.43 2773.76-6696.17C4559.81 1542.62 6927.39 482.6 9542.24 482.6zm6054.67 3415.32c-1549.42-1549.43-3690.11-2507.78-6054.67-2507.78-2364.49 0-4505.19 958.35-6054.61 2507.78C1938.2 5447.34 979.85 7587.97 979.85 9952.53c0 2364.55 958.35 4505.25 2507.78 6054.67 1549.42 1549.43 3690.05 2507.78 6054.61 2507.78 2364.56 0 4505.25-958.35 6054.67-2507.78 1549.43-1549.42 2507.78-3690.12 2507.78-6054.67 0-2364.56-958.35-4505.19-2507.78-6054.61z"
            className="fil0"
          />
          <path
            d="M11040.36 6331.07c480.41 198.99 912.08 489.95 1272.77 850.64 360.69 360.68 651.65 792.35 850.64 1272.77 191.42 462.17 297.08 968.19 297.08 1498.08 0 529.89-105.66 1035.91-297.08 1498.08-198.99 480.42-489.95 912.09-850.64 1272.77-360.69 360.69-792.36 651.65-1272.77 850.64-462.17 191.43-968.19 297.08-1498.08 297.08-529.9 0-1035.92-105.65-1498.09-297.08-480.42-198.99-912.09-489.95-1272.77-850.64-360.69-360.68-651.65-792.35-850.64-1272.77-191.43-462.17-297.08-968.19-297.08-1498.08 0-529.89 105.65-1035.91 297.08-1498.08 198.99-480.41 489.95-912.09 850.64-1272.77 360.68-360.69 792.35-651.65 1272.77-850.64 462.17-191.43 968.19-297.08 1498.09-297.08 529.89 0 1035.91 105.65 1498.08 297.08zm-346.65 839.35c-354.04-146.64-742.9-227.59-1151.43-227.59-408.53 0-797.4 80.95-1151.44 227.6-367.37 152.17-698.67 375.86-976.75 653.95-278.08 278.08-501.77 609.37-653.95 976.75-146.65 354.03-227.59 742.89-227.59 1151.43 0 408.53 80.94 797.4 227.59 1151.43 152.18 367.38 375.88 698.67 653.95 976.75 278.09 278.09 609.39 501.78 976.75 653.95 354.04 146.65 742.91 227.6 1151.44 227.6 408.53 0 797.39-80.95 1151.43-227.59 367.37-152.18 698.67-375.87 976.75-653.96 278.08-278.08 501.78-609.37 653.96-976.75 146.64-354.03 227.59-742.9 227.59-1151.43 0-408.53-80.95-797.4-227.6-1151.43-152.17-367.37-375.87-698.67-653.95-976.75-278.08-278.09-609.38-501.78-976.75-653.96z"
            style={{
              fill: '#8d8b8d',
            }}
          />
          <path
            d="M9542.25 3115.39c925.14 0 1808.1 184.27 2614.11 518.13 837.56 346.94 1590.65 854.7 2220.45 1484.5 629.8 629.8 1137.56 1382.89 1484.5 2220.43 333.87 806 518.13 1688.95 518.13 2614.09 0 925.14-184.27 1808.1-518.13 2614.11-346.94 837.56-854.7 1590.65-1484.5 2220.45-629.8 629.8-1382.89 1137.56-2220.45 1484.5-806.01 333.86-1688.97 518.13-2614.11 518.13-925.14 0-1808.09-184.26-2614.09-518.13-837.54-346.94-1590.63-854.7-2220.43-1484.5-629.8-629.8-1137.56-1382.89-1484.5-2220.45-333.86-806.01-518.13-1688.97-518.13-2614.11 0-925.14 184.26-1808.09 518.13-2614.09 346.94-837.54 854.7-1590.63 1484.5-2220.43 629.8-629.8 1382.89-1137.56 2220.43-1484.5 806-333.87 1688.95-518.13 2614.09-518.13zM13723.83 5771c-545.77-545.78-1196.82-985.14-1919.55-1284.48-696.15-288.33-1460.12-447.46-2262.03-447.46-801.89 0-1565.85 159.13-2262 447.47-722.72 299.33-1373.77 738.69-1919.54 1284.47-545.78 545.77-985.15 1196.82-1284.48 1919.53-288.34 696.13-447.46 1460.1-447.46 2262.01 0 801.91 159.13 1565.89 447.46 2262.04 299.34 722.71 738.7 1373.77 1284.48 1919.54 545.77 545.78 1196.82 985.15 1919.53 1284.48 696.13 288.34 1460.1 447.46 2262.01 447.46 801.91 0 1565.89-159.13 2262.04-447.46 722.71-299.34 1373.77-738.7 1919.54-1284.48 545.78-545.77 985.14-1196.83 1284.48-1919.54 288.33-696.15 447.46-1460.13 447.46-2262.04 0-801.91-159.12-1565.88-447.46-2262.01-299.33-722.71-738.7-1373.76-1284.48-1919.53z"
            className="fil0"
          />
        </g>
      </g>
    </svg>
  );
}

export default AfroTourismIcon;
