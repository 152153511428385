import styled from 'styled-components';

export const ScheduleMeetCardContainer = styled.div`
  margin-top: 20px;

  .evaluation-card {
    border: 1px solid #f0f0ef;
    border-radius: 8px;
    padding: 16px;
  }

  .evaluation-card__title {
    margin-bottom: 8px;
    margin-top: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 16px;
    }
  }

  .evaluation-card__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .evaluation-card__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  .evaluation-card__header-user {
    p {
      margin: 0;
    }
  }

  .evaluation-card__rating {
    p {
      margin: 0;
    }
  }

  .evaluation-card__content-text {
    font-weight: 400;
    line-height: 14.09px;
  }
`;
