import HttpClient from './utils/HttpClient';

class RangeTaxService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async delete({ id }) {
    return this.httpClient.delete(`/range-tax/${id}`);
  }

  async create({ data }) {
    return this.httpClient.post('/range-tax', data);
  }

  async update({ id, data }) {
    return this.httpClient.patch(`/range-tax/${id}`, data);
  }
}

export default new RangeTaxService();
