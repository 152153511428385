import MentorContainer from './MentorContainer';

import { MentorProvider } from './mentorContext';

function MentorDetails() {
  return (
    <MentorProvider>
      <MentorContainer />
    </MentorProvider>
  );
}

export default MentorDetails;
