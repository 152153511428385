import { useTranslation } from 'react-i18next';
import { Badge, Skeleton } from 'antd';

import * as S from './styles';
import { useMentor } from '../mentorContext';

const MentorSpecialties = () => {
  const { t } = useTranslation();
  const { loading, mentor } = useMentor();

  if (loading) {
    return (
      <S.MentorSpecialtiesContainer>
        <Skeleton active size="small" />
        <Skeleton active size="small" />
        <Skeleton active size="small" />
      </S.MentorSpecialtiesContainer>
    );
  }

  const { specialties, customSpecialties } = mentor;

  if (!specialties?.length && !customSpecialties?.length) {
    return null;
  }

  function renderCustomSpecialty(specialty) {
    if (specialty) {
      return specialty;
    }

    return null;
  }

  const renderSpecialty = (specialty) => {
    const specialtyInfo = specialties?.find(
      ({ code }) => code === specialty.code
    );

    if (specialtyInfo) {
      return t(`specialties.${specialtyInfo.name}`);
    }

    return t(`specialties.notFound`);
  };

  return (
    <S.MentorSpecialtiesContainer>
      {specialties?.map((specialty) => (
        <Badge
          count={renderSpecialty(specialty)}
          className="mentor-tab__badge"
        />
      ))}
      {customSpecialties?.map((specialty) => (
        <Badge
          count={renderCustomSpecialty(specialty)}
          className="mentor-tab__badge"
        />
      ))}
    </S.MentorSpecialtiesContainer>
  );
};

export default MentorSpecialties;
