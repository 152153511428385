import { COUNTRIES_LIST } from 'constants/countries';

function findCountryNameById({ id, acronym }) {
  if (!id && !acronym) {
    return 'N/A';
  }

  if (id && Number.isNaN(Number(id))) {
    return id;
  }

  if (acronym && Number.isNaN(Number(acronym))) {
    const name = COUNTRIES_LIST.find(
      (c) => c.code === acronym || c.name === acronym
    )?.name;

    return name || 'N/A';
  }

  if (acronym && !Number.isNaN(Number(acronym))) {
    return COUNTRIES_LIST.find((c) => c.id === Number(acronym))?.name || 'N/A';
  }

  const name = COUNTRIES_LIST.find(
    (c) => c.id === Number(id) || c.code === acronym
  )?.name;

  return name || 'N/A';
}

export default findCountryNameById;
