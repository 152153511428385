import convertPxToRem from 'utils/convert-px-rem';
import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';

export const SearchHomeFilters = styled.div`
  ${PaddingContainer};
  background-color: ${({ theme }) => theme.colors.neutralWhite[10]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  z-index: 10;

  .search-filters {
    margin: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: 1200px;
    }
  }

  .search-home__filter {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: column wrap;
    gap: 16px;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease-in-out;
    padding: 20px 0 40px;
    width: 120px;
    &:hover {
      span {
        color: ${({ theme }) => theme.colors.pink[20]};
      }
      svg path {
        fill: ${({ theme }) => theme.colors.pink[20]}!important;
      }
    }

    span {
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
    }
  }
`;
