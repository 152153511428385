import { Typography } from 'antd';
import logoSVG from 'assets/images/logos/contenta.logo.svg';
import { ContentaButtonSecondary } from 'components/Styled/ContentaButton';
import { useUser } from 'contexts/userContext';
import { useTranslation } from 'react-i18next';
import { authService } from 'services/Auth0Service';
import { useRegister } from '../context/useRegister';
import * as S from './styles';

function FinishedRegister() {
  const { t } = useTranslation();
  const { formValues } = useRegister();
  const { authUser: user } = useUser();

  const openLinkInNewTab = () => {
    // const link = advisorsList.find((advisor) => advisor.idiom === idiom).link;
    const link = 'https://rosana-contenta360.youcanbook.me/';
    window.open(link, '_blank');
  };

  const redirectToLogin = () => {
    sessionStorage.setItem('successRegister', true);
    const REDIRECT_URI = `${window.location.origin}/redirecting?redirect_to=advisor_profile`;

    const { email, password } = formValues;

    if (!email || !password) {
      window.location.href = '/advisor/home';
    }

    localStorage.setItem(
      '@contenta-register-values',
      JSON.stringify(formValues)
    );

    authService.login(
      {
        realm: 'Username-Password-Authentication',
        username: formValues.email,
        password: formValues.password,
        redirectUri: REDIRECT_URI,
        responseType: 'token id_token',
      },
      (err) => {
        if (err) {
          console.error('Login error:', err);
          return;
        }
      }
    );
  };

  const advisorsList = [
    // {
    //   name: 'Ludmila Pessanha',
    //   avatar:
    //     'https://onboarding.contenta360.com/wp-content/uploads/2023/10/Imagem-do-WhatsApp-de-2023-10-03-as-17.30.31_82ab4c69.jpg',
    //   link: 'https://ludmilapessanha.youcanbook.me/',
    //   role: 'Global Operations Manager',
    //   idiom: 'PT',
    // },
    // {
    //   name: 'Leticia Arzuaga',
    //   avatar:
    //     'https://onboarding.contenta360.com/wp-content/uploads/2023/08/leticia.jpg',
    //   link: 'https://leticia-contenta360.youcanbook.me/',
    //   role: 'Co-Founder',
    //   idiom: 'EN',
    // },
    {
      name: 'Rosana Grilli',
      avatar: '/rosana.jpeg',
      role: 'Founder',
      idiom: 'PT',
    },
  ];

  const renderName = () => {
    if (formValues?.name) {
      return `${formValues.name},`;
    }
    const firstName = user?.name.split(' ')[0];
    return `${firstName},`;
  };

  return (
    <S.Container>
      <img
        src={logoSVG}
        alt="Contenta 360"
        className="finished-register__logo"
      />
      <p className="finished-register__title">
        {renderName()} {t('guides.registerSuccess1')}
      </p>
      <div
        className="finished-register__subtitle"
        dangerouslySetInnerHTML={{
          __html: t('guides.registerSuccessParagraph'),
        }}
      />

      <div className="success__footer">
        {/* <ContentaButtonSecondary onClick={() => openLinkInNewTab('PT')}>
          {t('mentorHome.notMember.listLinks.portuguese')}
        </ContentaButtonSecondary>
        <ContentaButtonSecondary onClick={() => openLinkInNewTab('EN')}>
          {t('mentorHome.notMember.listLinks.english')}
        </ContentaButtonSecondary> */}

        <ContentaButtonSecondary onClick={() => openLinkInNewTab('PT')}>
          {t('mentorHome.notMember.listLinks.schedule')}
        </ContentaButtonSecondary>

        {advisorsList.map((advisor) => (
          <div className="success__footer-advisor">
            <img src={advisor.avatar} alt={advisor.name} />
            <h4>{advisor.name}</h4>
            <p>{advisor.role}</p>
          </div>
        ))}
      </div>

      <div className="success__login">
        <Typography.Text className="contenta-text">
          {t('guides.afterRegister')}
        </Typography.Text>
        <ContentaButtonSecondary onClick={() => redirectToLogin()}>
          {t('buttons.makeLogin')}
        </ContentaButtonSecondary>
      </div>
    </S.Container>
  );
}

export default FinishedRegister;
