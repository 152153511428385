import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const { Search } = Input;

function FAQHeader({ search, setSearch }) {
  const { t } = useTranslation();
  return (
    <S.HeaderContainer>
      <h1 className="header-container__title">{t('faq.title')}</h1>
      <div className="header__search">
        <Search
          placeholder={t('faq.searchPlaceholder')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </S.HeaderContainer>
  );
}

export default FAQHeader;
