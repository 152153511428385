import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';

export const Container = styled.div`
  margin-bottom: 36px;
  ${PaddingContainer};

  .content-table__action {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
`;
