import HttpClient from './utils/HttpClient';

class TravellerService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async saveUser(user) {
    return this.httpClient.post(`/traveler`, user);
  }

  async updateTraveller({ data, id }) {
    return this.httpClient.patch(`/traveler/${id}`, data);
  }

  async updateTravellerAddress(address, traveler) {
    return this.httpClient.patch(`/traveler/${traveler?.id}/address`, address);
  }
}

export default new TravellerService();
