import { Form, Popconfirm, Space, Table, Typography, notification } from 'antd';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { CURRENCIES } from 'constants/currencies';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EditableCell from './EditableCell';
import RangeTaxService from 'services/RangeTaxService';
import { errorHandler } from 'utils/errorHandler';
import { successHandler } from 'utils/successHandler';
import errorLogger from 'utils/errorLogger';

export const EditableContext = React.createContext(null);

const ContentTable = ({ currency, values, isLoading, schedulingType }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [data, setData] = useState(values);
  const [editingKey, setEditingKey] = useState('');

  const currencySymbol = CURRENCIES.find(
    (item) => item.code === currency
  ).symbol;

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      minValue: '',
      maxValue: '',
      taxPercent: '',
      ...record,
    });

    setEditingKey(record.id);
  };

  const cancel = () => setEditingKey('');

  const updateVisualRow = async ({ row, newData, index }) => {
    try {
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      errorHandler({ error: errInfo, typeError: '' });
    }
  };

  const updateDatabaseRow = async ({ row }) => {
    const data = {
      currency,
      minValue: Number(row.minValue) > 0 ? Number(row.minValue) : null,
      maxValue: Number(row.maxValue) > 0 ? Number(row.maxValue) : null,
      taxPercent: Number(row.taxPercent),
      schedulingType,
    };

    try {
      await RangeTaxService.update({ data, id: row.id });
    } catch (error) {
      errorLogger({
        error,
        message: `[PRICES] Error updating range tax`,
      });
    }
  };

  const createDatabaseRow = async ({ row }) => {
    const data = {
      currency,
      minValue: Number(row.minValue) > 0 ? Number(row.minValue) : null,
      maxValue: Number(row.maxValue) > 0 ? Number(row.maxValue) : null,
      taxPercent: Number(row.taxPercent),
      schedulingType,
    };

    try {
      await RangeTaxService.create({ data });
    } catch (error) {
      errorLogger({
        error,
        message: `[PRICES] Error creating range tax`,
      });
    }
  };

  const validateFields = (row) => {
    const isEditing = row.id;

    let isValueAlreadyInTable;
    if (isEditing) {
      isValueAlreadyInTable = data.some(
        (item) =>
          item.minValue === Number(row.minValue) ||
          item.maxValue === Number(row.maxValue)
      );
    } else {
      const withoutLastItem = data.slice(0, -1);
      isValueAlreadyInTable = withoutLastItem.some(
        (item) =>
          item.minValue === Number(row.minValue) ||
          item.maxValue === Number(row.maxValue)
      );
    }

    if (isValueAlreadyInTable) {
      notification.error({
        message: t('common.error.generic'),
        description: 'Valor já existe na tabela',
        duration: 4.5,
      });
      return false;
    }

    return true;
  };

  const save = async (record) => {
    const row = await form.validateFields();
    const { id } = record;

    const canUpdate = validateFields(row);

    if (!canUpdate) {
      return;
    }

    const newData = [...data];
    const index = newData.findIndex((item) => id === item.id);
    const rowWithId = {
      ...row,
      id,
      taxPercent: row.taxPercent.replace('%', ''),
    };

    updateVisualRow({ row: rowWithId, newData, index });

    if (!id) {
      createDatabaseRow({ row: rowWithId });
    } else {
      updateDatabaseRow({ row: rowWithId });
    }
  };

  const remove = async (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.id === item.id);

    if (!record.id) {
      newData.splice(index, 1);
      setData(newData);

      return;
    }

    try {
      await RangeTaxService.delete({ id: record.id });
      newData.splice(index, 1);
      setData(newData);
      successHandler({ message: t('common.deleted') });
    } catch (error) {
      errorLogger({
        error,
        message: `[PRICES] Error deleting range tax`,
      });
      errorHandler({ error });
    }
  };

  const addRow = () => {
    const newData = {
      key: data.length,
      minValue: 0,
      maxValue: 0,
      taxPercent: 0,
    };

    setData([...data, newData]);
  };

  const columns = [
    {
      title: t('adminPages.prices.table.columns.minimum'),
      dataIndex: 'minValue',
      width: '25%',
      editable: true,
      render: (value) => (value ? `${currencySymbol} ${value}` : '-'),
    },
    {
      title: t('adminPages.prices.table.columns.maximum'),
      dataIndex: 'maxValue',
      width: '15%',
      editable: true,
      render: (value) => (value ? `${currencySymbol} ${value}` : '-'),
    },
    {
      title: t('adminPages.prices.table.columns.percent'),
      dataIndex: 'taxPercent',
      width: '40%',
      editable: true,
      render: (value) => (value ? `${value} %` : '-'),
    },
    {
      title: t('adminPages.prices.table.columns.operation'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              {t('common.save')}
            </Typography.Link>
            <Popconfirm
              title={t('adminPages.prices.table.areYouSure')}
              onConfirm={cancel}
            >
              <a>{t('common.cancel')}</a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              {t('common.edit')}
            </Typography.Link>
            <Typography.Link>
              <Popconfirm
                title={t('adminPages.prices.table.areYouSureDelete')}
                onConfirm={() => remove(record)}
              >
                {t('common.remove')}
              </Popconfirm>
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <div className="content-table__action">
        <ContentaButtonPrimary onClick={addRow} type="button" size="small">
          {t('adminPages.prices.table.addNewVariant')}
        </ContentaButtonPrimary>
      </div>

      <Form form={form} component={false}>
        <Table
          loading={isLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </>
  );
};

export default ContentTable;
