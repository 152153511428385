import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const { Title } = Typography;

import ScrollToTop from 'components/ScrollToTop';
import aboutUsImage1 from '../../assets/images/about-us/about-us-1.webp';
import aboutUsImage2 from '../../assets/images/about-us/about-us-2.webp';
import aboutUsImage3 from '../../assets/images/about-us/about-us-3.webp';
import aboutUsImage4 from '../../assets/images/about-us/about-us-4.webp';
import aboutUsImage5 from '../../assets/images/about-us/about-us-5.webp';
import aboutUsImage6 from '../../assets/images/about-us/about-us-6.webp';
import aboutUsImage7 from '../../assets/images/about-us/about-us-7.webp';

const AboutUsPage = () => {
  const { t } = useTranslation();

  const services = [
    t('aboutUs.travelExperts.services.0'),
    t('aboutUs.travelExperts.services.1'),
    t('aboutUs.travelExperts.services.2'),
    t('aboutUs.travelExperts.services.3'),
  ];

  const servicesHowItWorks = [
    t('aboutUs.howItWorks.steps.1'),
    t('aboutUs.howItWorks.steps.2'),
    t('aboutUs.howItWorks.steps.3'),
    t('aboutUs.howItWorks.steps.4'),
  ];

  return (
    <S.Container>
      <ScrollToTop />
      <Title level={3}>{t('aboutUs.subtitle')}</Title>

      <Title>{t('aboutUs.title')}</Title>

      <Title level={5}>{t('aboutUs.mission.title')}</Title>

      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.mission.paragraph1') }}
      />
      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.mission.paragraph2') }}
      />

      <Divider />

      <Title level={3}>{t('aboutUs.howItWorks.heading')}</Title>

      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.contenta360.paragraph') }}
      />

      <img src={aboutUsImage1} alt="About us" className="about-us__image" />

      <p
        dangerouslySetInnerHTML={{
          __html: t('aboutUs.travelExperts.paragraph'),
        }}
      />

      <ul>
        {servicesHowItWorks.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
      </ul>
      <img
        src={aboutUsImage2}
        alt="About us"
        className="about-us__image bigger"
      />

      <img src={aboutUsImage3} alt="About us" className="about-us__image" />
      <br />
      <Title level={3}>{t('aboutUs.travelExperts.heading')}</Title>

      <img src={aboutUsImage4} alt="About us" className="about-us__image" />

      <img
        src={aboutUsImage5}
        alt="About us"
        className="about-us__image bigger"
      />

      <p>{t('aboutUs.howItWorks.steps.0')}</p>

      <ul>
        {services.map(
          (service, index) =>
            service.length > 0 && (
              <li dangerouslySetInnerHTML={{ __html: service }} key={index} />
            )
        )}
      </ul>
      <img
        src={aboutUsImage6}
        alt="About us"
        className="about-us__image bigger"
      />

      <Divider />

      <Title level={2}>{t('aboutUs.contenta360Difference.heading')}</Title>
      <p
        dangerouslySetInnerHTML={{
          __html: t('aboutUs.contenta360Difference.paragraph'),
        }}
      />

      <Divider />

      <Title level={2}>{t('aboutUs.whoWeAre.heading')}</Title>
      <p>{t('aboutUs.whoWeAre.paragraph')}</p>

      <img
        src={aboutUsImage7}
        alt="About us"
        className="about-us__image bigger"
      />

      <p
        dangerouslySetInnerHTML={{
          __html: t('aboutUs.whoWeAre.paragraph2'),
        }}
      />
      <h3>{t('aboutUs.whoWeAre.paragraph3')}</h3>
      <p
        dangerouslySetInnerHTML={{ __html: t('aboutUs.whoWeAre.paragraph4') }}
      />
      <p>{t('aboutUs.whoWeAre.paragraph5')}</p>
    </S.Container>
  );
};

export default AboutUsPage;
