import { Input, Select } from 'antd';
import { COUNTRIES_LIST } from 'constants/countries';
import { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';

const useConsultancyInputs = () => {
  const { t } = useTranslation();
  const [regionValue, setRegionValue] = useState(null);
  const consultancyInputs = [
    {
      name: 'consultancyCountry',
      col: 8,
      label: t('guides.country'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.countryRequired'),
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((country) => (
            <Select.Option key={country.id} value={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'consultancyState',
      label: t('guides.state'),
      col: 8,
      isRequired: true,
      hasFeedback: true,
      message: t('guides.stateRequired'),
    },
    {
      name: 'consultancyCity',
      label: t('guides.city'),
      col: 8,
      isRequired: true,
      hasFeedback: true,
      message: t('guides.cityRequired'),
    },
    // {
    //   name: 'consultancyRegion',
    //   label: t('guides.consultancyRegion'),
    //   col: 24,
    //   isRequired: true,
    //   hasFeedback: true,
    //   message: t('guides.cityRequired'),
    //   input: (
    //     <div className="google-input">
    //       <GooglePlacesAutocomplete
    //         apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    //         selectProps={{
    //           placeholder: t('common.searchPlace'),
    //           className: 'google-input-search',
    //           value: regionValue,
    //           onChange: (place) => {
    //             setRegionValue({
    //               placeId: place.value.place_id,
    //               label: place.label,
    //             });
    //           },
    //         }}
    //       />
    //     </div>
    //   ),
    // },
    {
      name: 'consultancyDetails',
      label: t('guides.consultancyDetails'),
      col: 24,
      isRequired: true,
      hasFeedback: false,
      className: 'initial-font-transform',
      message: t('guides.chooseAOptionRequired'),
      input: (
        <Input.TextArea
          rows={4}
          placeholder=""
          maxLength={500}
          showCount
          className="h-auto"
        />
      ),
    },
  ];

  return {
    consultancyInputs,
    regionValue,
    setRegionValue,
  };
};

export default useConsultancyInputs;
