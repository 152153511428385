import RedirectLoader from 'components/RedirectingLoader';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services/Auth0Service';
import { useUser } from '../../contexts/userContext';
import AppRoutes from '../../Routes';

export function AppWrapper() {
  const { isLoading, setAuthUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get(CONTENTA_TOKEN)) {
      authService.client.userInfo(Cookies.get(CONTENTA_TOKEN), (err, user) => {
        if (err) {
          navigate('/logout');
          return;
        }

        setAuthUser(user);
      });
    }
  }, []);

  return isLoading ? <RedirectLoader /> : <AppRoutes />;
}
