import { Form } from 'antd';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import useGiftCard from './PaymentBriefing/useGiftCard';
import getStripeKey from 'utils/getStripeKey';

const ScheduleBriefingContext = createContext(null);

export function ScheduleBriefingProvider({
  children,
  advisorId,
  checkoutState,
}) {
  const [form] = Form.useForm();
  const [paidBooking, setPaidBooking] = useState(null);
  const [protocol, setProtocol] = useState(null);

  const [currentStep, setCurrentStep] = useState('PAYMENT');
  const [stripePaymentValue, setStripePaymentValue] = useState();
  const [initialValue, setInitialValue] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [UIFormValues, setUIFormValues] = useState({});

  const {
    errorGiftCard,
    giftCard,
    loadingGiftCard,
    setValueGiftCard,
    successMessage,
    totalCheckoutValue,
    valueGiftCard,
    handleClickGiftCard,
    setGiftCard,
    setTotalCheckoutValue,
  } = useGiftCard({ advisorId, protocol });

  const updateFormValues = (values) => {
    setFormValues(values);
  };

  const redirectSuccessPayment = () => {
    setCurrentStep('PAYMENT_SUCCESS');
  };

  const redirectPendingPayment = () => {
    setCurrentStep('PAYMENT_PROCESSING');
  };

  const redirectPayment = () => {
    setCurrentStep('PAYMENT');
  };

  const convertLastTwoDigitsToCents = (value) => {
    const valueString = value.toString();
    const cents = valueString.slice(-2);
    const dollars = valueString.slice(0, -2);

    return Number(`${dollars}.${cents}`);
  };

  const convertToCurrencyFormat = (value) => {
    const currencyAdvisor =
      checkoutState.advisor.addressCountry.acronym === 'BR' ? 'pt-BR' : 'en-US';
    return value?.toLocaleString(currencyAdvisor, {
      style: 'currency',
      currency:
        checkoutState.advisor.addressCountry.acronym === 'BR' ? 'BRL' : 'USD',
    });
  };

  const loadValuesForUI = (response) => {
    const payment = response.paymentIntent;
    if (!initialValue?.amount) {
      setInitialValue({
        amount: payment.amount,
        convertedAmount: convertToCurrencyFormat(
          convertLastTwoDigitsToCents(payment?.amount)
        ),
        amountWithPadding: convertLastTwoDigitsToCents(payment.amount),
      });

      return;
    }

    const difference = initialValue.amount - payment.amount;
    setStripePaymentValue({
      amount: payment.amount,
      appliedGiftCardValue: convertToCurrencyFormat(
        convertLastTwoDigitsToCents(difference)
      ),
      amountWithPadding: convertLastTwoDigitsToCents(payment.amount),
    });
  };

  const getStripePaymentValue = async () => {
    try {
      const stripe = window.Stripe(
        getStripeKey({ country: checkoutState.advisor.addressCountry.acronym })
      );
      const response = await stripe.retrievePaymentIntent(
        checkoutState.clientSecret
      );

      loadValuesForUI(response);
    } catch (error) {
      console.error(error);
    }
  };

  const value = useMemo(
    () => ({
      currentStep,
      errorGiftCard,
      form,
      formValues,
      giftCard,
      initialValue,
      loadingGiftCard,
      paidBooking,
      protocol,
      setValueGiftCard,
      stripePaymentValue,
      successMessage,
      totalCheckoutValue,
      UIFormValues,
      valueGiftCard,
      handleClickGiftCard,
      redirectPayment,
      redirectPendingPayment,
      redirectSuccessPayment,
      setGiftCard,
      setPaidBooking,
      setProtocol,
      setTotalCheckoutValue,
      setUIFormValues,
      updateFormValues,
      convertToCurrencyFormat,
    }),
    [
      currentStep,
      errorGiftCard,
      form,
      formValues,
      giftCard,
      loadingGiftCard,
      paidBooking,
      protocol,
      successMessage,
      totalCheckoutValue,
      UIFormValues,
      valueGiftCard,
      initialValue,
      stripePaymentValue,
    ]
  );

  useEffect(() => {
    const stripeIsLoaded = typeof window.Stripe !== 'undefined';
    if (protocol && stripeIsLoaded) {
      getStripePaymentValue();
    }
  }, [protocol, valueGiftCard]);

  return (
    <ScheduleBriefingContext.Provider value={value}>
      {children}
    </ScheduleBriefingContext.Provider>
  );
}

export const useScheduleBriefingContext = () =>
  React.useContext(ScheduleBriefingContext);
