import { LoadingOutlined } from '@ant-design/icons';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaInput } from 'components/Styled/ContentaInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewsletterService from 'services/NewsletterService';
import { errorHandler } from 'utils/errorHandler';
import * as S from './styles';
import { Typography } from 'antd';
import errorLogger from 'utils/errorLogger';

function Newsletter() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;
    setIsLoading(true);
    try {
      await NewsletterService.subscribeToNewsletter({ email });

      setIsSubscribed(true);
    } catch (error) {
      errorLogger({
        error,
        message: '[NEWSLETTER] Error subscribing to newsletter',
      });
      errorHandler({
        error,
        typeError: 'GENERIC',
        keyMessage: 'newsletter',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.NewsletterContainer>
      <h3>{t('footer.newsletter')}</h3>
      <form onSubmit={handleSubmit} className="newsletter__form">
        <ContentaInput
          type="email"
          placeholder={t('footer.subscribePlaceholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ContentaButtonPrimary
          size="medium"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <LoadingOutlined /> : null}

          {t('footer.subscribe')}
        </ContentaButtonPrimary>
      </form>
      {isSubscribed && (
        <Typography.Paragraph type="success">
          {t('footer.subscribeSuccess')}
        </Typography.Paragraph>
      )}
    </S.NewsletterContainer>
  );
}

export default Newsletter;
