// mentor routes
import AboutUsPage from 'pages/AboutUs';
import ForbiddenPage from 'pages/Common/403';
import FAQ from 'pages/faq';
import Home from 'pages/home';
import Privacy from 'pages/Privacy';
import TermsConditions from 'pages/TermsConditions';
import { FindMentor } from 'pages/TravellerPages/FindMentor';
import MentorDetails from 'pages/TravellerPages/MentorDetails';
import { Route } from 'react-router-dom';

export default [
  <Route path="/" exact element={<Home />} key={'unlogged-home'} />,
  <Route path="faq" exact element={<FAQ />} key={'unlogged-faq'} />,
  <Route path="about-us" exact element={<AboutUsPage />} key={'about-us'} />,
  <Route
    path="/not-allowed"
    exact
    element={<ForbiddenPage />}
    key={'not-allowed'}
  />,
  <Route
    path="terms-and-conditions"
    exact
    element={<TermsConditions />}
    key={'unlogged-terms-and-conditions'}
  />,
  <Route
    path="privacy-policy"
    exact
    element={<Privacy />}
    key={'unlogged-privacy-policy'}
  />,
  <Route
    path="advisor-details/:id"
    exact
    element={<MentorDetails />}
    key={'unlogged-advisor-details'}
  />,
  <Route
    path="advisor-details/:country/:name/:id"
    exact
    element={<MentorDetails />}
    key={'unlogged-advisor-details-by-country'}
  />,
  <Route
    path="search-advisor"
    element={<FindMentor />}
    key={'unlogged-search-advisor'}
  />,
];
