import AccessibilityIcon from 'components/Icons/SearchIcons/Accessibility';
import AdrenalineIcon from 'components/Icons/SearchIcons/AdrenalineIcon';
import AdventureIcon from 'components/Icons/SearchIcons/AdventureIcon';
import AfroTourismIcon from 'components/Icons/SearchIcons/Afrotourism';
import ArtCultureIcon from 'components/Icons/SearchIcons/ArtCulture';
import FamilyTravelIcon from 'components/Icons/SearchIcons/FamilyTravel';
import LGBTIcon from 'components/Icons/SearchIcons/LGBT';
import LuxuryTravel from 'components/Icons/SearchIcons/LuxuryTravel';
import MountainIcon from 'components/Icons/SearchIcons/MountainIcon';
import ReligiousIcon from 'components/Icons/SearchIcons/ReligiousIcon';
import TravelBudget from 'components/Icons/SearchIcons/TravelBudget';
import UrbanIcon from 'components/Icons/SearchIcons/UrbanIcon';
import WineGastronomy from 'components/Icons/SearchIcons/WineGastronomy';
import WineIcon from 'components/Icons/SearchIcons/WineIcon';

const FILTERS_OPTIONS = {
  1: <ArtCultureIcon />,
  10: <AdrenalineIcon />,
  11: <LGBTIcon />,
  12: <UrbanIcon />,
  13: <TravelBudget />,
  14: <FamilyTravelIcon />,
  15: <LuxuryTravel />,
  16: <AccessibilityIcon />,
  17: <AfroTourismIcon />,
  2: <MountainIcon />,
  3: <AdventureIcon />,
  5: <WineGastronomy />,
  7: <WineIcon />,
  8: <ReligiousIcon />,
};

export default FILTERS_OPTIONS;
