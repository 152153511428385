import { add, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BookingService from 'services/BookingService';
import getTimeZone from 'utils/getTimeZone';

const useBookingAdvisor = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [availableSchedules, setAvailableSchedules] = useState([]);

  const getAdvisorSchedules = async (advisorId) => {
    const timezone = getTimeZone();
    const currentDay = format(add(new Date(), { days: 2 }), 'yyyy-MM-dd');
    const endDay = format(add(new Date(), { days: 4 }), 'yyyy-MM-dd');
    try {
      const { dates } = await BookingService.getBookingByAdvisorId({
        advisorId,
        params: `?startsAt=${currentDay}&endsAt=${endDay}&timeZone=${timezone}`,
      });

      return dates;
    } catch (error) {
      console.error(error);
    }
  };

  const getAvailableSchedules = async () => {
    setIsLoading(true);
    const dates = await getAdvisorSchedules(id);
    setAvailableSchedules(dates);
    setIsLoading(false);
  };

  useEffect(() => {
    getAvailableSchedules();
  }, [id]);

  return {
    availableSchedules,
    isLoading,
    setAvailableSchedules,
  };
};

export default useBookingAdvisor;
