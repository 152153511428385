import { Col } from 'antd';
import AdvisorCard from 'components/AdvisorCard';
import ScrollToTop from 'components/ScrollToTop';
import useMentorInfo from 'hooks/useMentorInfo';
import { useLocation, useParams } from 'react-router-dom';
import MentorDetailsHeader from './MentorDetailsHeader';
import MentorDetailsSchedule from './MentorDetailsSchedule';
import { MentorProvider } from './mentorContext';

import { useUser } from 'contexts/userContext';
import handleLastnameByRole from 'utils/handleLastnameByRole';
import * as S from './styles';
import MentorDetailsTabs from 'pages/TravellerPages/MentorDetails/MentorDetailsTabs';

function GiftAdvisorProfile() {
  const { id } = useParams();
  const { state } = useLocation();
  const { mentor } = useMentorInfo({ id });
  const { userRole } = useUser();

  const advisorState = state?.advisor;

  return (
    <MentorProvider>
      <ScrollToTop />
      <MentorDetailsHeader />

      <S.MentorDetailsContainer gutter={36}>
        <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
          <AdvisorCard
            advisor={{
              name: `${
                mentor?.name || advisorState?.name
              } ${handleLastnameByRole({
                role: userRole,
                lastname: mentor?.surname || advisorState?.surname,
              })}`,
              title: mentor?.title || advisorState?.title,
              image: mentor?.imageUrl || advisorState?.imageUrl,
              video: mentor?.videoUrl || advisorState?.videoUrl,
            }}
          />
          <MentorDetailsTabs mentor={mentor} />
        </Col>

        <Col
          xs={24}
          md={{
            span: 24,
            offset: 0,
          }}
          lg={{
            span: 10,
            offset: 2,
          }}
          xl={{
            span: 9,
            offset: 3,
          }}
          xxl={{
            span: 8,
            offset: 4,
          }}
        >
          <MentorDetailsSchedule advisor={mentor} />
        </Col>
      </S.MentorDetailsContainer>
    </MentorProvider>
  );
}

export default GiftAdvisorProfile;
