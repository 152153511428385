import { useEffect, useState } from 'react';
import MentorService from 'services/MentorService';
import errorLogger from 'utils/errorLogger';

const useMentorInfo = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [advisor, setAdvisor] = useState({});

  const getMentorInfo = async () => {
    setIsLoading(true);
    try {
      const response = await MentorService.getMentorByIdPublic(id);
      setAdvisor(response);
    } catch (error) {
      errorLogger({
        message: '[USE_MENTOR_INFO] error on get mentor info',
        error,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id && advisor.id !== id) {
      getMentorInfo();
    }
  }, [id, advisor.id]);

  return {
    mentor: advisor,
    isLoading: isLoading,
  };
};

export default useMentorInfo;
