import { Col, Form, Input, Row } from 'antd';
import { ContentaInputGroup } from '../../../../../components/Styled/ContentaInputGroup';

function FirstStep({ inputs }) {
  return (
    <Row gutter={8}>
      {inputs.map((input) => (
        <Col
          key={input.name}
          xs={input?.col?.xs}
          sm={input?.col?.sm}
          lg={input?.col?.lg}
        >
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={input.hasFeedback}
              label={input.label}
              name={input.name}
              placeholder={input?.placeholder}
              rules={[{ required: input.isRequired, message: input?.message }]}
            >
              {input.input || <Input className={input?.className} />}
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      ))}
    </Row>
  );
}

export default FirstStep;
