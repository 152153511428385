import { CONTENTA_TIMER_CHECKOUT } from 'constants/storages-values';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemainingTimeContainer } from '../styles';
import { useScheduleBriefingContext } from '../useScheduleContext';

function RemainingTime({ onEndTime }) {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(0);
  const { paidBooking } = useScheduleBriefingContext();
  const storageTimer = sessionStorage.getItem(CONTENTA_TIMER_CHECKOUT);

  if (paidBooking?.bookingId) {
    return null;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      let remainingTime = Number(storageTimer);
      remainingTime = new Date(remainingTime).getTime() + 900000;

      const now = new Date().getTime();
      let diff = remainingTime - now;
      if (diff < 0) {
        clearInterval(timer);
        setRemainingTime('00:00');
        onEndTime();
        return null;
      }

      const minutes = String(
        Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, '0');
      const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(
        2,
        '0'
      );

      setRemainingTime(`${minutes}:${seconds}`);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <RemainingTimeContainer>
      <h3 className="remaining-time__title">{t('briefing.remainingTime')}</h3>
      <p className="remaining-time__time">{remainingTime}</p>
    </RemainingTimeContainer>
  );
}

export default RemainingTime;
