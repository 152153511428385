import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const HeaderContent = styled.div`
  height: 650px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: transparent;
    padding: 0;
    height: 520px;
    margin-top: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 495px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    height: 500px;
  }

  .header-content__video {
    width: 100%;
    height: 520px;
    object-fit: cover;
    object-position: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      height: 495px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      height: 500px;
    }
  }

  .home-slider__slide {
    background: #08afea;
    img {
      bottom: 0;
      height: 650px;
      margin: 0 auto;
      object-fit: contain;
      position: relative;
      right: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        left: 0;
        right: 0;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: 490px;
        float: right;
        position: relative;
      }
    }
  }

  .header-home__container {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
    }
  }
`;

export const HeaderContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: space-between;
  position: absolute;
  top: 84px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: center;
    justify-content: center;
    height: 500px;
    left: 0;
    top: 60px;
    z-index: 1000;
  }

  .ant-form.ant-form-vertical {
    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .about-us__container {
    margin-bottom: 24px;
  }
`;

export const FadeTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  justify-content: flex-start;
  margin-top: 24px;
  opacity: ${(props) => (props.showText ? 1 : 0)};
  transition: opacity 1s ease;
  width: 100%;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: calc(56px + 24px);
    max-width: 600px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: flex-start;
    max-width: 1200px;
  }

  h1,
  p {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(32)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    margin-right: auto;
    max-width: 700px;
    z-index: 2;
  }

  p {
    font-size: ${convertPxToRem(20)};
  }
`;
