import useBookingAdvisor from 'hooks/useBookingAdvisor';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MentorService from 'services/MentorService';

const MentorContext = createContext(null);

export function MentorProvider({ children }) {
  const { id } = useParams();
  const {
    availableSchedules,
    isLoading: loadingSchedules,
    setAvailableSchedules,
  } = useBookingAdvisor();

  const [advisor, setAdvisor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getMentorInfo = async () => {
    setIsLoading(true);
    try {
      const response = await MentorService.getMentorByIdPublic(id);
      setAdvisor(response);
    } catch (error) {
      setAdvisor({});
    }
    setIsLoading(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    getMentorInfo();
  }, [id]);

  const value = useMemo(
    () => ({
      advisor,
      availableSchedules,
      isLoading: isLoading,
      isModalVisible,
      loadingSchedules,
      mentor: advisor,
      openModal,
      setAvailableSchedules,
      setIsModalVisible,
    }),
    [advisor, isLoading, isModalVisible, availableSchedules, loadingSchedules]
  );

  return (
    <MentorContext.Provider value={value}>{children}</MentorContext.Provider>
  );
}

export const useMentor = () => React.useContext(MentorContext);
