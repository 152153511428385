import { Tabs } from 'antd';
import styled from 'styled-components';

export const ContentaTab = styled(Tabs)`
  // .tab-full and not includes advisor-details
  &.tab-full:not(.advisor-details) {
    width: 100%;
  }

  .ant-tabs-nav-list {
    margin: 16px 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      flex-flow: row wrap;
      margin: 0 0 16px;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 24px 0;
  }

  .ant-tabs-tab {
    margin: 0 24px 0;
  }

  .ant-tabs-tab-active {
    span {
      &::after {
        border-bottom: 0px solid ${({ theme }) => theme.colors.blue[20]};
        content: '';
        display: block;
        position: relative;
        top: 13px;
        right: 16px;
        width: calc(100% + 32px);
      }
    }
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.blue[40]};
      font-weight: 700;
    }
  }

  .tabs {
    &__personal-tab {
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: flex;
        flex-flow: column-reverse wrap;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.colors.blue[20]};
  }

  .ant-card-bordered {
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    margin-bottom: 24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 16px;
    }
  }
`;
