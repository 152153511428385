import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { COUNTRIES_LIST } from 'constants/countries';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getAddress from 'services/getAddress';
import { ContentaButtonPrimary } from '../../../../components/Styled/ContentaButton';

function AddressForm({ form }) {
  const { t } = useTranslation();
  const { whoAmI } = useUser();

  const [isSearching, setIsSearching] = useState(false);

  const addressInputs = [
    {
      name: 'zipcode',
      label: t('guides.zipcode'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.zipcodeRequired'),
      className: 'is-number-input',
      onBlur: (e) => {
        setIsSearching(true);
        getAddress({ cep: e.target.value })
          .then((response) => {
            form.setFieldsValue({
              street: response.street,
              city: response.city,
              state: response.state,
              neighborhood: response.neighborhood,
            });
          })
          .catch(() => {
            form.setFieldsValue({
              street: '',
              city: '',
              state: '',
              neighborhood: '',
            });
          })
          .finally(() => {
            setIsSearching(false);
          });
      },
    },
    {
      name: 'country',
      label: t('guides.residenceCountry'),
      isRequired: true,
      col: 12,
      message: t('guides.residenceCountryRequired'),
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((country) => (
            <Select.Option key={country.code} value={country.code}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'street',
      label: t('guides.street'),
      isRequired: true,
      col: 16,
      hasFeedback: true,
      message: t('guides.streetRequired'),
      disabled: isSearching,
    },
    {
      name: 'number',
      label: t('guides.number'),
      message: t('guides.numberRequired'),
      isRequired: true,
      hasFeedback: true,
      col: 8,
    },
    {
      name: 'complement',
      label: t('guides.complement'),
      className: 'contenta-input',
      hasFeedback: false,
      required: true,
      col: 12,
    },
    // {
    //   name: 'neighborhood',
    //   label: t('guides.neighborhood'),
    //   hasFeedback: true,
    //   required: true,
    //   col: 12,
    // },
    {
      name: 'state',
      label: t('guides.state'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.stateRequired'),
      disabled: isSearching,
    },
    {
      name: 'city',
      label: t('guides.city'),
      isRequired: true,
      col: 12,
      hasFeedback: true,
      message: t('guides.cityRequired'),
      disabled: isSearching,
    },
  ];

  useEffect(() => {
    if (whoAmI?.address) {
      form.setFieldsValue({
        country: COUNTRIES_LIST.find(
          (c) => c.id === whoAmI?.address?.country?.id
        )?.name,
        // ...addressValues,
      });
    }
  }, [whoAmI]);

  return (
    <>
      <Row gutter={16}>
        {addressInputs.map((input) => (
          <Col key={input.name} xs={24} sm={input.col}>
            <ContentaInputGroup>
              <Form.Item
                hasFeedback={input.hasFeedback}
                label={input.label}
                name={input.name}
                placeholder={input?.placeholder}
                rules={[
                  { required: input.isRequired, message: input?.message },
                ]}
              >
                {input.input || (
                  <Input className={input?.className} onBlur={input?.onBlur} />
                )}
              </Form.Item>
            </ContentaInputGroup>
          </Col>
        ))}
        <Divider />

        <footer className="card__actions">
          <ContentaButtonPrimary htmlType="submit">
            {t('common.save')} {t('common.address')}
          </ContentaButtonPrimary>
        </footer>
      </Row>
    </>
  );
}

export default AddressForm;
