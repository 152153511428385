import { Col, Form, Input, Row, Select } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useTranslation } from 'react-i18next';

function RegisteredAdvisorInfo({ isRequiredLicense }) {
  const { t } = useTranslation();

  const requiredLicensedOptions = {
    true: 'true',
    false: 'false',
    depends: 'depends',
  };

  const checkIfIsRequired = () => {
    if (requiredLicensedOptions.true === isRequiredLicense) {
      return true;
    }

    return false;
  };

  return (
    <>
      <p className="label-title">{t('guides.registerTuristGuide')}</p>
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={false}
              label={t('guides.association')}
              name="association"
              placeholder={t('guides.association')}
              rules={[
                {
                  required: checkIfIsRequired(),
                  message: t('guides.associationRequired'),
                },
              ]}
            >
              <Input className="contenta-input" />
            </Form.Item>
          </ContentaInputGroup>
        </Col>

        <Col xs={8} sm={8} lg={8}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={false}
              label={t('guides.associatedDay')}
              name="associatedDay"
              placeholder={t('guides.day')}
              rules={[
                {
                  required: checkIfIsRequired(),
                  message: t('guides.associatedDayRequired'),
                },
              ]}
            >
              <Input
                maxLength={2}
                minLength={2}
                placeholder={t('guides.day')}
                className="is-number-input"
              />
            </Form.Item>
          </ContentaInputGroup>
        </Col>

        <Col xs={8} sm={8} lg={8}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={false}
              label={t(' ')}
              name="associatedMonth"
              placeholder={t('guides.month')}
              rules={[
                {
                  required: checkIfIsRequired(),
                  message: t('guides.associatedMonthRequired'),
                },
              ]}
            >
              <Select placeholder={t('guides.month')}>
                <Select.Option value="01">{t('months.January')}</Select.Option>
                <Select.Option value="02">{t('months.February')}</Select.Option>
                <Select.Option value="03">{t('months.March')}</Select.Option>
                <Select.Option value="04">{t('months.April')}</Select.Option>
                <Select.Option value="05">{t('months.May')}</Select.Option>
                <Select.Option value="06">{t('months.June')}</Select.Option>
                <Select.Option value="07">{t('months.July')}</Select.Option>
                <Select.Option value="08">{t('months.August')}</Select.Option>
                <Select.Option value="09">
                  {t('months.September')}
                </Select.Option>
                <Select.Option value="10">{t('months.October')}</Select.Option>
                <Select.Option value="11">{t('months.November')}</Select.Option>
                <Select.Option value="12">{t('months.December')}</Select.Option>
              </Select>
            </Form.Item>
          </ContentaInputGroup>
        </Col>

        <Col xs={8} sm={8} lg={8}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={false}
              label={t(' ')}
              name="associatedYear"
              placeholder={t('guides.year')}
              rules={[
                {
                  required: checkIfIsRequired(),
                  message: t('guides.associatedYearRequired'),
                },
              ]}
            >
              <Input
                maxLength={4}
                minLength={4}
                placeholder={t('guides.year')}
                className="is-number-input"
              />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      </Row>
    </>
  );
}

export default RegisteredAdvisorInfo;
