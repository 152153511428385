import { Card, Divider, Form } from 'antd';
import ScrollToTop from 'components/ScrollToTop';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useUser } from 'contexts/userContext';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import currencyLocales from 'utils/currencyLocales';
import formOptionsLayout from 'utils/formOptionsLayout';

import CreditCardSection from '../CreditCardSection';
import { useScheduleBriefingContext } from '../useScheduleContext';
import AddressFilled from './AddressFilled';
import AddressForm from './AddressForm';
import * as S from './styles';

const SubtotalValues = ({ title, value }) => {
  return (
    <div className="banner__payment-gift">
      <span>{title}</span>
      <span className="banner__payment-gift-value">{value}</span>
    </div>
  );
};

function PaymentBriefing({ paymentValue, checkoutState }) {
  const {
    valueGiftCard,
    setProtocol,
    initialValue,
    stripePaymentValue,
    convertToCurrencyFormat,
  } = useScheduleBriefingContext();
  const { t } = useTranslation();
  const { whoAmI, userCurrency } = useUser();

  const [form] = Form.useForm();
  const briefingValue = useMemo(() => {
    try {
      const currency = userCurrency.currencyStandard;
      const currencyLocale = currencyLocales[currency] || currencyLocales.USD;

      return paymentValue.toLocaleString(currencyLocale, {
        style: 'currency',
        currency: currency,
      });
    } catch (e) {
      return 0;
    }
  }, [userCurrency]);

  const [isEditingAddress, setIsEditingAddress] = useState(null);

  const handleClickAddressButton = () => {
    if (isEditingAddress) {
      setIsEditingAddress(false);
    } else {
      setIsEditingAddress(true);
    }
  };

  useEffect(() => {
    if (whoAmI?.address) {
      form.setFields([
        { name: 'state', value: whoAmI?.address?.state },
        { name: 'city', value: whoAmI?.address?.city },
        { name: 'street', value: whoAmI?.address?.street },
        { name: 'zipcode', value: whoAmI?.address?.zipcode },
        { name: 'complement', value: whoAmI?.address?.complement },
        { name: 'number', value: whoAmI?.address?.number },
      ]);
    }
    setIsEditingAddress(false);
  }, [whoAmI?.address]);

  useEffect(() => {
    if (checkoutState?.protocol) {
      setProtocol(checkoutState?.protocol);
    }
  }, [checkoutState]);

  return (
    <>
      <ScrollToTop />
      <S.PaymentBriefingContainer>
        <S.BannerPaymentBriefingContainer>
          <h1>{t('traveler.scheduleBriefing.paymentTitle')}</h1>
          <p className="banner__payment-investment">
            {t('traveler.scheduleBriefing.investmentValue')}
          </p>
          <h2>{initialValue?.convertedAmount}</h2>
          <p className="banner__payment-details">
            {t('traveler.scheduleBriefing.paymentDetailsSubtitle')}
          </p>
          {valueGiftCard > 0 && (
            <>
              <SubtotalValues
                title={t('traveler.scheduleBriefing.subtotal')}
                value={initialValue?.convertedAmount}
              />
              <SubtotalValues
                title={t('traveler.scheduleBriefing.discount')}
                value={convertToCurrencyFormat(valueGiftCard)}
              />
              <SubtotalValues
                title={t('traveler.scheduleBriefing.totalPayment')}
                value={
                  stripePaymentValue?.amount < 0
                    ? 0
                    : stripePaymentValue?.appliedGiftCardValue
                }
              />
            </>
          )}
        </S.BannerPaymentBriefingContainer>
        <div className="payment-briefing__cards">
          <Card>
            <h2 className="card__title">
              {t('traveler.scheduleBriefing.paymentAddress')}
            </h2>

            <Form
              preserve
              initialValues={{
                state: whoAmI?.address?.state,
                city: whoAmI?.address?.city,
                street: whoAmI?.address?.street,
                zipcode: whoAmI?.address?.zipcode,
                complement: whoAmI?.address?.complement,
                number: whoAmI?.address?.number,
              }}
              {...formOptionsLayout(form)}
              onFinish={handleClickAddressButton}
            >
              {isEditingAddress ? (
                <AddressForm form={form} />
              ) : (
                <AddressFilled />
              )}
            </Form>

            {!isEditingAddress && (
              <>
                <Divider />

                <footer className="card__actions">
                  <ContentaButtonPrimary onClick={handleClickAddressButton}>
                    {t('common.edit')} {t('common.address')}
                  </ContentaButtonPrimary>
                </footer>
              </>
            )}
          </Card>

          <CreditCardSection
            briefingValue={briefingValue}
            checkoutState={checkoutState}
            isEditingAddress={isEditingAddress}
            paymentValue={
              stripePaymentValue?.amount > 0
                ? stripePaymentValue.amountWithPadding
                : initialValue?.amountWithPadding
            }
            totalCheckoutValue={
              stripePaymentValue?.amount > 0
                ? stripePaymentValue?.appliedGiftCardValue
                : initialValue?.convertedAmount
            }
          />
        </div>
      </S.PaymentBriefingContainer>
    </>
  );
}

export default PaymentBriefing;
