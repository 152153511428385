import * as React from 'react';

function IconSpain(props) {
  return (
    <svg
      width={22}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width={22} height={16} rx={2} fill="#F93939" />
          <path
            d="M19.905 0H2.095C.938 0 0 .955 0 2.133v11.734C0 15.045.938 16 2.095 16h17.81C21.062 16 22 15.045 22 13.867V2.133C22 .955 21.062 0 19.905 0Z"
            fill="#F93939"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.267h22v7.466H0V4.267Z"
            fill="#FFDA2C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.429 6.637v2.688c0 .747-.704 1.344-1.572 1.344H5.762c-.865-.002-1.572-.603-1.572-1.346V6.635c0-.61.47-1.12 1.115-1.286.195-.554.794-.057 1.505-.057.714 0 1.31-.494 1.504.058.643.17 1.115.68 1.115 1.287Z"
            fill="#D4AF2C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.429 7.467h1.047v3.2H9.43v-3.2Zm-6.286 0H4.19v3.2H3.143v-3.2Z"
            fill="#CBCBCB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.429 9.6h1.047v1.067H9.43V9.6Zm-6.286 0H4.19v1.067H3.143V9.6Z"
            fill="#1A47B8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.429 6.4h1.047v1.067H9.43V6.4Zm-6.286 0H4.19v1.067H3.143V6.4Z"
            fill="#D4AF2C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.238 6.4h1.048V8H5.238V6.4Zm2.095 2.133h1.048v1.6H7.333v-1.6Z"
            fill="#AF010D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.333 6.4h1.048V8H7.333V6.4Z"
            fill="#AE6A3E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.238 8.533h1.048v1.6H5.238v-1.6Z"
            fill="#FFDA2C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.286 6.4 5.238 5.333h3.143L7.333 6.4H6.286Z"
            fill="#AF010D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.286 4.267h1.047v1.066H6.286V4.267Z"
            fill="#D4AF2C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width={22} height={16} rx={2} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSpain;
