function ReligiousIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={50}
      height={58}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="843 1368 769 736"
    >
      <path
        d="M1376.28 1452.22c-4.6-4.6-10.02-8.28-15.94-10.73-5.73-2.38-12.12-3.69-18.89-3.69-6.78 0-13.16 1.32-18.9 3.69-5.92 2.45-11.33 6.13-15.94 10.73-4.6 4.61-8.28 10.02-10.73 15.94-2.37 5.73-3.68 12.11-3.68 18.89 0 6.78 1.31 13.17 3.68 18.9 2.48 5.99 6.14 11.4 10.67 15.94h.06c4.61 4.6 10.02 8.28 15.94 10.73 5.74 2.37 12.12 3.69 18.9 3.69 6.77 0 13.16-1.32 18.89-3.69 5.92-2.45 11.33-6.13 15.94-10.73h.06a49.17 49.17 0 0 0 10.67-15.94c2.37-5.73 3.69-12.12 3.69-18.9 0-6.78-1.32-13.16-3.69-18.89-2.45-5.92-6.12-11.33-10.73-15.94zm-465.19 617.53c-9.05 0-16.39-7.41-16.39-16.55s7.34-16.55 16.39-16.55h656.14c9.05 0 16.38 7.41 16.38 16.55s-7.33 16.55-16.38 16.55H911.09zm322.14-502.05a16.18 16.18 0 0 1 12.65-6.11c4.77-1.02 9.92.11 13.9 3.51l123.12 105.79 92.83-102.41c6.08-6.75 16.41-7.28 23.07-1.2 6.65 6.08 7.11 16.49 1.02 23.23l-103.17 113.83c-5.87 6.95-16.18 7.84-23.04 1.98l-124.03-106.57-120.88 150.46 209.99 172.18c7.02 5.73 8.12 16.11 2.46 23.18-3.22 4.04-7.95 6.12-12.72 6.11v.04l-337.9-1.44c-9.03-.02-16.36-7.45-16.35-16.57 0-9.12 7.34-16.5 16.37-16.47l291.63 1.24-186.69-153.08c-7.02-5.73-8.12-16.11-2.47-23.18l140.21-174.52z"
        style={{
          fill: '#8d8b8d',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}

export default ReligiousIcon;
