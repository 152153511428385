import { notification } from 'antd';
import i18n from '../i18n';

function errorMessages(message) {
  const messages = {
    'auth/email-already-in-use': 'Email already in use',
    'auth/invalid-email': 'Invalid email',
    ERR_NETWORK: 'Erro de conexão',
  };

  return messages[message] || message;
}

function showNotification({ message, description, duration }) {
  notification.error({
    message: message || i18n?.t('common.error.generic'),
    description: description || i18n?.t('common.error.generic'),
    duration: duration || 4.5,
  });
}

/**
 * It takes an error object as an argument, and returns a notification with the error message
 * @param {Object} error - The error object returned from the API call.
 * @param {String} typeError - The type of error, to be used in the switch case.
 */
export function errorHandler({ error, typeError, keyMessage }) {
  const errorMsg = error?.response?.data?.error;
  switch (typeError) {
    case 'VALIDATION':
      if (!error.errorFields) {
        showNotification({ description: errorMessages(errorMsg) });
        break;
      }
      error.errorFields.forEach(({ errors }) => {
        showNotification({ description: errors[0] });
      });
      break;
    case 'BACKEND':
      showNotification({ description: errorMessages(error) });
      break;
    case 'ADVISORS':
      showNotification({
        description: i18n.t('common.error.errorFetchingAdvisors'),
      });
      break;
    case 'ZIPCODE':
      showNotification({
        description: i18n.t('guides.zipcodeError'),
      });
      break;
    case 'NOT_FOUND':
      showNotification({
        description: i18n.t('common.error.notFound'),
      });
      break;
    case 'CREATE_SCHEDULE':
      showNotification({
        description: i18n.t('common.error.createSchedule'),
      });
      break;
    case 'GENERIC':
      showNotification({
        description: i18n.t(`common.error.${keyMessage}`),
      });
      break;
    case 'FULL_MESSAGE':
      showNotification({
        description: i18n.t(`${keyMessage}`),
      });
      break;
    default:
      showNotification({
        description: errorMessages(errorMsg),
      });
      break;
  }
}
