import axios from 'axios';
import api from 'services/api';

class HttpClient {
  constructor() {
    this.baseURL = process.env.REACT_APP_API;
  }

  async get(url) {
    const response = await api.get(`${this.baseURL}${url}`);

    if (response.data) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async publicGet(url, params = {}) {
    const usp = new URLSearchParams(params);
    usp.sort();
    let qs = usp.toString();
    qs = qs ? `?${qs}` : '';

    const response = await axios.get(`${this.baseURL}${url}${qs}`, {
      headers: {
        'Request-Type': 'public',
      },
    });

    if (response.data) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async publicPost(url, data) {
    const response = await fetch(`${this.baseURL}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async post(url, data) {
    const response = await api.post(`${this.baseURL}${url}`, data);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async put(url, data) {
    const response = await api.put(`${this.baseURL}${url}`, data);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async patch(url, data) {
    const response = await api.patch(`${this.baseURL}${url}`, data);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }

  async delete(url) {
    const response = await api.delete(`${this.baseURL}${url}`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }

    throw new Error(`${response.status} - ${response.statusText}`);
  }
}

export default HttpClient;
