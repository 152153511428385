import HttpClient from './utils/HttpClient';

class MentorService {
  constructor() {
    this.httpClient = new HttpClient();
    this.basePath = '/advisor';
  }

  async getMentorById(id) {
    return this.httpClient.get(`/api/user/${id}`);
  }

  async getMentorByIdPublic(id) {
    return this.httpClient.publicGet(`${this.basePath}/public/${id}`);
  }

  async saveMentor(data) {
    return this.httpClient.post(`${this.basePath}`, data);
  }

  async updateMentorInfo(data) {
    return this.httpClient.put(`${this.basePath}`, data);
  }

  async updateMentorStatus({ id, status }) {
    return this.httpClient.patch(`${this.basePath}/${id}`, { status });
  }

  async uploadMentorImage({ id, data }) {
    return this.httpClient.post(`${this.basePath}/${id}/image`, data);
  }

  async uploadAdvisorImageOnRegister({ data }) {
    return this.httpClient.post(`${this.basePath}/image`, data);
  }

  async uploadAdvisorVideo({ file }) {
    return this.httpClient.post(`${this.basePath}/video`, file);
  }

  async getPublicMentors() {
    return this.httpClient.publicGet(`${this.basePath}/highlights`);
  }

  async getAdvisorsSearchResults({ filters, page, limit }) {
    return this.httpClient.get(
      `${this.basePath}/public?${filters}&page=${page}&limit=${limit}`
    );
  }

  async updateLicenseStatus({ id, status }) {
    return this.httpClient.patch(`${this.basePath}/${id}/guide_license`, {
      status,
    });
  }

  async uploadCoverPhoto({ data }) {
    return this.httpClient.post(`${this.basePath}/cover`, data);
  }
}

export default new MentorService();
