import { GiftOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Divider, Form, Typography } from 'antd';
import CheckoutRulesModal from 'components/Modais/CheckoutRulesModal';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaInput } from 'components/Styled/ContentaInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BookingService from 'services/BookingService';
import getTimeZone from 'utils/getTimeZone';

import errorLogger from 'utils/errorLogger';
import { useScheduleBriefingContext } from '../useScheduleContext';

const { Text } = Typography;

const showMinimumChargeMessage = (minimumCharge, t) => {
  const valueFormatted = minimumCharge.minimumCharge.toLocaleString(
    minimumCharge.currency.locale,
    {
      style: 'currency',
      currency: minimumCharge.currency.currencyStandard,
    }
  );

  return (
    <Text type="danger">
      {t('traveler.scheduleBriefing.minimumCharge').replace(
        '$value',
        valueFormatted
      )}
    </Text>
  );
};

export default function CheckoutForm({
  advisor,
  isEditingAddress,
  paymentValue,
  selectedSchedule,
}) {
  const {
    redirectPendingPayment,
    errorGiftCard,
    giftCard,
    minimumCharge,
    loadingGiftCard,
    valueGiftCard,
    successMessage,
    handleClickGiftCard,
    setGiftCard,
    convertToCurrencyFormat,
  } = useScheduleBriefingContext();
  const { state } = useLocation();
  const stripe = useStripe();
  const { t } = useTranslation();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const updateSchedule = async () => {
    try {
      await BookingService.rescheduleBooking({
        bookingId: state.currentScheduleToReschedule.id,
        data: {
          startAt: state?.schedule?.startAt,
          timezone: getTimeZone(),
        },
      });
    } catch (error) {
      errorLogger({
        error,
        message: `[CheckoutForm] Error rescheduling booking`,
      });
    }
  };

  const createSchedule = async () => {
    redirectPendingPayment();
  };

  const submitPayment = () => {
    if (state.isReschedule) {
      updateSchedule();
    } else {
      createSchedule();
    }
  };

  const handleSubmit = async (e) => {
    setModalOpen(false);
    e.preventDefault();
    setMessage(null);

    if (!stripe || !elements) return;

    setIsProcessing(true);

    const { error } =
      (await stripe?.confirmPayment({
        elements,
        redirect: 'if_required',
      })) ?? {};

    if (!error) {
      setIsProcessing(false);
      submitPayment();
      return;
    }

    errorLogger({
      message: '[CheckoutForm] Error submitting payment',
      error,
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(t('common.unexpectedError'));
    }

    setIsProcessing(false);
  };

  return (
    <>
      <form>
        <PaymentElement id="payment-element" />

        <Divider />

        <div className="card__gift-card">
          <Form.Item
            name="giftCard"
            label={t('traveler.scheduleBriefing.giftCard')}
          >
            <ContentaInput
              onChange={(e) => setGiftCard(e.target.value)}
              onPressEnter={handleClickGiftCard}
              placeholder={t('traveler.scheduleBriefing.hasSomegiftCard')}
              value={giftCard}
            />
            <ContentaButtonPrimary
              onClick={() => handleClickGiftCard(paymentValue)}
              loading={loadingGiftCard}
            >
              <GiftOutlined />
            </ContentaButtonPrimary>
          </Form.Item>
          {valueGiftCard > 0 && <Text type="success">{successMessage}</Text>}
          {errorGiftCard && (
            <Text type="danger">
              {t('traveler.scheduleBriefing.giftCardError')}
            </Text>
          )}

          {minimumCharge?.minimumChargeApplied &&
            showMinimumChargeMessage(minimumCharge, t)}
        </div>

        <Divider />

        {message && (
          <Text
            type="danger"
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: '16px',
              width: '100%',
              display: 'block',
            }}
          >
            {message}
          </Text>
        )}

        <ContentaButtonPrimary
          onClick={() => setModalOpen(true)}
          disabled={isProcessing || isEditingAddress}
        >
          {isProcessing ? (
            <>
              {t('creditCard.finishingPayment')}
              <LoadingOutlined />
            </>
          ) : (
            <>
              {t('creditCard.finishPayment')}{' '}
              {convertToCurrencyFormat(paymentValue)}
            </>
          )}
        </ContentaButtonPrimary>
      </form>
      <CheckoutRulesModal
        advisor={advisor?.name}
        isModalOpen={modalOpen}
        handleClose={() => setModalOpen(false)}
        onConfirm={handleSubmit}
        selectedSchedule={selectedSchedule}
        isPresential={
          selectedSchedule?.presentialTour?.selectedSlots?.length > 1
        }
      />
    </>
  );
}
