import HttpClient from './utils/HttpClient';

class NewsletterService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async subscribeToNewsletter(data) {
    return await this.httpClient.post('/newsletter', data);
  }
}

export default new NewsletterService();
