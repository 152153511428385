import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row, Upload } from 'antd';
import userPhotoPlaceholder from 'assets/images/placeholder-photo.png';
import Avatar from 'components/Avatar';
import { ContentaButtonPrimaryOutline } from 'components/Styled/ContentaButton';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useImageUploader from './useImageUploader';

function PhotoPicker({ setSelectedFile }) {
  const { imageUrl, beforeUpload, handleSelectFile, handleChange } =
    useImageUploader({ setSelectedFile });
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={24}>
        <div className="form-photo">
          <Form.Item
            label={t('guides.photo')}
            valuePropName="avatar"
            name="avatar"
            rules={[{ required: false, message: t('guides.photoIsRequired') }]}
          >
            <Avatar
              alt="avatar"
              src={imageUrl || userPhotoPlaceholder}
              onClick={() => {
                document.querySelector('input[type="file"]').click();
              }}
            />
            <Upload
              accept="image/jpeg, image/png"
              beforeUpload={beforeUpload}
              customRequest={handleSelectFile}
              name="avatar"
              onChange={handleChange}
              showUploadList={false}
            >
              <ContentaButtonPrimaryOutline
                icon={<PlusOutlined />}
                style={{ margin: '0', height: '46px' }}
                type="default"
              >
                {t('common.addPhoto')}
              </ContentaButtonPrimaryOutline>
            </Upload>
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
}

export default memo(PhotoPicker);
