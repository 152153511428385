import * as React from 'react';
const JapanIconFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={20}
    fill="none"
    {...props}
  >
    <rect
      width={27.5}
      height={19.5}
      x={0.25}
      y={0.25}
      fill="#fff"
      stroke="#F5F5F5"
      strokeWidth={0.5}
      rx={1.75}
    />
    <mask
      id="a"
      width={28}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <rect
        width={27.5}
        height={19.5}
        x={0.25}
        y={0.25}
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.5}
        rx={1.75}
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="M14 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={8}
        x2={8}
        y1={4}
        y2={16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D81441" />
        <stop offset={1} stopColor="#BB0831" />
      </linearGradient>
    </defs>
  </svg>
);
export default JapanIconFlag;
