import axios from 'axios';
import { errorHandler } from '../utils/errorHandler';

async function getAddress({ cep }) {
  if (!cep) {
    return null;
  }

  try {
    const { data } = await axios.get(
      `https://brasilapi.com.br/api/cep/v1/${cep}`
    );

    return data;
  } catch (error) {
    errorHandler({ error, typeError: 'ZIPCODE' });
  }

  return null;
}

export default getAddress;
