import { Checkbox, Space } from 'antd';
import conductCodeEn from 'assets/docs/CODIGO DE CONDUTA_CONTENTA_ENG.pdf';
import conductCodePt from 'assets/docs/CODIGO DE CONDUTA_CONTENTA_PT.pdf';
import { ReactComponent as Chat } from 'assets/images/icons/chat.svg';
import { ReactComponent as Clock } from 'assets/images/icons/clock.svg';
import { ReactComponent as GroupWhite } from 'assets/images/icons/group-wt.svg';
import { ReactComponent as Group } from 'assets/images/icons/group.svg';
import { ReactComponent as Marker } from 'assets/images/icons/marker.svg';
import { ReactComponent as Paper } from 'assets/images/icons/paper.svg';
import { ReactComponent as Video } from 'assets/images/icons/video-camera.svg';
import { useLanguage } from 'contexts/languageContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentaCheckbox } from '../../Styled/ContentaCheckbox';
import { Col, ConfirmationButton, ModalContainer } from './styles';

const CheckoutRulesModal = ({
  advisor,
  handleClose,
  isModalOpen,
  isPresential,
  onConfirm,
  selectedSchedule,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const pdf = currentLanguage === 'pt-BR' ? conductCodePt : conductCodeEn;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const briefingType = isPresential ? 'presential' : 'online';

  const getTimeDuration = (time) => {
    let hoursIndicator = 'traveler.scheduleBriefing.rulesModal.hour';

    if (Number(time) > 1) {
      hoursIndicator = 'traveler.scheduleBriefing.rulesModal.hours';
    }

    if (Number(time) < 10) {
      time = `0${time}`;
    }

    return `${time} ${t(hoursIndicator)}`;
  };
  const showCorrectDate = ({ date }) => {
    const newDate = new Date(`${date}T00:00:00`);
    const day = newDate.toLocaleDateString(currentLanguage, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return `${day}`;
  };

  const baseTranslationPath = `traveler.scheduleBriefing.rulesModal.${briefingType}`;
  const texts = {
    scheduleDate: t(`${baseTranslationPath}.scheduleDate`),
    scheduleDateComplement: t(`${baseTranslationPath}.scheduleDateComplement`),
    duration: t(`${baseTranslationPath}.duration`),
  };

  const scheduledTime = {
    online: [
      {
        icon: () => <Clock />,
        text: `${texts.scheduleDate} ${selectedSchedule?.startAt.slice(0, 5)} ${
          texts.scheduleDateComplement
        } ${showCorrectDate({ date: selectedSchedule?.date })}. <strong>${
          texts.duration
        }: ${getTimeDuration('1')} </strong>`,
        id: 'scheduledTime_online_1',
      },
    ],
    presential: [
      {
        icon: () => <Clock />,
        text: `${texts.scheduleDate} ${selectedSchedule?.startAt.slice(0, 5)} ${
          texts.scheduleDateComplement
        } ${showCorrectDate({ date: selectedSchedule?.date })}. <strong>${
          texts.duration
        }: ${getTimeDuration(
          selectedSchedule?.presentialTour?.amountHours
        )} </strong>`,
        id: 'scheduledTime_presential_1',
      },
      {
        icon: () => <Chat />,
        text: t(`${baseTranslationPath}.chatDescription`),
        id: 'scheduledTime_presential_2',
      },
    ],
  };

  const meetingLocation = {
    online: [
      {
        icon: () => <Video />,
        text: t(`${baseTranslationPath}.locationDetails`),
        id: 'meetingLocation_online_1',
      },
    ],
    presential: [
      {
        icon: () => <Marker />,
        text: t(`${baseTranslationPath}.locationDetails`),
        id: 'meetingLocation_presential_1',
      },
      {
        icon: () => <GroupWhite />,
        text: t(`${baseTranslationPath}.locationDetails2`),
        id: 'meetingLocation_presential_2',
      },
    ],
  };

  const generalRules = {
    online: [
      {
        icon: () => <Paper />,
        text: t(`${baseTranslationPath}.rule1`),
        id: 'generalRules_online_1',
      },
      {
        icon: () => <Group />,
        text: t(`${baseTranslationPath}.rule2`),
        id: 'generalRules_online_2',
      },
    ],
    presential: [
      {
        icon: () => <Paper />,
        text: t(`${baseTranslationPath}.rule1`),
        id: 'generalRules_presential_1',
      },
    ],
  };

  return (
    <ModalContainer
      open={isModalOpen}
      onCancel={handleClose}
      width={614}
      className="contenta-modal"
      hasFooter={false}
      footer={null}
      title={t(`${baseTranslationPath}.title`)}
    >
      <Col briefingType={briefingType}>
        <p>
          {t(`${baseTranslationPath}.advisor`)}: {advisor}
        </p>
        <span className="col-label">
          {t(`${baseTranslationPath}.meetingDuration`)}
        </span>
        {scheduledTime[briefingType].map(({ text, icon, id }) => (
          <div className="content-row" key={id}>
            <div className="checkout-modal__icon-wrapper">{icon()}</div>
            <span>
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </span>
          </div>
        ))}
      </Col>
      <Col briefingType={briefingType}>
        <span className="col-label">
          {t(`${baseTranslationPath}.meetingLocationInfo`)}
        </span>
        {meetingLocation[briefingType].map(({ id, text, icon }) => (
          <div key={id} className="content-row">
            <div className="checkout-modal__icon-wrapper">{icon()}</div>
            <span>{text}</span>
          </div>
        ))}
      </Col>
      <Col briefingType={briefingType}>
        <p className="col-title">{t(`${baseTranslationPath}.generalRules`)}</p>
        {generalRules[briefingType].map(({ id, text, icon }) => (
          <div key={id} className="content-row">
            <div className="checkout-modal__icon-wrapper checkout-modal__icon-wrapper-neutral-bg">
              {icon()}
            </div>
            <div>
              <span>{text}</span>
            </div>
          </div>
        ))}
      </Col>
      <Col briefingType={briefingType}>
        <p className="col-title">
          {t(`${baseTranslationPath}.rescheduleTitle`)}
        </p>
        <ul>
          <li>{t(`${baseTranslationPath}.rescheduleRule1`)}</li>
          <li>{t(`${baseTranslationPath}.rescheduleRule2`)}</li>
        </ul>
      </Col>
      <Checkbox.Group>
        <Space direction="vertical">
          <ContentaCheckbox
            value={true}
            onChange={() => setTermsAccepted(!termsAccepted)}
          >
            <span className="checkout-modal__terms">
              {t(`${baseTranslationPath}.terms1`)}{' '}
              <a href={pdf} rel="noreferrer" download={pdf}>
                {t(`${baseTranslationPath}.terms2`)}
              </a>{' '}
              {t(`${baseTranslationPath}.terms3`)}
            </span>
          </ContentaCheckbox>
        </Space>
      </Checkbox.Group>
      <div className="checkout-modal__button-wrap">
        <ConfirmationButton
          disabled={!termsAccepted}
          briefingType={briefingType}
          onClick={onConfirm}
        >
          {t(`${baseTranslationPath}.confirmationButton`)}
        </ConfirmationButton>
        <p>{t(`${baseTranslationPath}.contact`)}</p>
      </div>
    </ModalContainer>
  );
};

export default CheckoutRulesModal;
