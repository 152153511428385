import bgImg from 'assets/images/traveller/banner-home.webp';
import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.section`
  .banner-home__content {
    ${PaddingContainer}
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    padding-bottom: 24px;
    padding-top: 24px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-flow: row nowrap;
    }
  }

  .banner-home__title {
    color: ${({ theme }) => theme.colors.neutralBlack[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 28px;
    font-style: normal;
    font-weight: lighter;
    letter-spacing: 1px;
    line-height: 64px;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: 500px;
    }

    strong {
      font-weight: 800;
    }
  }

  .banner-home__subtitle {
    color: ${({ theme }) => theme.colors.neutralWhite[40]};
    font-style: normal;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(16)};
    font-weight: 400;
    margin: 0 0 32px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      max-width: 35%;
    }
  }

  p + p {
    margin-top: 36px;
  }

  .banner-forum {
    .banner-home__content {
      background-image: url(${bgImg});
      min-height: 400px;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-flow: column;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        background-position: center;
        min-height: 550px;
      }
    }

    .ant-btn {
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        max-width: 200px;
      }
    }
  }
`;
