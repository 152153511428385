import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/logo-white.png';
import CarouselTexts from '../shared/CarouselTexts';
import LoginForm from '../shared/Form';
import { LoginContainer, LoginImage, SloganText } from '../shared/styled';

export function LoginAdvisor() {
  const { t } = useTranslation();
  const isDesktop = window.innerWidth >= 768;
  return (
    <LoginContainer>
      <div className="login-traveller__content login-advisor">
        <LoginImage src={logo} alt="Contenta 360 logo" />
        <LoginForm isAdvisor />
        <SloganText>{t('login.slogan')}</SloganText>
      </div>
      {isDesktop && (
        <div className="login-traveller__carousel">
          <CarouselTexts />
        </div>
      )}
    </LoginContainer>
  );
}
