import styled from 'styled-components';

export const ErrorContainer = styled.div`
  .error-instructions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    h3 {
      grid-column: 1 / 3;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
`;
