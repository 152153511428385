import auth0 from 'auth0-js';

export const authService = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientID: process.env.REACT_APP_CLIENT_ID,
  redirectUri: `${window.location.origin}/authenticate`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  audience: process.env.REACT_APP_AUDIENCE,
  leeway: 60,
});
