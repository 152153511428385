import { isUserAdmin, isUserGuide, isUserTraveller } from 'utils/isRole';
import HttpClient from './utils/HttpClient';

class UserService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getCurrentUser({ role }) {
    if (isUserGuide(role) || isUserAdmin(role)) {
      return this.httpClient.get(`/advisor/user`);
    }
    return this.httpClient.get(`/traveler/user`);
  }

  async getUserCurrency() {
    return this.httpClient.get(`/location`);
  }

  async savePhoto({ role, formData }) {
    if (isUserGuide(role)) {
      return this.httpClient.post(`/advisor/image`, formData);
    }
    return this.httpClient.post(`/traveler/image`, formData);
  }

  async saveGuideLicense({ formData }) {
    return this.httpClient.post(`/advisor/guide_license`, formData);
  }

  async resendEmailVerification({ role }) {
    const suffix = isUserTraveller(role) ? '/traveler' : '/advisor';

    return this.httpClient.post(`${suffix}/confirmation-email`);
  }
}

export default new UserService();
