import { Form, Modal } from 'antd';
import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';
import commonTexts from 'styles/commonTexts';

export const SearchMentorContainer = styled(Form)`
  width: 100%;
  z-index: 2;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-self: center;
    max-width: 1200px;
  }

  .scrollable-filters {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    height: 90px; /* 40px - more place for scrollbar, is hidden under parent box */
    justify-content: space-between;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 5px;
    white-space: nowrap;
    width: 100vw;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .swiper-slide {
    margin-bottom: 32px;
    width: auto;
  }

  .search-home__filters-option {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;
    padding: 0 12px;
  }

  .search-home__filters-more {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;

    span {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
      font-size: ${convertPxToRem(24)};
    }
  }

  .google-input {
    div > div {
      border-color: hsl(0, 0%, 80%);
      border: none;
      div span {
        background: none;
      }
    }
  }

  .search-home__content {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.neutralWhite[40]};
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr 1fr 50px;
      padding: 0 16px 0 0;
      margin-bottom: 80px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 72px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      margin-bottom: 64px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      margin-bottom: 46px;
    }

    .search-home__input-container {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.neutralWhite[40]};
      border-radius: 8px;
      display: flex;
      height: 100%;
      padding: 0 16px;
      transition: all 0.3s ease-in-out;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        border-right: 1px solid ${({ theme }) => theme.colors.table.header};
      }

      .ant-input {
        background-color: transparent;
        border: none !important;
        min-height: 30px;
        padding: 0;

        &:focus {
          border-color: transparent !important;
        }
      }

      span {
        font-family: ${({ theme }) => theme.fonts.texts};
      }
    }

    .ant-form-item {
      margin-bottom: 0;
      padding: 8px 0;
      width: 100%;
    }

    .ant-form-item-label {
      padding: 0;
      label {
        color: ${({ theme }) => theme.colors.neutralBlack[10]};
        font-family: ${({ theme }) => theme.fonts.texts};
        font-size: ${convertPxToRem(12)};
        font-weight: 800;
      }
    }

    .ant-form-item-control-input {
      min-height: 30px;
    }

    .ant-input {
    }

    .search-home__find {
      align-items: center;
      color: ${({ theme }) => theme.colors.pink.main};
      cursor: pointer;
      display: flex;
      flex-flow: row wrap;
      height: 100%;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      border-radius: 0 0 8px 8px;
      padding: 16px;
      position: relative;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        border-radius: 0 8px 8px 0;
        right: 0;
        width: calc(100% + 16px);
      }

      span {
        font-size: ${convertPxToRem(24)};
        font-weight: 700;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.pink.main};
        color: ${({ theme }) => theme.colors.light};
      }
    }
  }
`;

export const SpecialtiesModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-title {
    ${commonTexts.CommonTitle};
    font-size: ${convertPxToRem(24)};
    color: ${({ theme }) => theme.colors.grayTitle};
  }

  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }

  .search-home__modal-search {
    margin-bottom: 24px;

    .ant-select-selector {
      background: #ffffff;
      border: none;
      box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.1);
      border-radius: 8px;
      height: 55px;
      padding: 16px 24px;
      width: 100%;

      &::placeholder {
        color: ${({ theme }) => theme.colors.neutralBlack[20]};
        font-size: ${convertPxToRem(16)};
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.texts};
      }
    }

    .ant-select-arrow {
      background: none;
      border: none;
      color: ${({ theme }) => theme.colors.pink.main};
      cursor: pointer;
      font-size: ${convertPxToRem(32)};
      padding: 0;
      z-index: 2;
    }
  }

  .search-home__selected-filters {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    margin-bottom: 24px;
  }

  .search-home__selected-filters-option {
    background: ${({ theme }) => theme.colors.purple[30]};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(12)};
    font-weight: 700;
    padding: 4px 8px;

    .anticon {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
      margin-left: 8px;
      cursor: pointer;
      position: relative;
    }
  }

  .search-home__filters {
    display: grid;
    flex-flow: row wrap;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .search-home__filters-option {
    ${commonTexts.CommonText};
    align-items: center;
    border-radius: 4px;
    border: 1px solid #757575;
    color: ${({ theme }) => theme.colors.grayTitle};
    cursor: pointer;
    display: flex;
    font-size: ${convertPxToRem(14)};
    font-weight: 700;
    padding: 8px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.purple.main};
      border: 1px solid ${({ theme }) => theme.colors.purple.main};
      color: ${({ theme }) => theme.colors.light};
    }

    &.selected {
      border: 1px solid ${({ theme }) => theme.colors.purple.main};
    }
  }

  .ant-modal-footer {
    padding: 24px;
    width: 100%;

    .ant-btn {
      width: 100%;
    }
  }
`;
