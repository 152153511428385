import { Skeleton } from 'antd';
import * as S from './styles';

function SkeletonLoading() {
  return (
    <S.AvailableScheduleContainer>
      {Array.from({ length: 3 }).map((schedule, index) => (
        <Skeleton
          loading
          active
          title={false}
          paragraph={{ rows: 5 }}
          width={100}
          height={20}
          key={`skeleton-${index}`}
        />
      ))}
    </S.AvailableScheduleContainer>
  );
}

export default SkeletonLoading;
