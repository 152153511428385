import HttpClient from './utils/HttpClient';

class PaymentService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async checkoutSchedule(data) {
    return this.httpClient.post('/payment/checkout', data);
  }

  async retryPayment({ data, paymentId }) {
    return this.httpClient.post(
      `/payment/subscription/${paymentId}/retry`,
      data
    );
  }

  async giftcard(data) {
    return this.httpClient.post('/payment/giftcard', data);
  }

  async getGiftcard({ protocol, data }) {
    return this.httpClient.patch(`/payment/${protocol}`, data);
  }

  async createPaymentIntent({ data }) {
    return this.httpClient.post('/payment/payment-intent', data);
  }

  async checkStatus({ protocolId }) {
    return this.httpClient.get(`/payment/${protocolId}`);
  }

  async paymentSubscription() {
    return this.httpClient.post('/payment/subscription');
  }

  async getSubscriptionEdit() {
    return this.httpClient.get(`/payment/subscription/setup`);
  }

  async getClientSecretGiftCard({ data }) {
    return this.httpClient.post('/payment/gift-card', data);
  }
}

export default new PaymentService();
