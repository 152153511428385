import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const GiftCardContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.blue[30]};
  display: flex;
  flex-flow: column;
  gap: 16px;
  position: relative;
  padding: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    padding: 48px 0;
    justify-content: center;
    flex-flow: row nowrap;
  }

  p {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(24)};
    font-weight: 700;
    line-height: initial;
    margin: 0;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${convertPxToRem(40)};
      max-width: 60%;
      text-align: left;
    }
  }

  .ant-btn {
    border-color: ${({ theme }) => theme.colors.neutralWhite[10]}!important;
    width: 275px;

    &.btn-rounded {
      border-radius: 100px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      gap: 0;
      height: 52px;
      width: 52px;
      position: absolute;
      bottom: 45px;
      right: 16px;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        bottom: 45px;
        right: 35px;
      }
    }
  }
`;
