import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import fetcher from 'services/fetcher';
import useSWR from 'swr';

import ContentTable from './ContentTable';
import { Loader } from 'components/Loader';

function TablesContainer({ currency }) {
  const { t } = useTranslation();
  const { data, isLoading } = useSWR(`/range-tax/${currency}`, fetcher);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h2>{t('guides.presentialTour')}</h2>
      <ContentTable
        currency={currency}
        values={data?.['PRESENTIAL_SERVICE']}
        isLoading={isLoading}
        schedulingType="PRESENTIAL_SERVICE"
      />

      <Divider />

      <h2>{t('guides.videoCallPrice')}</h2>
      <ContentTable
        currency={currency}
        values={data?.['ONLINE_SERVICE']}
        isLoading={isLoading}
        schedulingType="ONLINE_SERVICE"
      />
    </div>
  );
}

export default TablesContainer;
