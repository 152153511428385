import { MEETING_TYPES } from 'constants/types';
import useFilterMentors from 'hooks/useFilterMentors';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MentorService from 'services/MentorService';
import { errorHandler } from 'utils/errorHandler';

const SearchContext = createContext({});

export function SearchProvider({ children }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { tagFilters, toggleTagFilter } = useFilterMentors();
  const [searchedParams, setSearchedParams] = useState({
    scheduling_type: MEETING_TYPES.online,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [foundAdvisors, setFoundAdvisors] = useState([]);
  const [isLastPage, setIsLastPage] = useState(false);

  const handleFilterClick = (filters) => {
    if (Array.isArray(filters)) {
      filters.forEach((filter) => {
        toggleTagFilter(filter);
      });
    } else {
      toggleTagFilter(filters);
    }
  };

  const handleRedirectWithFilters = (filter) => {
    handleFilterClick(filter);
    navigate('/search-advisor', {
      state: { specialty: filter },
    });
  };

  const serializeFilters = (filters) => {
    if (typeof filters === 'string') return filters;

    const searchFilters = new URLSearchParams();

    // Adiciona apenas os valores definidos aos parâmetros da URL
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== 'undefined' && value !== null) {
        searchFilters.append(String(key), String(value));
      }
    });

    return searchFilters.toString();
  };

  const getAdvisors = async (filters, page = currentPage) => {
    const isPageExist = filters.includes('page');
    const searchFilters = new URLSearchParams(filters);
    let pageFilter;
    if (isPageExist) {
      searchFilters.forEach((value, key) => {
        if (key === 'page') {
          pageFilter = value;
          searchFilters.delete(key);
        }
      });
    }
    try {
      const response = await MentorService.getAdvisorsSearchResults({
        filters,
        page: pageFilter || page,
        limit: 10,
      });

      return response;
    } catch (error) {
      errorHandler({
        error,
        typeError: 'ADVISORS',
      });

      return [];
    }
  };

  const getMoreData = async () => {
    const nextPage = currentPage + 1;
    let filters = serializeFilters(searchedParams);
    const isPageExist = filters.includes('page');
    if (isPageExist) {
      filters = filters.replace(/page=\d+/, `page=${nextPage}`);
    } else {
      filters = `${filters}&page=${nextPage}`;
    }
    const response = await getAdvisors(filters, nextPage);
    setIsLastPage(response?.last);
    setCurrentPage(nextPage);
    const mentorData = response?.content;
    setFoundAdvisors([...foundAdvisors, ...mentorData]);

    return response;
  };

  const handleSearchClick = (filterValues) => {
    setSearchedParams(filterValues);
    const filters = serializeFilters(filterValues);

    const pathname = '/search-advisor';
    const isSearchMentorPage = window.location.pathname === pathname;

    if (isSearchMentorPage) {
      return;
    }

    const newUrl = `${pathname}`;
    navigate(newUrl, {
      state: { filters },
    });
  };

  useEffect(() => {
    toggleTagFilter(state?.specialty?.[0]);
  }, []);

  const value = useMemo(
    () => ({
      tagFilters,
      isLastPage,
      currentPage,
      foundAdvisors,
      searchedParams,
      getAdvisors,
      getMoreData,
      setIsLastPage,
      toggleTagFilter,
      serializeFilters,
      setFoundAdvisors,
      handleSearchClick,
      handleFilterClick,
      setSearchedParams,
      handleRedirectWithFilters,
      setCurrentPage,
    }),
    [tagFilters, searchedParams, foundAdvisors, isLastPage, currentPage]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

export const useSearch = () => React.useContext(SearchContext);
