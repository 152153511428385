import * as React from 'react';
const KoreaIconFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={20}
    fill="none"
    {...props}
  >
    <rect
      width={27.5}
      height={19.5}
      x={0.25}
      y={0.25}
      fill="#fff"
      stroke="#F5F5F5"
      strokeWidth={0.5}
      rx={1.75}
    />
    <mask
      id="a"
      width={28}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <rect
        width={27.5}
        height={19.5}
        x={0.25}
        y={0.25}
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.5}
        rx={1.75}
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#E01B41"
        fillRule="evenodd"
        d="M13.999 14.666a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333Z"
        clipRule="evenodd"
      />
      <mask
        id="b"
        width={10}
        height={10}
        x={9}
        y={5}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13.999 14.666a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333Z"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#0E4B9C"
          fillRule="evenodd"
          d="M9.33 10.666c1.334 1.25 3.334 2 4.668 0 1.333-2 4-2 4.666 0 .667 2 0 4 0 4H9.331s-1.333-5.25 0-4Z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#262626"
        fillRule="evenodd"
        d="M6.172 3.556a.333.333 0 0 1 .456-.122l.577.334c.16.092.214.296.122.455L5.66 7.11a.333.333 0 0 1-.455.122l-.577-.334a.333.333 0 0 1-.122-.455l1.666-2.887Zm14.622.212a.333.333 0 0 0-.122.455l1.666 2.887c.092.16.296.214.456.122l.577-.334a.333.333 0 0 0 .122-.455l-1.667-2.887a.333.333 0 0 0-.455-.122l-.577.334Zm-1.733 1a.333.333 0 0 0-.122.455l1.667 2.887c.092.16.296.214.455.122l.578-.334a.333.333 0 0 0 .122-.455l-1.667-2.887a.333.333 0 0 0-.455-.122l-.578.334ZM4.506 13.556a.333.333 0 0 1 .122-.455l.577-.333a.333.333 0 0 1 .455.122l1.667 2.886a.333.333 0 0 1-.122.456l-.577.333a.333.333 0 0 1-.456-.122l-1.666-2.887Zm1.854-1.455a.333.333 0 0 0-.122.455l1.666 2.887c.092.16.296.214.456.122l.577-.333a.333.333 0 0 0 .122-.455L7.392 11.89a.333.333 0 0 0-.455-.122l-.577.333Zm14.246-.211a.333.333 0 0 1 .455-.122l.578.333c.16.092.214.296.122.455l-1.667 2.887a.333.333 0 0 1-.455.122l-.578-.333a.333.333 0 0 1-.122-.455l1.667-2.887Zm2.188.878a.333.333 0 0 0-.456.122l-1.666 2.886a.333.333 0 0 0 .122.456l.577.333c.16.092.363.038.455-.122l1.667-2.887a.333.333 0 0 0-.122-.455l-.577-.333ZM8.36 4.434a.333.333 0 0 0-.456.122L6.238 7.443a.333.333 0 0 0 .122.455l.577.334c.16.092.363.037.455-.122L9.06 5.223a.333.333 0 0 0-.122-.455l-.577-.334Z"
        clipRule="evenodd"
        opacity={0.75}
      />
    </g>
  </svg>
);
export default KoreaIconFlag;
