import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: center;

  /* breakpoints.lg */
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }

  .login-traveller__carousel {
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      display: block;
    }
  }

  .login-traveller {
    background: linear-gradient(
      31.24deg,
      #ff0134 0%,
      #fa34c7 50%,
      #d41ae9 75%,
      #bc09ff 100%
    );
  }

  .login-advisor {
    background: linear-gradient(31.24deg, #1a00ff 0%, #50abff 100%);
  }

  .login-traveller__content {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .login-form {
    min-width: 360px;
  }
`;

export const LoginImage = styled.img`
  margin-bottom: 24px;
  width: 240px;
`;

export const LoginContent = styled.div``;

export const LoginCarouselTexts = styled.div``;

export const LoginBoxHeader = styled.header`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 1.25px;
  }
`;

export const FooterLogin = styled.footer`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-decoration: underline;
  }
`;

export const SloganText = styled.p`
  color: ${({ theme }) => theme.colors.neutralWhite[40]};
  font-family: ${(props) => props.theme.fonts.texts};
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-align: center;
  margin: 8px 0 0;
`;

export const CarouselTextsSwipper = styled(Swiper)`
  max-width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .swiper-wrapper {
    height: auto;
  }

  .swiper-pagination {
    top: 75%;
    left: -170px;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.pink[30]};
  }
`;
