import { Divider } from 'antd';
import { useState } from 'react';

import FinancialInputs from './FinancialInputs';
import RegisteredAdvisorInfo from './RegisteredAdvisorInfo';
import RegisteredLicense from './RegisteredLicense';
import ScrollToTop from 'components/ScrollToTop';

export function SecondStep() {
  const [isRequiredLicense, setIsRequiredLicense] = useState(false);

  return (
    <>
      <ScrollToTop />
      <RegisteredLicense setIsRequiredLicense={setIsRequiredLicense} />

      <Divider />

      <RegisteredAdvisorInfo
        isRequiredLicense={isRequiredLicense}
        setIsRequiredLicense={setIsRequiredLicense}
      />

      <FinancialInputs />
    </>
  );
}
