import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const MentorAboutContainer = styled.div`
  margin: 20px 0;

  .mentor-about__block-content {
    margin-bottom: 20px;
  }

  p {
    color: #868484;
    font-size: ${convertPxToRem(16)};
    font-weight: 500;
    margin: 0 0 8px;
  }

  .mentor-about__link {
    color: ${({ theme }) => theme.colors.pink[30]};
    cursor: pointer;
    font-size: ${convertPxToRem(16)};
    font-weight: 700;
  }

  .mentor-about__block-title {
    font-size: ${convertPxToRem(18)};
    font-weight: 800;
    margin: 0 0 8px;
  }
`;
