import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const ContainerGiftCardModal = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin: 0;
  }

  .gift-modal__input-currency {
    width: 100%;

    .ant-input-number {
      border-radius: 8px;
      border: 1px solid;
      width: 100%;
    }
  }

  .gift-modal__choose {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 24px;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  .gift-modal__choose-option {
    background: ${(props) => props.theme.colors.neutralWhite[10]};
    border: 1px solid ${(props) => props.theme.colors.neutralWhite[40]};
    border-radius: 8px;
    height: 167px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 16px;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    padding: 24px;
    position: relative;
    width: 50%;

    .gift-modal__empty-circle {
      background: #ffffff;
      border-radius: 50px;
      border: 2px solid rgba(0, 0, 0, 0.4);
      height: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 20px;
    }

    .anticon-check-circle {
      font-size: ${convertPxToRem(20)};
      color: rgba(0, 0, 0, 0.4);
      position: absolute;
      right: 16px;
      top: 16px;
    }

    span {
      color: ${(props) => props.theme.colors.neutralBlack[40]};
      font-family: ${(props) => props.theme.fonts.primary};
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
      text-align: center;
    }
  }

  footer {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    margin-top: 36px;
    width: 100%;
  }

  footer button {
    width: 100%;
  }
`;
