import { Header } from '../styles';

import logoWhite from '../../../../assets/images/logo-white.png';
import Avatar from 'components/Avatar';

function TravellerRegisterHeader() {
  return (
    <Header>
      <Avatar src={logoWhite} alt="" className="header__logo" />
    </Header>
  );
}

export default TravellerRegisterHeader;
