import { keyRole } from './isRole';

function checkIsRegistered({ whoAmI }) {
  const hasRole = whoAmI[keyRole];
  if (hasRole && hasRole.length > 0) {
    return true;
  }

  return false;
}

export default checkIsRegistered;
