export const COUNTRIES_LIST = [
  { id: 1, name: 'Brazil', code: 'BR' },
  { id: 2, name: 'Afghanistan', code: 'AF' },
  { id: 3, name: 'Albania', code: 'AL' },
  { id: 4, name: 'Algeria', code: 'DZ' },
  { id: 5, name: 'American Samoa', code: 'AS' },
  { id: 6, name: 'Andorra', code: 'AD' },
  { id: 7, name: 'Angola', code: 'AO' },
  { id: 8, name: 'Anguilla', code: 'AI' },
  { id: 9, name: 'Antarctica', code: 'AQ' },
  { id: 10, name: 'Antigua and Barbuda', code: 'AG' },
  { id: 11, name: 'Argentina', code: 'AR' },
  { id: 12, name: 'Armenia', code: 'AM' },
  { id: 13, name: 'Aruba', code: 'AW' },
  { id: 14, name: 'Australia', code: 'AU' },
  { id: 15, name: 'Austria', code: 'AT' },
  { id: 16, name: 'Azerbaijan', code: 'AZ' },
  { id: 17, name: 'Bahamas', code: 'BS' },
  { id: 18, name: 'Bahrain', code: 'BH' },
  { id: 19, name: 'Bangladesh', code: 'BD' },
  { id: 20, name: 'Barbados', code: 'BB' },
  { id: 21, name: 'Belarus', code: 'BY' },
  { id: 22, name: 'Belgium', code: 'BE' },
  { id: 23, name: 'Belize', code: 'BZ' },
  { id: 24, name: 'Benin', code: 'BJ' },
  { id: 25, name: 'Bermuda', code: 'BM' },
  { id: 26, name: 'Bhutan', code: 'BT' },
  { id: 27, name: 'Bolivia', code: 'BO' },
  { id: 28, name: 'Bosnia and Herzegowina', code: 'BA' },
  { id: 29, name: 'Botswana', code: 'BW' },
  { id: 30, name: 'Bouvet Island', code: 'BV' },
  {
    id: 31,
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  { id: 32, name: 'Brunei Darussalam', code: 'BN' },
  { id: 33, name: 'Bulgaria', code: 'BG' },
  { id: 34, name: 'Burkina Faso', code: 'BF' },
  { id: 35, name: 'Burundi', code: 'BI' },
  { id: 36, name: 'Cambodia', code: 'KH' },
  { id: 37, name: 'Cameroon', code: 'CM' },
  { id: 38, name: 'Canada', code: 'CA' },
  { id: 39, name: 'Cape Verde', code: 'CV' },
  { id: 40, name: 'Cayman Islands', code: 'KY' },
  { id: 41, name: 'Central African Republic', code: 'CF' },
  { id: 42, name: 'Chad', code: 'TD' },
  { id: 43, name: 'Chile', code: 'CL' },
  { id: 44, name: 'China', code: 'CN' },
  { id: 45, name: 'Christmas Island', code: 'CX' },
  { id: 46, name: 'Cocos (Keeling) Islands', code: 'CC' },
  { id: 47, name: 'Colombia', code: 'CO' },
  { id: 48, name: 'Comoros', code: 'KM' },
  { id: 49, name: 'Congo', code: 'CG' },
  {
    id: 50,
    name: 'Congo, the Democratic Republic of the',
    code: 'CD',
  },
  { id: 51, name: 'Cook Islands', code: 'CK' },
  { id: 52, name: 'Costa Rica', code: 'CR' },
  { id: 53, name: 'Cote d`Ivoire', code: 'CI' },
  { id: 54, name: 'Croatia (Hrvatska)', code: 'HR' },
  { id: 55, name: 'Cuba', code: 'CU' },
  { id: 56, name: 'Cyprus', code: 'CY' },
  { id: 57, name: 'Czech Republic', code: 'CZ' },
  { id: 58, name: 'Denmark', code: 'DK' },
  { id: 59, name: 'Djibouti', code: 'DJ' },
  { id: 60, name: 'Dominica', code: 'DM' },
  { id: 61, name: 'Dominican Republic', code: 'DO' },
  { id: 62, name: 'East Timor', code: 'TL' },
  { id: 63, name: 'Ecuador', code: 'EC' },
  { id: 64, name: 'Egypt', code: 'EG' },
  { id: 65, name: 'El Salvador', code: 'SV' },
  { id: 66, name: 'Equatorial Guinea', code: 'GQ' },
  { id: 67, name: 'Eritrea', code: 'ER' },
  { id: 68, name: 'Estonia', code: 'EE' },
  { id: 69, name: 'Ethiopia', code: 'ET' },
  {
    id: 70,
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  { id: 71, name: 'Faroe Islands', code: 'FO' },
  { id: 72, name: 'Fiji', code: 'FJ' },
  { id: 73, name: 'Finland', code: 'FI' },
  { id: 74, name: 'France', code: 'FR' },
  { id: 76, name: 'French Guiana', code: 'GF' },
  { id: 77, name: 'French Polynesia', code: 'PF' },
  {
    id: 78,
    name: 'French Southern Territories',
    code: 'TF',
  },
  { id: 79, name: 'Gabon', code: 'GA' },
  { id: 80, name: 'Gambia', code: 'GM' },
  { id: 81, name: 'Georgia', code: 'GE' },
  { id: 82, name: 'Germany', code: 'DE' },
  { id: 83, name: 'Ghana', code: 'GH' },
  { id: 84, name: 'Gibraltar', code: 'GI' },
  { id: 85, name: 'Greece', code: 'GR' },
  { id: 86, name: 'Greenland', code: 'GL' },
  { id: 87, name: 'Grenada', code: 'GD' },
  { id: 88, name: 'Guadeloupe', code: 'GP' },
  { id: 89, name: 'Guam', code: 'GU' },
  { id: 90, name: 'Guatemala', code: 'GT' },
  { id: 91, name: 'Guinea', code: 'GN' },
  { id: 92, name: 'Guinea-Bissau', code: 'GW' },
  { id: 93, name: 'Guyana', code: 'GY' },
  { id: 94, name: 'Haiti', code: 'HT' },
  {
    id: 95,
    name: 'Heard and Mc Donald Islands',
    code: 'HM',
  },
  {
    id: 96,
    name: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  { id: 97, name: 'Honduras', code: 'HN' },
  { id: 98, name: 'Hong Kong', code: 'HK' },
  { id: 99, name: 'Hungary', code: 'HU' },
  { id: 100, name: 'Iceland', code: 'IS' },
  { id: 101, name: 'India', code: 'IN' },
  { id: 102, name: 'Indonesia', code: 'ID' },
  {
    id: 103,
    name: 'Iran (Islamic Republic of)',
    code: 'IR',
  },
  { id: 104, name: 'Iraq', code: 'IQ' },
  { id: 105, name: 'Ireland', code: 'IE' },
  { id: 106, name: 'Israel', code: 'IL' },
  { id: 107, name: 'Italy', code: 'IT' },
  { id: 108, name: 'Jamaica', code: 'JM' },
  { id: 109, name: 'Japan', code: 'JP' },
  { id: 110, name: 'Jordan', code: 'JO' },
  { id: 111, name: 'Kazakhstan', code: 'KZ' },
  { id: 112, name: 'Kenya', code: 'KE' },
  { id: 113, name: 'Kiribati', code: 'KI' },
  {
    id: 114,
    name: 'Korea, Democratic People`s Republic of',
    code: 'KP',
  },
  { id: 115, name: 'Korea, Republic of', code: 'KR' },
  { id: 116, name: 'Kuwait', code: 'KW' },
  { id: 117, name: 'Kyrgyzstan', code: 'KG' },
  {
    id: 118,
    name: 'Lao People`s Democratic Republic',
    code: 'LA',
  },
  { id: 119, name: 'Latvia', code: 'LV' },
  { id: 120, name: 'Lebanon', code: 'LB' },
  { id: 121, name: 'Lesotho', code: 'LS' },
  { id: 122, name: 'Liberia', code: 'LR' },
  { id: 123, name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { id: 124, name: 'Liechtenstein', code: 'LI' },
  { id: 125, name: 'Lithuania', code: 'LT' },
  { id: 126, name: 'Luxembourg', code: 'LU' },
  { id: 127, name: 'Macau', code: 'MO' },
  { id: 128, name: 'North Macedonia', code: 'MK' },
  { id: 129, name: 'Madagascar', code: 'MG' },
  { id: 130, name: 'Malawi', code: 'MW' },
  { id: 131, name: 'Malaysia', code: 'MY' },
  { id: 132, name: 'Maldives', code: 'MV' },
  { id: 133, name: 'Mali', code: 'ML' },
  { id: 134, name: 'Malta', code: 'MT' },
  { id: 135, name: 'Marshall Islands', code: 'MH' },
  { id: 136, name: 'Martinique', code: 'MQ' },
  { id: 137, name: 'Mauritania', code: 'MR' },
  { id: 138, name: 'Mauritius', code: 'MU' },
  { id: 139, name: 'Mayotte', code: 'YT' },
  { id: 140, name: 'Mexico', code: 'MX' },
  {
    id: 141,
    name: 'Micronesia, Federated States of',
    code: 'FM',
  },
  { id: 142, name: 'Moldova, Republic of', code: 'MD' },
  { id: 143, name: 'Monaco', code: 'MC' },
  { id: 144, name: 'Mongolia', code: 'MN' },
  { id: 145, name: 'Montserrat', code: 'MS' },
  { id: 146, name: 'Morocco', code: 'MA' },
  { id: 147, name: 'Mozambique', code: 'MZ' },
  { id: 148, name: 'Myanmar', code: 'MM' },
  { id: 149, name: 'Namibia', code: 'NA' },
  { id: 150, name: 'Nauru', code: 'NR' },
  { id: 151, name: 'Nepal', code: 'NP' },
  { id: 152, name: 'Netherlands', code: 'NL' },
  { id: 154, name: 'New Caledonia', code: 'NC' },
  { id: 155, name: 'New Zealand', code: 'NZ' },
  { id: 156, name: 'Nicaragua', code: 'NI' },
  { id: 157, name: 'Niger', code: 'NE' },
  { id: 158, name: 'Nigeria', code: 'NG' },
  { id: 159, name: 'Niue', code: 'NU' },
  { id: 160, name: 'Norfolk Island', code: 'NF' },
  { id: 161, name: 'Northern Mariana Islands', code: 'MP' },
  { id: 162, name: 'Norway', code: 'NO' },
  { id: 163, name: 'Oman', code: 'OM' },
  { id: 164, name: 'Pakistan', code: 'PK' },
  { id: 165, name: 'Palau', code: 'PW' },
  { id: 166, name: 'Panama', code: 'PA' },
  { id: 167, name: 'Papua New Guinea', code: 'PG' },
  { id: 168, name: 'Paraguay', code: 'PY' },
  { id: 169, name: 'Peru', code: 'PE' },
  { id: 170, name: 'Philippines', code: 'PH' },
  { id: 171, name: 'Pitcairn', code: 'PN' },
  { id: 172, name: 'Poland', code: 'PL' },
  { id: 173, name: 'Portugal', code: 'PT' },
  { id: 174, name: 'Puerto Rico', code: 'PR' },
  { id: 175, name: 'Qatar', code: 'QA' },
  { id: 176, name: 'Reunion', code: 'RE' },
  { id: 177, name: 'Romania', code: 'RO' },
  { id: 178, name: 'Russian Federation', code: 'RU' },
  { id: 179, name: 'Rwanda', code: 'RW' },
  { id: 180, name: 'Saint Kitts and Nevis', code: 'KN' },
  { id: 181, name: 'Saint LUCIA', code: 'LC' },
  {
    id: 182,
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  { id: 183, name: 'Samoa', code: 'WS' },
  { id: 184, name: 'San Marino', code: 'SM' },
  { id: 185, name: 'Sao Tome and Principe', code: 'ST' },
  { id: 186, name: 'Saudi Arabia', code: 'SA' },
  { id: 187, name: 'Senegal', code: 'SN' },
  { id: 188, name: 'Seychelles', code: 'SC' },
  { id: 189, name: 'Sierra Leone', code: 'SL' },
  { id: 190, name: 'Singapore', code: 'SG' },
  {
    id: 191,
    name: 'Slovakia (Slovak Republic)',
    code: 'SK',
  },
  { id: 192, name: 'Slovenia', code: 'SI' },
  { id: 193, name: 'Solomon Islands', code: 'SB' },
  { id: 194, name: 'Somalia', code: 'SO' },
  { id: 195, name: 'South Africa', code: 'ZA' },
  {
    id: 196,
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  { id: 197, name: 'Spain', code: 'ES' },
  { id: 198, name: 'Sri Lanka', code: 'LK' },
  { id: 199, name: 'St. Helena', code: 'SH' },
  { id: 200, name: 'St. Pierre and Miquelon', code: 'PM' },
  { id: 201, name: 'Sudan', code: 'SD' },
  { id: 202, name: 'Suriname', code: 'SR' },
  {
    id: 203,
    name: 'Svalbard and Jan Mayen Islands',
    code: 'SJ',
  },
  { id: 204, name: 'Swaziland', code: 'SZ' },
  { id: 205, name: 'Sweden', code: 'SE' },
  { id: 206, name: 'Switzerland', code: 'CH' },
  { id: 207, name: 'Syrian Arab Republic', code: 'SY' },
  { id: 208, name: 'Taiwan', code: 'TW' },
  { id: 209, name: 'Tajikistan', code: 'TJ' },
  {
    id: 210,
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  { id: 211, name: 'Thailand', code: 'TH' },
  { id: 212, name: 'Togo', code: 'TG' },
  { id: 213, name: 'Tokelau', code: 'TK' },
  { id: 214, name: 'Tonga', code: 'TO' },
  { id: 215, name: 'Trinidad and Tobago', code: 'TT' },
  { id: 216, name: 'Tunisia', code: 'TN' },
  { id: 217, name: 'Turkey', code: 'TR' },
  { id: 218, name: 'Turkmenistan', code: 'TM' },
  { id: 219, name: 'Turks and Caicos Islands', code: 'TC' },
  { id: 220, name: 'Tuvalu', code: 'TV' },
  { id: 221, name: 'Uganda', code: 'UG' },
  { id: 222, name: 'Ukraine', code: 'UA' },
  { id: 223, name: 'United Arab Emirates', code: 'AE' },
  { id: 224, name: 'United Kingdom', code: 'GB' },
  { id: 225, name: 'United States', code: 'US' },
  {
    id: 226,
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  { id: 227, name: 'Uruguay', code: 'UY' },
  { id: 228, name: 'Uzbekistan', code: 'UZ' },
  { id: 229, name: 'Vanuatu', code: 'VU' },
  { id: 230, name: 'Venezuela', code: 'VE' },
  { id: 231, name: 'Viet Nam', code: 'VN' },
  { id: 232, name: 'Virgin Islands (British)', code: 'VG' },
  { id: 233, name: 'Virgin Islands (U.S.)', code: 'VI' },
  { id: 234, name: 'Wallis and Futuna Islands', code: 'WF' },
  { id: 235, name: 'Western Sahara', code: 'EH' },
  { id: 236, name: 'Yemen', code: 'YE' },
  { id: 237, name: 'Yugoslavia', code: 'YU' },
  { id: 238, name: 'Zambia', code: 'ZM' },
  { id: 239, name: 'Zimbabwe', code: 'ZW' },
  { id: 240, name: 'Bailiwick of Guernsey', code: 'GG' },
  { id: 241, name: 'Bailiwick of Jersey', code: 'JE' },
  { id: 243, name: 'Isle of Man', code: 'IM' },
  { id: 246, name: 'Crna Gora (Montenegro)', code: 'ME' },
  { id: 247, name: 'SÉRVIA', code: 'RS' },
  { id: 248, name: 'Republic of South Sudan', code: 'SS' },
  { id: 249, name: 'Zona del Canal de Panamá' },
  { id: 252, name: 'Dawlat Filasṭīn', code: 'PS' },
  { id: 253, name: 'Åland Islands', code: 'AX' },
  { id: 254, name: 'Saint Barthélemy', code: 'BL' },
  { id: 255, name: 'Curaçao', code: 'CW' },
  { id: 256, name: 'Saint Martin', code: 'SM' },
  { id: 258, name: 'Bonaire', code: 'AN' },
  { id: 259, name: 'Antartica', code: 'AQ' },
  {
    id: 260,
    name: 'Heard Island and McDonald Islands',
    code: 'AU',
  },
  { id: 261, name: 'Saint-Barthélemy', code: 'FR' },
  { id: 262, name: 'Saint Martin', code: 'SM' },
  {
    id: 263,
    name: 'Terres Australes et Antarctiques Françaises',
    code: 'TF',
  },
];
