import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const queryParams = {};

    params.forEach((value, key) => (queryParams[key] = value));

    return queryParams;
  }, [search]);
}
