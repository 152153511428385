import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  ${PaddingContainer};
  margin-bottom: 24px;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 32px;
    margin-top: 56px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(28)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .privacy__content {
    h3 {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.25px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    p {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
      margin-bottom: 24px;
      margin-left: 32px;
      text-align: justify;
    }
  }

  p:first-child {
    margin-left: 0;
  }
`;
