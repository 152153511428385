import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import travelLoading from '../../assets/images/animations/travel-loading.json';
import isMobile from 'utils/isMobile';

function RedirectLoader() {
  const { t } = useTranslation();

  let styles = {};
  if (isMobile()) {
    styles = {
      width: '200px',
      height: 'auto',
      marginBotton: '24px',
    };
  } else {
    styles = {
      width: '400px',
      height: '50%',
      marginBotton: '24px',
    };
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 64px)',
      }}
    >
      <Lottie animationData={travelLoading} loop={true} style={styles} />
      <p>{t('common.loading')}...</p>
    </div>
  );
}

export default RedirectLoader;
