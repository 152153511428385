import styled from 'styled-components';

const getSize = (size) => {
  switch (size) {
    case 'xxsmall':
      return '10px';

    case 'xsmall':
      return '12px';
    case 'small':
      return '14px';

    case 'medium':
      return '16px';

    case 'large':
      return '18px';

    default:
      return '14px';
  }
};

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return '#4A4647';

    case 'secondary':
      return '#868484';

    default:
      return '#4A4647';
  }
};

/**
 * ContentaText is a styled component for text content.
 * @param {string} size - The size of the text. Can be 'small', 'medium', or 'large'.
 * @param {string} color - The color of the text. Can be 'primary' or 'secondary'.
 * @param {boolean} bold - Whether the text should be bold or not.
 */
export const ContentaText = styled.p`
  font-family: ${({ theme }) => theme.fonts.texts};
  font-size: ${({ size }) => getSize(size)};
  color: ${({ color }) => getColor(color)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: 22px;
  letter-spacing: 1.25px;
  text-decoration-skip-ink: none;
`;
