import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { useUser } from 'contexts/userContext';
import { Outlet } from 'react-router-dom';

export function UnloggedRoute({ children }) {
  const { isLoading } = useUser();

  if (isLoading) {
    return <RedirectLoader />;
  }

  return <ContainerApp>{children || <Outlet />}</ContainerApp>;
}
