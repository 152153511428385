export const keyRole = 'https://contenta360.com/roles/roles';

export const ROLES = {
  ADMIN: 'ADMIN',
  ADVISOR: 'ADVISOR',
  APPROVED_ADVISOR: 'APPROVED_ADVISOR',
  USER: 'TRAVELER',
  USER_TRAVELER: 'TRAVELER',
};

export function isUserAdmin(userRole) {
  if (!userRole) return false;
  return userRole === ROLES.ADMIN;
}

export function isUserGuide(userRole) {
  if (!userRole) return false;
  return userRole === ROLES.ADVISOR;
}

export function isUserTraveller(userRole) {
  if (!userRole) return false;
  return userRole === ROLES.USER || userRole === ROLES.USER_TRAVELER;
}

export function canViewAdmin(user) {
  const roles = user[keyRole];
  return roles.includes(ROLES.ADMIN);
}

export function canViewGuide(user) {
  const roles = user[keyRole];
  return roles.includes(ROLES.ADVISOR);
}

export function canViewUser(user) {
  const roles = user[keyRole];
  return roles.includes(ROLES.USER) || roles.includes(ROLES.USER_TRAVELER);
}
