import { useLocation } from 'react-router-dom';
import findCountryNameById from 'utils/findCountryNameById';
import { ContentaBreadcrumb } from '../../../../components/ContentaBreadcrumb';
import { useMentor } from '../mentorContext';
import { MentorHeader } from '../styles';

function MentorDetailsHeader() {
  const { mentor } = useMentor();
  const { state } = useLocation();
  const advisorState = state?.advisor;
  const country = findCountryNameById({
    id: mentor?.country?.acronym || advisorState?.country?.acronym,
  });

  const breadcrumbItems = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/search-advisor',
      label: 'breadcrumb.advisors',
    },
    {
      label: mentor?.name || advisorState?.name,
    },
  ];

  return (
    <>
      <ContentaBreadcrumb items={breadcrumbItems} />
      <MentorHeader>
        <h2>{mentor?.city || advisorState?.city}</h2>
        <h3>{country}</h3>
      </MentorHeader>
    </>
  );
}

export default MentorDetailsHeader;
