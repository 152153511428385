import HttpClient from './utils/HttpClient';

class MembershipService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getMembership() {
    return await this.httpClient.get('/subscription/product');
  }

  async cancelMembership(data) {
    return await this.httpClient.patch('/subscription', {
      ...data,
      action: 'disable',
    });
  }

  async updateMembership(data) {
    return await this.httpClient.patch('/subscription', data);
  }

  async getReasons() {
    return this.httpClient.get('/subscription/reason/disable');
  }
}

export default new MembershipService();
