export const MEETING_TYPES = {
  online: 'ONLINE_SERVICE',
  presential: 'PRESENTIAL_SERVICE',
};

export const USER_TYPES = {
  admin: 'ADMIN',
  advisor: 'ADVISOR',
  traveler: 'TRAVELER',
};

export const STATUS_TYPE = {
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
};

export const SCHEDULE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  SCHEDULED: 'SCHEDULED',
  UNAVAILABLE: 'UNAVAILABLE',
  CANCELED: 'CANCELED',
  RESERVED: 'RESERVED',
};

export const BRIEFING_TYPES = {
  ONLINE: MEETING_TYPES.online,
  IN_PERSON: MEETING_TYPES.presential,
};

export const MOCK_USER_PICTURE =
  'https://randomuser.me/api/portraits/lego/8.jpg';

export const ROLES_TYPES = {
  ADMIN: 'ADMIN',
  ADVISOR: 'ADVISOR',
  APROVED_ADVISOR: 'APROVED_ADVISOR',
  TRAVELER: 'TRAVELER',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  PAUSED: 'PAUSED',
  INACTIVE: 'INACTIVE',
};
