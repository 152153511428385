import logo from 'assets/images/logo.png';
import { Loader } from 'components/Loader';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { authService } from 'services/Auth0Service';
import * as S from './styles';

export function Logout() {
  const removeLocalStorage = () => {
    Cookies.remove(CONTENTA_TOKEN);
    const keys = Object.keys(sessionStorage);
    keys.forEach((key) => {
      if (key.startsWith('@contenta-')) {
        sessionStorage.removeItem(key);
      }
    });

    const authTokenKey = Object.keys(localStorage).filter((key) =>
      key.startsWith('@@auth0spajs@@')
    );
    localStorage.removeItem(authTokenKey[0]);
  };

  const handleLogout = async () => {
    const baseUrl = window.location.origin;
    removeLocalStorage();
    authService.logout({
      returnTo: baseUrl,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LoginLogo src={logo} alt="Contenta 360 logo" />
        <Loader />
      </S.LoginContainer>
    </S.Container>
  );
}
