import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { useUser } from 'contexts/userContext';
import { Navigate, Outlet } from 'react-router-dom';
import { canViewAdmin } from 'utils/isRole';

export function ProtectedRouteAdmin({ user, children }) {
  const { isLoading, whoAmI } = useUser();

  const isAuthenticated = Object.keys(user).length > 0;

  if (isLoading) {
    return <RedirectLoader />;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="login" />;
  }

  if (!canViewAdmin(user || whoAmI)) {
    return <Navigate to="not-allowed" />;
  }

  return <ContainerApp>{children || <Outlet />}</ContainerApp>;
}
