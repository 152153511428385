import { Col, Form, Input } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { CONTENTA_REGISTER_USER } from 'constants/storages-values';
import LoginByGoogle from 'pages/LoginPages/shared/LoginGoogle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ThirdStep = ({ form, onUpdateForm, setFormValues }) => {
  const { t } = useTranslation();
  const [showFields, setShowFields] = useState(true);

  const hideFields = () => {
    const userStorage = JSON.parse(
      localStorage.getItem(CONTENTA_REGISTER_USER)
    );
    if (userStorage?.sub) {
      setShowFields(false);
      setFormValues((prev) => ({ ...prev, email: userStorage.email }));
      localStorage.removeItem(CONTENTA_REGISTER_USER);
      return;
    }

    setShowFields(true);
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(t('Please input your password!')));
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasMinLength = value.length >= 8;

    if (!hasUpperCase) {
      return Promise.reject(
        new Error(t('Password must have at least one uppercase letter!'))
      );
    }

    if (!hasNumber) {
      return Promise.reject(
        new Error(t('Password must have at least one number!'))
      );
    }

    if (!hasSpecialChar) {
      return Promise.reject(
        new Error(t('Password must have at least one special character!'))
      );
    }

    if (!hasMinLength) {
      return Promise.reject(
        new Error(t('Password must be at least 8 characters long!'))
      );
    }

    return Promise.resolve();
  };

  return (
    <div>
      {!showFields ? (
        <Col xs={24}>
          <p>{t('register.authGoogleSuccess')}</p>
          <p>{t('register.authGoogleSuccessMessage')}</p>
        </Col>
      ) : (
        <>
          <Col xs={24}>
            <ContentaInputGroup>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('login.confirmEmail'),
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  onChange={(e) => {
                    onUpdateForm({ email: e.target.value });
                  }}
                  value={form.email}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          {/* Campo de senha com validação */}
          <Col xs={24}>
            <ContentaInputGroup>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    validator: validatePassword, // Validação personalizada
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  onChange={(e) => {
                    onUpdateForm({ password: e.target.value });
                  }}
                  value={form.password}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          {/* Campo de confirmação de senha */}
          <Col xs={24}>
            <ContentaInputGroup>
              <Form.Item
                label="Confirm password"
                name="confirmPassword"
                dependencies={['password']} // Depende do campo de senha
                rules={[
                  {
                    required: true,
                    message: t('login.confirmPassword'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('login.passwordsDoNotMatch'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm password"
                  onChange={(e) => {
                    onUpdateForm({ confirmPassword: e.target.value });
                  }}
                  value={form.confirmPassword}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          <Col xs={24} className="mb-24">
            <LoginByGoogle
              queryParam={`&origin=traveler-register-auth0`}
              onClosePopup={() => hideFields()}
            />
          </Col>
        </>
      )}
    </div>
  );
};

export default ThirdStep;
