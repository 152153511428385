import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const CardSchedule = styled.div`
  background: ${(props) => props.theme.colors.light};
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  position: relative;
  bottom: calc(24px + 40px);
  padding: 24px;

  h2 {
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 125% */
    letter-spacing: 1.25px;
    text-align: center;
    margin-bottom: 32px;
  }

  .general-info__footer {
    display: flex;
    flex-flow: column wrap;
    gap: 16px;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
