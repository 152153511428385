import { Col, Form, Input, Row, Typography } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useUser } from 'contexts/userContext';
import usePersonalAddressInputs from 'hooks/Register/usePersonalAddress';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PersonalInputs({ form }) {
  const { t } = useTranslation();

  const { registerFieldsHelper } = useUser();
  const { inputs, zipcodeValues } = usePersonalAddressInputs();

  useEffect(() => {
    for (const key in zipcodeValues) {
      form.setFieldsValue({
        [key]: zipcodeValues[key],
      });
    }
  }, [zipcodeValues]);

  return (
    <Row gutter={12}>
      {inputs.map((input) => (
        <Col
          key={input.name}
          xs={input?.col?.xs || 24}
          sm={input?.col?.sm || 4}
          lg={input?.col?.lg || 8}
        >
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={input.hasFeedback}
              label={input.label}
              name={input.name}
              placeholder={input?.placeholder}
              rules={[{ required: input.isRequired, message: input?.message }]}
            >
              {input.input || <Input className={input?.className} />}
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      ))}
      {!registerFieldsHelper.isAdult && (
        <Typography.Text type="danger">
          {t('guides.mustBeAdult')}
        </Typography.Text>
      )}
      {!registerFieldsHelper.isValidDate && (
        <Typography.Text type="danger">
          {t('guides.invalidDate')}
        </Typography.Text>
      )}
    </Row>
  );
}

export default memo(PersonalInputs);
