import 'swiper/css';

import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Radio } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { ContentaRadio } from 'components/Styled/ContentaRadio';
import { CONTENTA_GIFT_CARD } from 'constants/storages-values';
import { MEETING_TYPES } from 'constants/types';
import { useSearch } from 'contexts/searchContext';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import SpecialtiesService from 'services/SpecialtiesService';
import { Swiper, SwiperSlide } from 'swiper/react';
import SpecialtiesModalFilter from './SpecialtiesModalFilter';

import * as S from './styles';

export function SearchMentor({
  handleFilterClick,
  handleSearchClick,
  initialValues,
}) {
  const { t } = useTranslation();
  const { setCurrentPage } = useSearch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedInput, setfocusedInput] = useState('destiny');
  const [topics, setTopics] = useState([]);
  const [placeSearch, setPlaceSearch] = useState(null);
  const [initialRadioValue] = useState(
    initialValues?.scheduling_type || MEETING_TYPES.online
  );

  const formOptions = {
    form,
    layout: 'vertical',
    requiredMark: false,
  };

  const inputs = [
    {
      value: 'destiny',
      name: 'travel_destination',
      label: t('traveler.home.myTravelDestiny'),
      placeholder: t('traveler.home.preferredDestinyPlaceholder'),
      input: (
        <div className="google-input">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            selectProps={{
              placeholder: t('traveler.home.preferredDestinyPlaceholder'),
              onChange: (value) => {
                setPlaceSearch(value.value.place_id);
              },
            }}
          />
        </div>
      ),
    },
    {
      value: 'mentor',
      name: 'name',
      label: t('traveler.home.preferredMentor'),
      placeholder: t('traveler.home.preferredMentorPlaceholder'),
    },
    {
      value: 'meeting',
      name: 'scheduling_type',
      label: t('traveler.home.meetingMentor'),
      input: (
        <Radio.Group
          onFocus={() => handleFocus('meeting')}
          value={initialRadioValue}
        >
          <ContentaRadio value={MEETING_TYPES.online}>
            {t('traveler.home.virtual')}
          </ContentaRadio>
          <ContentaRadio value={MEETING_TYPES.presential}>
            {t('traveler.home.inPerson')}
          </ContentaRadio>
        </Radio.Group>
      ),
    },
  ];

  const handleSearch = async () => {
    sessionStorage.removeItem(CONTENTA_GIFT_CARD);
    setCurrentPage(0);
    const values = await form.getFieldsValue();
    const params = {
      ...values,
      travel_destination: placeSearch,
      page: 0,
    };
    handleSearchClick(params, true);
  };

  const handleFocus = (value) => {
    setfocusedInput(value);
  };

  const canShowFilters = () => {
    if (window.location.pathname.includes('search-advisor')) {
      return true;
    }

    return false;
  };

  const getTopics = async () => {
    try {
      const data = await SpecialtiesService.getTopics();
      setTopics(data.sort((a, b) => a.topicOrder - b.topicOrder));
    } catch (error) {
      setTopics([]);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      scheduling_type: initialRadioValue,
    });
    if (canShowFilters()) {
      getTopics();
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      travel_destination: initialValues?.travel_destination,
      name: initialValues?.name,
      scheduling_type: initialValues?.scheduling_type,
    });
  }, [initialValues]);

  return (
    <>
      <S.SearchMentorContainer {...formOptions}>
        {canShowFilters() && (
          <Swiper spaceBetween={4} slidesPerView={'auto'}>
            {topics.map(({ name, code }) => (
              <SwiperSlide
                onClick={() => handleFilterClick({ label: name, id: code })}
                key={code}
              >
                <span className="search-home__filters-option">
                  {t(`specialties.${name}`)}
                </span>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <div className="search-home__content">
          {inputs.map((input) => (
            <ContentaInputGroup
              className="search-home__input-container"
              key={input.name}
              isFocused={focusedInput === input.value}
            >
              <Form.Item label={input.label} name={input.name}>
                {input.input || (
                  <Input
                    placeholder={input.placeholder}
                    onFocus={() => handleFocus(input.value)}
                  />
                )}
              </Form.Item>
            </ContentaInputGroup>
          ))}
          <div
            className="search-home__find"
            role="button"
            tabIndex={0}
            onClick={handleSearch}
          >
            <SearchOutlined />
          </div>
        </div>
        <SpecialtiesModalFilter
          handleFilterClick={handleFilterClick}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </S.SearchMentorContainer>
    </>
  );
}
