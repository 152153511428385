import { Input, InputNumber } from 'antd';
import styled from 'styled-components';

const ContentaInput = styled(Input)`
  border-radius: 8px !important;
  border: 1px solid #4a4647 !important;
  color: ${({ theme }) => theme.colors.neutralBlack[10]};
  font-family: ${({ theme }) => theme.fonts.texts};
  font-feature-settings: 'lnum';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  min-height: 48px;
`;

const ContentaPasswordInput = styled(Input.Password)`
  height: 64px;
  background: #ffffff;
  border: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 8px;
`;

const ContentaInputNumber = styled(InputNumber)`
  border-radius: 8px !important;
  border: 1px solid #4a4647 !important;
  color: ${({ theme }) => theme.colors.neutralBlack[10]};
  font-family: ${({ theme }) => theme.fonts.texts};
  font-feature-settings: 'lnum';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  min-height: 48px;
`;

export { ContentaInput, ContentaPasswordInput, ContentaInputNumber };
