import { Checkbox } from 'antd';
import styled from 'styled-components';

export const ContentaCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.purple[30]};
    border: 2px solid ${(props) => props.theme.colors.purple[10]};
    border-radius: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border: 0;
  }

  .ant-checkbox-inner {
    border: 2px solid ${(props) => props.theme.colors.purple[10]};
    border-radius: 0;
  }

  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border: 2px solid ${(props) => props.theme.colors.purple[10]};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border: 2px solid ${(props) => props.theme.colors.purple[10]};
  }
`;
