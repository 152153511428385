import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';

import * as S from './styles';
import { useUser } from 'contexts/userContext';
import { isUserTraveller } from 'utils/isRole';

function FooterCard({
  className,
  onClick,
  isSaving = false,
  isDisabled = false,
}) {
  const { t } = useTranslation();
  const { userRole } = useUser();

  return (
    <S.ActionsFooter>
      <ContentaButtonPrimary
        loading={isSaving}
        onClick={onClick}
        className={className}
        isMentor={!isUserTraveller(userRole)}
        disabled={isDisabled}
      >
        {t('common.save')}
      </ContentaButtonPrimary>
    </S.ActionsFooter>
  );
}

FooterCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FooterCard.defaultProps = {
  className: '',
};

export default FooterCard;
