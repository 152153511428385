import { Col, Form, Input, Row, Select } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import useSpecialties from 'hooks/Register/useSpecialities';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function SpecialtiesInput({ handleChange, updateFourthStepForm }) {
  const { specialtiesListOrdered } = useSpecialties();
  const { t } = useTranslation();

  const [customSpecialty, setCustomSpecialty] = useState('');

  return (
    <>
      <p className="label-title">{t('guides.specialties')}</p>
      <Row gutter={16}>
        <Col span={24}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.chooseUpToFiveOptions')}
              name="specialties"
              rules={[
                {
                  required: true,
                  message: t('guides.chooseUpToFiveOptionsRequired'),
                },
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      if (value.length > 5) {
                        callback(t('guides.chooseUpToFiveOptionsLimit'));
                      } else if (value.length <= 5) {
                        callback();
                      }
                    }
                  },
                },
              ]}
            >
              <Select
                mode="multiple"
                onChange={handleChange}
                className="select-specialties"
                optionFilterProp="name"
                virtual={false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {specialtiesListOrdered?.map((specialty) => (
                  <Select.Option key={specialty.code} value={specialty.code}>
                    {specialty.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </ContentaInputGroup>
        </Col>
        <Col span={24}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.customSpecialty')}
              name="customSpecialty"
              rules={[{ required: false }]}
            >
              <Input
                className="contenta-input"
                onChange={(e) => setCustomSpecialty(e.target.value)}
                onBlur={(e) => {
                  updateFourthStepForm({ customSpecialty: e.target.value });
                }}
                value={customSpecialty}
              />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      </Row>
    </>
  );
}

export default SpecialtiesInput;
