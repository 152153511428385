import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { CarouselTextsSwipper } from '../styled';

import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

const CarouselTexts = () => {
  const { t } = useTranslation();

  const slidesTraveler = [
    {
      title: t('login.slidesTraveler.slide1.title'),
      description: t('login.slidesTraveler.slide1.description'),
    },
    {
      title: t('login.slidesTraveler.slide2.title'),
      description: t('login.slidesTraveler.slide2.description'),
    },
    {
      title: t('login.slidesTraveler.slide3.title'),
      description: t('login.slidesTraveler.slide3.description'),
    },
  ];

  const slidesAdvisor = [
    {
      title: t('login.slidesAdvisor.slide1.title'),
      description: t('login.slidesAdvisor.slide1.description'),
    },
    {
      title: t('login.slidesAdvisor.slide2.title'),
      description: t('login.slidesAdvisor.slide2.description'),
    },
    {
      title: t('login.slidesAdvisor.slide3.title'),
      description: t('login.slidesAdvisor.slide3.description'),
    },
    {
      title: t('login.slidesAdvisor.slide4.title'),
      subtitle: t('login.slidesAdvisor.slide4.description'),
      content: {
        title: t('login.slidesAdvisor.slide4.content.title'),
        bulletPoints: [
          t('login.slidesAdvisor.slide4.content.bulletPoints.1'),
          t('login.slidesAdvisor.slide4.content.bulletPoints.2'),
          t('login.slidesAdvisor.slide4.content.bulletPoints.3'),
          t('login.slidesAdvisor.slide4.content.bulletPoints.4'),
          t('login.slidesAdvisor.slide4.content.bulletPoints.5'),
        ],
      },
    },
  ];

  const slides = window.location.pathname.includes('advisor')
    ? slidesAdvisor
    : slidesTraveler;

  return (
    <CarouselTextsSwipper
      spaceBetween={50}
      slidesPerView={1}
      modules={[EffectFade, Pagination, Autoplay]}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {slides.map((slide) => (
        <SwiperSlide>
          <h1>{slide.title}</h1>
          <h2>{slide.subtitle}</h2>
          <p>{slide?.content?.title || slide.description}</p>
          {slide?.content?.bulletPoints && (
            <ul>
              {slide.content.bulletPoints.map((bulletPoint) => (
                <li>{bulletPoint}</li>
              ))}
            </ul>
          )}
        </SwiperSlide>
      ))}
    </CarouselTextsSwipper>
  );
};

export default CarouselTexts;
