import { isUserAdmin, isUserGuide } from './isRole';

function handleLastnameByRole({ role, lastname }) {
  if (isUserAdmin(role) || isUserGuide(role)) {
    return ` ${lastname}`;
  }

  const location = window.location.pathname;
  const isAdvisorDetail = location.includes('/advisor-details');
  if (
    isUserGuide(role) &&
    (location === '/search-advisor' || isAdvisorDetail)
  ) {
    return ` ${lastname}`;
  }

  const firstLetter = lastname?.[0] || '';
  return firstLetter ? ` ${firstLetter}.` : '';
}

export default handleLastnameByRole;
