import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  .finished-register__logo {
    margin-bottom: 48px;
    width: 250px;
  }

  .finished-register__title {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.5rem;
    margin: 0 0 16px;
  }

  .finished-register__subtitle {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0 auto 24px;
    max-width: 650px;
    text-align: center;
  }

  .success__footer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-bottom: 32px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      /* grid-template-columns: 1fr 1fr; */
      gap: 32px;
    }
  }

  .success__footer-advisor {
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0px 36px 45px -49px rgba(0, 0, 0, 0.29);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    padding: 24px;

    img {
      border-radius: 50%;
      height: 100px;
      width: 100px;
      margin-bottom: 16px;
    }

    h4 {
      color: #000000e6;
      font-size: 1rem;
      font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0;
    }
    p {
      color: #00000085;
      font-size: 0.75rem;
      font-family: ${({ theme }) => theme.fonts.primary}, sans-serif;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0;
    }
  }

  .success__login {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    margin-top: 24px;
    padding: 16px;
  }
`;
