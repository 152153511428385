import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';
import { Card } from 'antd';

export const MentorCardContainer = styled(Card)`
  box-shadow: 0px 6px 15px 0px #00000026;
  cursor: default;
  margin-bottom: 16px;
  width: 266px;

  .ant-card-body {
    padding: 0;
  }

  .mentor-card__cover-photo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 118px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .mentor-card__header {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    padding: 16px;
    cursor: pointer;

    img {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      margin-bottom: 2px;
      position: relative;
      bottom: 6px;
    }

    h3 {
      color: ${({ theme }) => theme.colors.pink.main};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-weight: 800;
      font-size: ${convertPxToRem(24)};
      margin: 0;
      line-height: 1;
      &:hover {
        text-decoration: underline;
      }
    }

    .mentor-card__country {
      color: #838383;
      display: block;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(14)};
      font-weight: 400;
      margin-bottom: 8px;
    }
  }

  .mentor-card__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 16px;

    .mentor-card__idioms-text {
      color: #838383;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-weight: 400;
      display: block;
      margin-bottom: 8px;
    }

    .mentor-card__idioms-options {
      display: flex;
      flex-flow: row nowrap;
      gap: 4px;
    }
  }

  .mentor-card__button {
    width: 100%;
    padding: 20px 20px 0;
    button {
      width: 100%;
    }
  }

  .mentor-card__specialties {
    margin-top: 24px;
    padding: 0px 16px 24px;

    .mentor-card__specialties-text {
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(14)};
      font-weight: 800;
      display: block;
    }
  }

  .mentor-card__specialties-link {
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(14)};
    font-weight: 800;
    margin-top: 8px;
    text-align: center;
    width: 100%;

    span {
      color: ${({ theme }) => theme.colors.pink.main};
      text-decoration: underline;
    }

    &:hover span {
      color: ${({ theme }) => theme.colors.pink.main};
      text-decoration: none;
    }
  }
`;
