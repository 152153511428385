import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const FoundMentorContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .ant-card-body {
    display: flex;
    margin-bottom: 24px;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .found-mentor__picture {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    gap: 16px;
    margin-right: 16px;
    width: 40%;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      width: auto;
    }
    img {
      border-radius: 8px;
    }
    .ant-btn {
      font-size: 10px;
      height: 36px;
      margin: 0;
      padding: 0;

      span {
        font-size: 12px;
        padding: 2px 8px;
      }
    }
  }

  .found-mentor__body {
    width: 50%;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      width: calc(100% - 160px);
    }
    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      margin: 0 auto 0 0;
      width: 70%;
    }
    h3,
    h2 {
      margin: 0;
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-weight: 700;
    }

    h3 {
      font-size: ${convertPxToRem(12)};
    }
    h2 {
      font-size: ${convertPxToRem(16)};
    }
  }

  .found-mentor__body-info {
    display: flex;
    flex-flow: row wrap;
    margin-top: 8px;
    margin-right: 16px;

    .found-mentor__licensed-badge {
      align-items: center;
      display: flex;
      gap: 8px;
    }

    .found-mentor__body-info__item {
      display: flex;
      flex-flow: column wrap;
      margin-bottom: 16px;

      &:not(:first-child) {
        margin-left: 16px;
      }

      .info__item--title {
        color: ${({ theme }) => theme.colors.neutralBlack[40]};
        font-family: ${({ theme }) => theme.fonts.texts};
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-size: ${convertPxToRem(12)};
        font-style: normal;
        font-weight: 800;
        letter-spacing: 1.25px;
        line-height: 20px;
      }

      .info__item--label {
        font-family: ${({ theme }) => theme.fonts.texts};
        font-style: normal;
        font-weight: 400;
        font-size: ${convertPxToRem(16)};
        line-height: 22px;
        letter-spacing: 1.25px;
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
        color: ${({ theme }) => theme.colors.neutralBlack[40]};
      }
    }

    .found-mentor__international {
      color: ${({ theme }) => theme.colors.pink[30]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-variant-numeric: lining-nums proportional-nums;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      text-transform: uppercase;
      width: 100%;
    }

    .found-mentor__about {
      display: block;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-style: normal;
      font-weight: 400;
      font-size: ${convertPxToRem(16)};
      line-height: 22px;
      letter-spacing: 1.25px;
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      width: 100%;
    }

    .found-mentor__see {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: ${({ theme }) => theme.colors.grayTitle};
      justify-self: flex-start;
      margin-bottom: 24px;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 100%;
        grid-column: 1 / -1;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-column: 1 / -1;
        justify-self: center;
      }

      span {
        color: ${({ theme }) => theme.colors.pink[30]};
      }
    }
  }

  .found-mentor__see.is-mobile {
    span {
      color: ${({ theme }) => theme.colors.pink[30]}!important;
    }
  }

  .ant-btn {
    align-self: center;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 100%;
    }
  }

  .found-mentor__see-all {
    color: ${({ theme }) => theme.colors.grayTitle};
    display: block;
    font-size: ${convertPxToRem(16)};
    margin-top: 24px;
    text-align: center;
  }

  .found-mentor__international-info {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(12)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
    margin: 0;
    position: relative;
    top: 16px;
    width: 100%;
  }
`;

export const MentorImg = styled.img`
  object-fit: cover;
  border-radius: 8px;
  height: 180px;
  width: 100px;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 250px;
    width: 170px;
  }
`;
