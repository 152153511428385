import { css } from 'styled-components';

const PaddingContainer = css`
  /* padding: 0 ; */
  padding-left: ${(props) => props.theme.spacing.md};
  padding-right: ${(props) => props.theme.spacing.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: ${(props) => props.theme.spacing.md};
    padding-right: ${(props) => props.theme.spacing.md};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-left: ${(props) => props.theme.spacing.xl};
    padding-right: ${(props) => props.theme.spacing.xl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-left: ${(props) => props.theme.spacing.xxl};
    padding-right: ${(props) => props.theme.spacing.xxl};
  }
`;

export default PaddingContainer;
