import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';

import './i18n';

Sentry.init({
  dsn: 'https://a532a0238fcc4bae8f5681924f1f47fa@o4505320724627456.ingest.sentry.io/4505320724627456',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // possible URL: https://localspecialist-register.contenta360.com, https://contenta-ui.herokuapp.com/
      tracePropagationTargets: [
        /^https:\/\/localspecialist-register.contenta360\.com/,
        /^https:\/\/contenta-ui\.herokuapp\.com/,
        /^https:\/\/www.contenta360\.com/,
      ],
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV !== 'production' ? 0.1 : 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: process.env.NODE_ENV !== 'production' ? 0.1 : 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
