import { Col, Form, Input } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { CONTENTA_REGISTER_USER } from 'constants/storages-values';
import LoginByGoogle from 'pages/LoginPages/shared/LoginGoogle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function FifthStep({ updateFifthStepForm, fifthStepForm }) {
  const { t } = useTranslation();
  const [showFields, setShowFields] = useState(true);

  const hideFields = () => {
    const userStorage = JSON.parse(
      localStorage.getItem(CONTENTA_REGISTER_USER) || '{}'
    );
    if (userStorage?.sub) {
      setShowFields(false);
      return;
    }
    setShowFields(true);
  };

  // Função de validação de senha
  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(t('login.passwordRequired')));
    }

    // Verificação das regras da senha
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasMinLength = value.length >= 8;

    if (!hasUpperCase) {
      return Promise.reject(new Error(t('login.passwordUppercase')));
    }

    if (!hasNumber) {
      return Promise.reject(new Error(t('login.passwordNumber')));
    }

    if (!hasSpecialChar) {
      return Promise.reject(new Error(t('login.passwordSpecialChar')));
    }

    if (!hasMinLength) {
      return Promise.reject(new Error(t('login.passwordMinLength')));
    }

    return Promise.resolve();
  };

  return (
    <div>
      {!showFields ? (
        <Col xs={24} sm={{ span: 12, offset: 6 }}>
          <p>{t('register.authGoogleSuccess')}</p>
          <p>{t('register.authGoogleSuccessMessage')}</p>
        </Col>
      ) : (
        <>
          {/* Campo de Email */}
          <Col xs={24} sm={{ span: 12, offset: 6 }}>
            <ContentaInputGroup>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('login.confirmEmail'),
                  },
                  {
                    type: 'email',
                    message: t('login.invalidEmail'),
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  onChange={(e) => {
                    updateFifthStepForm({ email: e.target.value });
                  }}
                  value={fifthStepForm.email}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          {/* Campo de Senha com validação */}
          <Col xs={24} sm={{ span: 12, offset: 6 }}>
            <ContentaInputGroup>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    validator: validatePassword, // Validação personalizada
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  onChange={(e) => {
                    updateFifthStepForm({ password: e.target.value });
                  }}
                  value={fifthStepForm.password}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          {/* Campo de Confirmação de Senha */}
          <Col xs={24} sm={{ span: 12, offset: 6 }}>
            <ContentaInputGroup>
              <Form.Item
                label="Confirm password"
                name="confirmPassword"
                dependencies={['password']} // Depende do campo de senha
                rules={[
                  {
                    required: true,
                    message: t('login.confirmPassword'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('login.passwordsDoNotMatch'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm password"
                  onChange={(e) => {
                    updateFifthStepForm({ confirmPassword: e.target.value });
                  }}
                  value={fifthStepForm.confirmPassword}
                />
              </Form.Item>
            </ContentaInputGroup>
          </Col>

          <Col xs={24} sm={{ span: 12, offset: 6 }}>
            <LoginByGoogle
              queryParam={`&origin=advisor-register-auth0`}
              onClosePopup={() => hideFields()}
            />
          </Col>
        </>
      )}
    </div>
  );
}
