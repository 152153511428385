function MenuIcon({ type = 'DEFAULT' }) {
  return (
    <svg
      width="31"
      height="24"
      viewBox="0 0 31 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30.256"
        height="2"
        fill={type === 'DEFAULT' ? '#fff' : '#FC36C4'}
      />
      <rect
        y="10"
        width="30.256"
        height="2"
        fill={type === 'DEFAULT' ? '#fff' : '#FC36C4'}
      />
      <rect y="20" width="30.256" height="2" fill="#FF9E1B" />
    </svg>
  );
}

export default MenuIcon;
