import { Badge, Skeleton } from 'antd';
import useSpecialties from 'hooks/Register/useSpecialities';
import { t } from 'i18next';

import { useMentor } from '../mentorContext';
import { MentorTabContainer } from './styles';

function Experiences() {
  const { mentor } = useMentor();
  const { specialties } = useSpecialties();
  const customSpecialties =
    mentor?.customSpecialty?.specialty?.split(',') || [];

  function renderCustomSpecialty(specialty) {
    if (specialty) {
      return specialty;
    }

    return null;
  }

  if (!mentor?.specialties) {
    return null;
  }

  if (specialties.length === 0) {
    return (
      <MentorTabContainer>
        <div className="mentor-experiences">
          <Skeleton active />
        </div>
      </MentorTabContainer>
    );
  }

  const renderSpecialty = (specialty) => {
    const specialtyInfo = specialties?.find(
      ({ code }) => code === specialty.code
    );

    if (specialtyInfo) {
      return t(`specialties.${specialtyInfo.name}`);
    }

    return t(`specialties.notFound`);
  };

  return (
    <MentorTabContainer>
      <div className="mentor-experiences">
        {mentor?.specialties.map((specialty) => (
          <Badge
            count={renderSpecialty(specialty)}
            className="mentor-tab__badge"
          />
        ))}
        {customSpecialties.map((specialty) => (
          <Badge
            count={renderCustomSpecialty(specialty)}
            className="mentor-tab__badge"
          />
        ))}
      </div>
    </MentorTabContainer>
  );
}

export default Experiences;
