import * as React from 'react';

function MailIcon(props) {
  return (
    <svg
      width={20}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.333.417A.839.839 0 0 1 19 .75c.053.079.04.055 0 0 .108.144.167.32.167.5 0-.044-.003-.063 0 0v12.5c0 .46-.373.834-.834.834H1.667a.833.833 0 0 1-.834-.834V1.25a.835.835 0 0 1 .834-.833h16.666Zm-.833 2.5-7 5.25a.833.833 0 0 1-.908.06l-.092-.06-7-5.25v10h15v-10ZM4.167 2.084 10 6.459l5.833-4.375H4.167Z"
        fill="#231F20"
      />
    </svg>
  );
}

export default MailIcon;
