import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const CardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #efefef;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-bottom: 16px;
  }

  .advisor-card__avatar {
    border-radius: 8px 0 0 8px;
    height: 250px;
    object-fit: cover;
    max-width: 132px;
  }

  .advisor-card__content {
    padding: 16px 16px 16px 0;
  }

  .advisor-card__name {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(18)};
    font-weight: 800;
    letter-spacing: 1px;
    margin: 0 0 8px;
  }

  .advisor-card__country {
    color: #838383;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(14)};
    font-weight: 700;
    margin: 0;

    @media (min-width: 768px) {
      font-weight: 400;
    }
  }

  .advisor-card__info {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      gap: 12px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .advisor-card__info {
    margin-top: 16px;
  }

  .advisor-card__idioms:not(:last-child) {
    margin-bottom: 12px;
  }

  .advisor-card__idioms-text {
    color: #838383;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(12)};
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
  }

  .advisor-card__idioms-options {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;

    img {
      width: 20px;
      height: 16px;
    }
  }

  .advisor-card__price {
    margin-top: 16px;
    display: flex;
    flex-flow: column wrap;

    .advisor-card__price-value {
      color: ${({ theme }) => theme.colors.pink[30]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(22)};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;
      display: block;
    }

    .advisor-card__price-text {
      color: #838383;
      display: block;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 400;
      margin-bottom: 8px;
      width: 100%;
    }
  }
`;
