import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

export const HeaderBriefingContainer = styled.header`
  ${PaddingContainer};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralWhite[30]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralWhite[40]};
  display: flex;
  flex-flow: row wrap;
  gap: 20%;
  justify-content: space-between;
  margin-bottom: 36px;
  padding-bottom: 24px;
  padding-top: 24px;

  .header-briefing__leftside {
    align-items: flex-start;
    display: flex;
    flex-flow: column-reverse;
    gap: 16px;
    justify-content: flex-start;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: center;
      flex-flow: row nowrap;
      justify-content: center;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      gap: 52px;
    }
  }

  .header-briefing__destiny {
    display: flex;
    flex-flow: column wrap;
    span {
      color: ${({ theme }) => theme.colors.neutralBlack[10]};
      font-family: ${({ theme }) => theme.fonts.primary};

      &:first-child {
        font-weight: 700;
        font-size: ${convertPxToRem(32)};
      }
      &:last-child {
        font-size: ${convertPxToRem(22)};
        font-weight: 400;
      }
    }
  }

  .header-briefing__mentor {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;

    .header-briefing__mentor-image {
      border-radius: 8px;
      height: 80px;
      width: 80px;
    }
    .header-briefing__mentor-info {
      display: flex;
      flex-flow: column wrap;
      color: ${({ theme }) => theme.colors.neutralBlack[10]};
      font-family: ${({ theme }) => theme.fonts.primary};

      span:first-child {
        font-size: ${convertPxToRem(12)};
        font-weight: 800;
      }

      span:last-child {
        font-size: ${convertPxToRem(12)};
        font-weight: 400;
      }
    }
  }

  .header-briefing__schedule {
    color: ${({ theme }) => theme.colors.neutralBlack[10]};
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
      padding: 16px 0;
      width: auto;
    }

    .header-briefing__scheduled-at {
      &:first-child {
        color: ${({ theme }) => theme.colors.neutralBlack[10]};
        font-family: ${({ theme }) => theme.fonts.texts};
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-size: ${convertPxToRem(12)};
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.02em;
        line-height: 14px;
        margin-bottom: 8px;
      }
    }
    .header-briefing__schedule-date {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: ${convertPxToRem(16)};
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .header-briefing__no-briefing {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
`;

export const ConfirmedPaymentContainer = styled.div`
  align-items: center;
  background-image: ${({ theme }) => theme.colors.linearGradient.pinkPurple};
  border-radius: 8px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-inline: auto;
  padding: 32px;
  width: min(100% - 24px, 620px);

  img {
    margin-bottom: 16px;
    image-rendering: optimizeQuality;
    width: 56px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-style: normal;
    font-weight: 800;
    font-size: ${convertPxToRem(28)};
    line-height: 32px;
    margin-bottom: 16px;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.texts};
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
    letter-spacing: 1.25px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    margin-bottom: 16px;
  }
`;

export const RemainingTimeContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite[10]};
  border-radius: 16px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -6px rgba(16, 24, 40, 0.08);
  color: ${({ theme }) => theme.colors.light};
  padding: 16px;
  margin: 0 16px 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 24px auto;
    position: absolute;
    right: 24px;
    width: 400px;
  }

  .remaining-time__title {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;
  }

  .remaining-time__time {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(40)};
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 52px;
    margin-bottom: 0;
  }
`;
