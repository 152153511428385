import { Rating } from '@smastrom/react-rating';
import { customStarsStyles } from 'constants/stars-colors';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

function RatingSingleStar({ rating, noMargin }) {
  const { t } = useTranslation();

  const roundRating = Math.round(rating ?? 0);
  const commonProps = {
    spaceBetween: 16,
    style: { maxWidth: 140 },
    value: roundRating || 0,
    isDisabled: true,
    items: 1,
    itemStyles: customStarsStyles,
  };

  return (
    <S.SingleRatingStarContainer noMargin={noMargin}>
      <div className={`rating__container`}>
        <Rating
          className={`rating__stars ${!rating ? '--no-rate' : ''}`}
          {...commonProps}
        />
      </div>
      <span className="rating__value">
        {rating ? rating.toFixed(1) : t('common.noRate')}
      </span>
    </S.SingleRatingStarContainer>
  );
}

export default RatingSingleStar;
