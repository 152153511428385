import * as React from 'react';

const PortugalFlagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={16}
    viewBox="0 0 28 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    style={{ borderRadius: 2 }}
    {...props}
  >
    <rect width={22} height={16} fill="#fff" rx={2} />

    <g>
      <path fill="#FF2936" d="M0 0h28v20H0z" />
      <path
        fill="#128415"
        fillRule="evenodd"
        d="M0 20h10.667V0H0v20Z"
        clipRule="evenodd"
      />
      <path
        stroke="#FAF94F"
        strokeWidth={1.333}
        d="M10.667 13.333a3.333 3.333 0 1 0 0-6.667 3.333 3.333 0 0 0 0 6.667Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.332 8.333c0-.184.15-.333.333-.333h2c.185 0 .334.15.334.333v2.334a1.333 1.333 0 0 1-2.667 0V8.333Z"
        clipRule="evenodd"
      />
      <path
        fill="#1D50B5"
        fillRule="evenodd"
        d="M10.667 10.667c.368 0 .666-.965.666-1.333a.667.667 0 1 0-1.333 0c0 .368.299 1.333.667 1.333Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

export default PortugalFlagIcon;
