import styled from 'styled-components';

export const RightContent = styled.div`
  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.neutralBlack[30]};
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralWhite[40]};
    gap: 24px;
    justify-content: flex-start;
    width: 100%;
  }

  .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
  }

  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav {
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin: 0;
    }
  }

  .tab-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
  }

  video {
    height: 300px;
    object-fit: cover;
    width: 100%;
  }

  .mentor-about__banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 300px;
    width: 100%;
  }
`;
