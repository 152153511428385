import moment from 'moment';

class IMentor {
  constructor(mentor) {
    this.id = mentor.id;
    this.addressId = mentor?.address?.id;
    this.associatedSince = mentor?.classAssociation?.associatedSince;
    this.associatedDay =
      mentor?.classAssociation?.associatedSince?.split('-')[2] || '';
    this.associatedMonth =
      mentor?.classAssociation?.associatedSince?.split('-')[1] || '';
    this.associatedYear =
      mentor?.classAssociation?.associatedSince?.split('-')[0] || '';
    this.birthDate = moment(mentor?.birthDate);
    this.day = mentor?.birthDate?.split('-')[2] || '';
    this.month = mentor?.birthDate?.split('-')[1] || '';
    this.year = mentor?.birthDate?.split('-')[0] || '';
    this.consultancyCity = mentor?.consultingRegion?.city;
    this.consultancyCountry = mentor?.consultingRegion?.country;
    this.consultancyDetails = mentor?.consultingRegion?.details;
    this.consultancyState = mentor?.consultingRegion?.state;
    this.currency = mentor?.currency;
    this.customLanguage = mentor?.customLanguage?.language || '';
    this.customSpecialty = mentor?.customSpecialty?.specialty || '';
    this.documentCompany = mentor?.company?.document || '';
    this.email = mentor?.email;
    this.isTurismCompany = mentor?.isTurismCompany;
    this.languages = mentor?.languages;
    this.ddi = mentor?.mobilePhone?.split(' ')[0] || '';
    this.mobilePhone = mentor?.mobilePhone?.split(' ')[1] || '';
    this.name = mentor?.name;
    this.association = mentor?.classAssociation?.name;
    this.nameCompany = mentor?.company?.name || '';
    this.nationality = mentor?.nationality || mentor?.address?.country?.id;
    this.otherLanguages = mentor?.customLanguage?.otherLanguages || '';
    this.registerNumber = mentor?.classAssociation?.registerNumber || '';
    this.role = mentor?.company?.position || mentor.role || '';
    this.specialties = mentor?.specialties;
    this.tellUsAboutYou = mentor?.summary;
    this.surname = mentor?.surname || '';
    this.valueHourVirtual = mentor?.valueHourVirtual || '';
    this.valueFourHours = mentor?.valueFourHours || 0;
    this.valueSixHours = mentor?.valueSixHours || 0;
    this.valueEightHours = mentor?.valueEightHours || 0;
    this.valueTenHours = mentor?.valueTenHours || 0;
    this.accountNumber = mentor?.financial?.accountNumber;
    this.agencyStreet = mentor?.financial?.address;
    this.agencyNumber = mentor?.financial?.agencyNumber;
    this.bankName = mentor?.financial?.bankName;
    this.bankNumber = mentor?.financial?.bankNumber;
    this.brazilianAccount = mentor?.financial?.brazilianAccount || '';
    this.brazilianAgency = mentor?.financial?.brazilianAgency || '';
    this.brazilianBank = mentor?.financial?.brazilianBank || '';
    this.brazilianDigit = mentor?.financial?.brazilianDigit || '';
    this.agencyCity = mentor?.financial?.city;
    this.accountCountry =
      mentor?.financial?.country?.id || mentor?.financial?.country || '';
    this.ibanCode = mentor?.financial?.ibanCode;
    this.swiftCode = mentor?.financial?.swiftCode;
    this.agencyZipcode = mentor?.financial?.zipCode;
    this.city = mentor?.address?.city;
    this.complement = mentor?.address?.complement || '';
    this.country =
      mentor?.address?.country?.id || mentor?.address?.country || '';
    this.state = mentor?.address?.state;
    this.street = mentor.address?.street;
    this.zipcode = mentor.address?.zipcode || '';
    this.facebook = mentor?.contact?.facebook || '';
    this.instagram = mentor?.contact?.instagram || '';
    this.blueSky = mentor?.contact?.blueSky || '';
    this.threads = mentor?.contact?.threads || '';
    this.twitter = mentor?.contact?.twitter || '';
    this.tikTok = mentor?.contact?.tikTok || '';
    this.site = mentor?.contact?.site || '';
    this.profilePhrase = mentor?.profilePhrase || '';
    this.imageURL = mentor?.imageURL || '';
    this.videoURL = mentor?.videoURL || '';
    this.kanaSurname = mentor?.kana?.surname || '';
    this.kanaName = mentor?.kana?.name || '';
    this.kanjiSurname = mentor?.kanji?.surname || '';
    this.kanjiName = mentor?.kanji?.name || '';
    this.isTurismCompany =
      mentor?.classAssociation?.guideDependsDescription?.length > 0
        ? 'depends'
        : mentor?.classAssociation?.registerNumber;
    this.requiredLicenseDetails =
      mentor?.classAssociation?.guideDependsDescription;
  }
}

export default IMentor;
