import { useTranslation } from 'react-i18next';
import * as S from './styles';
import ScrollToTop from 'components/ScrollToTop';

function TermsConditions() {
  const { t } = useTranslation();
  return (
    <S.Container>
      <ScrollToTop />
      <h1>{t('termsAndConditions.title')}</h1>

      <div
        dangerouslySetInnerHTML={{ __html: t('termsAndConditions.content') }}
      />
    </S.Container>
  );
}

export default TermsConditions;
