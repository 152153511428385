/* eslint-disable no-param-reassign */
import { Col, Form, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function TellYourStory({ updateFourthStepForm }) {
  const { t } = useTranslation();
  const [aboutYourHistory, setAboutYourHistory] = useState('');

  return (
    <>
      <p className="label-title">{t('guides.tellAboutYourHistory')}</p>
      <Row gutter={24}>
        <Col xs={24}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.tellAboutYourHistoryDesc')}
              name="tellUsAboutYou"
              className="initial-font-transform"
              rules={[
                {
                  required: true,
                  message: t('guides.tellAboutYourHistoryRequired'),
                },
                {
                  max: 2500,
                  message: t('guides.tellAboutYourHistoryCharLimit'),
                },
              ]}
            >
              <TextArea
                maxLength={2500}
                showCount
                onChange={(e) => setAboutYourHistory(e.target.value)}
                onBlur={(e) =>
                  updateFourthStepForm({ tellUsAboutYou: e.target.value })
                }
                value={aboutYourHistory}
                rows={8}
                className="h-auto"
              />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      </Row>
    </>
  );
}

export default memo(TellYourStory);
