import moment from 'moment';
import 'moment-timezone';

function addTimezoneDate(date) {
  const startAtUTC = date;

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startDate = moment.utc(startAtUTC);

  const convertedDate = startDate.tz(userTimezone).format();

  return convertedDate;
}

export default addTimezoneDate;
