import { CURRENCIES } from 'constants/currencies';

const getCurrencyAvailableInCountry = (formValues) => {
  const country = formValues?.country || formValues?.address?.country;
  const isBrazil = country === 1;

  if (isBrazil) {
    return CURRENCIES.filter((currency) => currency.code === 'BRL');
  }

  return CURRENCIES.filter((currency) => currency.code !== 'BRL');
};

export { getCurrencyAvailableInCountry };
