import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';

export const Container = styled.div`
  ${PaddingContainer};
  background-color: rgb(255 242 250);
  padding-bottom: 16px;
  padding-top: 16px;
  margin: 24px 0 0;

  h1,
  h3,
  h5 {
    font-family: ${({ theme }) => theme.fonts.texts};
  }

  .about-us__image {
    display: block;
    margin: 0 auto;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 500px;
    }

    &.bigger {
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 650px;
      }
    }
  }
`;
