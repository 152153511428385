import { Divider, Form } from 'antd';
import { KanaKanjiInputs } from 'pages/MentorPages/Profile/components/Personal';

import { useRegister } from '../context/useRegister';
import AddressInputs from './AddressInputs';
import PersonalInputs from './PersonalInputs';
import PhotoPicker from './PhotoPicker';

export function FirstStep({ setSelectedFile }) {
  const { form } = useRegister();

  const selectedNationality = Form.useWatch('nationality', form);
  const isJapan =
    selectedNationality === 'Japan' || selectedNationality === 109;

  return (
    <>
      <PhotoPicker setSelectedFile={setSelectedFile} />

      <PersonalInputs form={form} />

      {isJapan && <KanaKanjiInputs />}

      <Divider />

      <AddressInputs form={form} />
    </>
  );
}
