import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const ScheduleModalContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  .schedule-modal__title {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  .search-header__schedule {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 24px;

    .schedule-modal__tab {
      align-items: center;
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      display: flex;
      flex-flow: row nowrap;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 22px;
      text-align: center;
    }

    .schedule__btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.grayTitle};
      position: relative;
      top: 5px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .schedule__times {
      gap: 48px;
      grid-template-columns: minmax(90px, 100%) minmax(90px, 100%) minmax(
          90px,
          100%
        );
      position: relative;
      display: grid;
      width: 100%;
    }

    .schedule__date-container {
      display: flex;
      flex-flow: column wrap;
      text-align: center;
      font-family: ${({ theme }) => theme.fonts.texts};

      span {
        font-weight: 700;
        font-size: ${convertPxToRem(12)};
      }

      span:last-child {
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-size: ${convertPxToRem(16)};
      }
    }
  }

  .schedule__amount {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .ant-btn {
    margin-bottom: 16px;
    width: 100%;
  }
`;

export const RescheduleModalContainer = styled.div`
  p {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    line-height: 22px;
    letter-spacing: 1.25px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
  }
`;
