import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import { canViewGuide, isUserGuide } from 'utils/isRole';

export function ProtectedAdvisorRoute({ children, userRole }) {
  const { whoAmI, whoAmILoading, isAuthenticated, isLoading } = useUser();
  const [loading, setLoading] = useState(true);
  const [isRegistered, setIsRegistered] = useState(null);

  useEffect(() => {
    if (!isLoading && !whoAmILoading) {
      if (Object.keys(whoAmI).length > 0) {
        setIsRegistered(checkIsRegistered({ whoAmI }));
      }
      setLoading(false);
    }
  }, [isLoading, whoAmILoading, whoAmI, isAuthenticated]);

  if (loading || isRegistered === null) {
    return <RedirectLoader />;
  }

  const isRegisterRoute = window.location.pathname.includes('guide/register');

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (isUserGuide(userRole) && !isRegisterRoute) {
    return <Navigate to="/" replace />;
  }

  if (!isRegistered && !isRegisterRoute) {
    return <Navigate to="guide/register" replace />;
  }

  if (!canViewGuide(whoAmI)) {
    return <Navigate to="/not-allowed" replace />;
  }

  return <ContainerApp isMentor>{children || <Outlet />}</ContainerApp>;
}
