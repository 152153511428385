import { lazy } from 'react';
import { Route } from 'react-router-dom';
const Initialize = lazy(() =>
  import(/* webpackChunkName: "zoomChunk" */ '../modules/zoom/pages')
);

const Lobby = lazy(() =>
  import(/* webpackChunkName: "zoomChunk" */ '../modules/zoom/pages/Lobby')
);

export default [
  <Route
    path="/meet/*"
    exact
    element={<Initialize />}
    key={'unlogged-initialize'}
  />,
  <Route path="/lobby/:id" exact element={<Lobby />} key={'unlogged-lobby'} />,
];
