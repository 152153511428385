import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

function AddressFilled() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const addressValues = form.getFieldsValue(true);

  return (
    <address>
      <span className="card__address-title">
        {t('traveler.scheduleBriefing.yourAddress')}
      </span>
      <span>
        {addressValues?.street}, {addressValues?.number}
      </span>
      {addressValues?.complement && <span>{addressValues?.complement}</span>}
      <span>
        {addressValues?.city} - {addressValues?.state}
      </span>
      <span>
        {t('mentorDetails.contactFields.zipCode')}: {addressValues?.zipcode}
      </span>
    </address>
  );
}

export default AddressFilled;
