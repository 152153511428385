import { t } from 'i18next';
import { MentorTabContainer } from './styles';

function WhyAmI({ mentor }) {
  return (
    <MentorTabContainer>
      <p className="mentor-tab__description">
        {mentor?.summary || t('mentorProfile.noPhrase')}
      </p>
    </MentorTabContainer>
  );
}

export default WhyAmI;
