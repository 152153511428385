function LGBTIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={60}
      height={50}
      style={{
        position: 'relative',
        top: '-5px',
      }}
      viewBox="843 1368 793 771"
    >
      <path
        d="M1078.67 1431.97c36.34 0 70.53 9.61 100.13 26.42a203.97 203.97 0 0 1 60.36 52.29 203.97 203.97 0 0 1 60.36-52.29c29.6-16.81 63.78-26.42 100.13-26.42 56.08 0 106.86 22.74 143.62 59.49 36.75 36.76 59.49 87.54 59.49 143.62 0 19.31-2.71 38.03-7.78 55.79a201.965 201.965 0 0 1-22.83 51.43c-42 67.43-146.07 164.04-232.81 244.57-33.51 31.1-64.38 59.76-88.49 83.88-6.46 6.45-16.93 6.45-23.38 0-24.11-24.12-54.99-52.78-88.49-83.88-86.75-80.53-190.81-177.14-232.81-244.57a201.965 201.965 0 0 1-22.83-51.43c-5.07-17.76-7.78-36.48-7.78-55.79 0-56.08 22.73-106.86 59.49-143.62 36.76-36.75 87.54-59.49 143.62-59.49zm348.15 229.37c-9.16 0-16.59-7.43-16.59-16.59 0-9.15 7.43-16.58 16.59-16.58h61.54c9.15 0 16.58 7.43 16.58 16.58 0 9.16-7.43 16.59-16.58 16.59h-61.54zm-270.77 296.36h59.34c9.16 0 16.58 7.42 16.58 16.58 0 9.15-7.42 16.58-16.58 16.58h-23.66c17.39 16.23 33.5 31.41 47.43 44.98 21.73-21.17 48.76-46.26 77.7-73.13 47.04-43.66 99.26-92.14 143.29-137.53h-360.81c-9.17 0-16.61-7.44-16.61-16.62 0-9.17 7.44-16.61 16.61-16.61h391.99c22.05-24.44 40.45-47.28 52.79-67.09a169.191 169.191 0 0 0 19.09-43.03c4.22-14.81 6.48-30.49 6.48-46.75 0-46.96-19.03-89.47-49.8-120.24-30.77-30.77-73.28-49.8-120.24-49.8-30.56 0-59.18 8.01-83.86 22.03-25.48 14.46-46.85 35.33-61.95 60.39-1.34 2.46-3.32 4.6-5.88 6.16-2.9 1.77-6.13 2.54-9.28 2.4-5.51-.1-10.84-2.95-13.87-8.02-15.12-25.3-36.62-46.36-62.28-60.93-24.68-14.02-53.3-22.03-83.86-22.03-46.96 0-89.47 19.03-120.24 49.8-29.25 29.25-47.89 69.1-49.66 113.31h424.24c9.17 0 16.61 7.43 16.61 16.6 0 9.18-7.44 16.61-16.61 16.61H910.63c1.08 6.98 2.58 13.82 4.48 20.47a169.191 169.191 0 0 0 19.09 43.03c38.86 62.39 137.71 154.71 221.85 232.84z"
        style={{
          fill: '#8d8b8d',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}

export default LGBTIcon;
