import Avatar from 'components/Avatar';
import RatingSingleStar from 'components/RatingSingleStar';
import { ContentaButtonBlackGhost } from 'components/Styled/ContentaButton';
import {
  CONTENTA_TIMER_CHECKOUT,
  STATE_SCHEDULE,
} from 'constants/storages-values';
import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import addTimezoneDate from 'utils/addTimezoneDate';
import findCountryNameById from 'utils/findCountryNameById';
import handleLastnameByRole from 'utils/handleLastnameByRole';

import { HeaderBriefingContainer } from '../styles';
import RemainingTime from './RemainingTime';

function HeaderBriefing({
  advisor,
  selectedSchedule,
  showTimer = true,
  isAdvisor = false,
  fetchedBriefing,
  currentStep = '',
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userRole } = useUser();
  const { currentLanguage } = useLanguage();

  const isBriefingPage = window.location.href.includes('/briefing');
  const country = findCountryNameById({
    id: advisor?.nationality?.id || advisor?.country?.acronym,
  });

  const handleCancel = () => {
    sessionStorage.removeItem(STATE_SCHEDULE);
    const isRedirectedAfterLogin = state?.isRedirect;
    const previousPage = document.referrer;
    if (previousPage.includes('checkout')) {
      navigate('/home');
      return;
    }

    if (isRedirectedAfterLogin) {
      navigate('/search-advisor');
    } else if (isBriefingPage) {
      navigate('/home');
    } else {
      navigate(-1);
    }
  };

  const onEndTime = () => {
    sessionStorage.removeItem(CONTENTA_TIMER_CHECKOUT);
  };

  const showCorrectDate = ({ date }) => {
    let newDate;
    if (date?.includes('T')) {
      newDate = new Date(date);
    } else {
      newDate = new Date(`${date}T00:00:00`);
    }
    const day = newDate.toLocaleDateString(currentLanguage, {
      day: 'numeric',
      month: 'long',
    });

    return `${day}`;
  };

  const showCorrectHour = () => {
    let hour;
    if (selectedSchedule?.startAtUTC) {
      hour = moment(addTimezoneDate(selectedSchedule?.startAtUTC));
    } else {
      hour = moment(`${selectedSchedule?.date}T${selectedSchedule?.startAt}`);
    }

    return `${hour?.format('HH:mm')}` || '';
  };

  return (
    <>
      <HeaderBriefingContainer>
        {Object.keys(advisor || {}).length > 0 && (
          <div className="header-briefing__leftside">
            <div className="header-briefing__destiny">
              <span>{advisor?.city}</span>
              <span>{country}</span>
            </div>

            <div className="header-briefing__mentor">
              <Avatar
                src={advisor?.imageUrl || advisor?.imageURL}
                alt={`Photo of ${advisor?.name}`}
                className="header-briefing__mentor-image"
              />

              <div className="header-briefing__mentor-info">
                <span>
                  {advisor?.name}
                  {!isAdvisor
                    ? handleLastnameByRole({
                        role: userRole,
                        lastname: advisor?.surname || '',
                      })
                    : ` ${advisor?.surname || ''}`}
                </span>
                {!isAdvisor && (
                  <span>
                    {advisor?.profilePhrase || t('mentorProfile.noPhrase')}
                  </span>
                )}
                {!isAdvisor && (
                  <RatingSingleStar rating={advisor?.rating} noMargin />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="header-briefing__schedule">
          {selectedSchedule?.date && (
            <>
              <span className="header-briefing__scheduled-at">
                {t('briefing.scheduledFor')}
              </span>
              <span className="header-briefing__schedule-date">
                {showCorrectDate({
                  date: selectedSchedule?.date || selectedSchedule?.startAtUTC,
                })}

                {' | '}
                {showCorrectHour()}
              </span>
            </>
          )}
          {currentStep !== 'PAYMENT_SUCCESS' && (
            <ContentaButtonBlackGhost
              onClick={handleCancel}
              className="header-briefing__cancel-button"
              size="small"
            >
              {isBriefingPage ? t('common.back') : t('common.cancel')}
            </ContentaButtonBlackGhost>
          )}
        </div>
        {isAdvisor && !fetchedBriefing && (
          <div className="header-briefing__no-briefing">
            <span>{t('traveler.resume.noBriefing')}</span>
          </div>
        )}
      </HeaderBriefingContainer>
      {showTimer && <RemainingTime onEndTime={onEndTime} />}
    </>
  );
}

export default HeaderBriefing;
