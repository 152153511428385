export const CURRENCIES = [
  {
    name: 'US Dollar',
    symbol: '$',
    code: 'USD',
    countryCode: 'en-US',
  },
  // {
  //   name: 'Canadian Dollar',
  //   symbol: 'CA$',
  //   code: 'CAD',
  //   countryCode: 'en-CA',
  // },
  // {
  //   name: 'Euro',
  //   symbol: '€',
  //   code: 'EUR',
  //   countryCode: 'br-FR',
  // },
  {
    name: 'Brazilian Real',
    symbol: 'R$',
    code: 'BRL',
    countryCode: 'pt-BR',
  },
  // {
  //   name: 'British Pound Sterling',
  //   symbol: '£',
  //   code: 'GBP',
  //   countryCode: 'en-GB',
  // },
  // {
  //   name: 'Hong Kong Dollar',
  //   symbol: 'HK$',
  //   code: 'HKD',
  //   countryCode: 'HK',
  // },
];
