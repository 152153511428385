import { notification } from 'antd';
import i18n from '../i18n';

export function successHandler({ message, t = i18n.t }) {
  notification.success({
    message: t('common.success'),
    description: t(message),
    duration: 4.5,
  });
}
