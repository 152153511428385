import { SCHEDULE_STATUS } from 'constants/types';
import { add, format } from 'date-fns';

function filterAvailableDates(dates) {
  if (!dates) {
    return [];
  }
  // const now = new Date();
  const now = add(new Date(), { days: 2 });
  const currentDay = format(now, 'yyyy-MM-dd');
  const currentTime = now.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return dates.map((date) => {
    if (date.date === currentDay) {
      date.times = date.times.filter(
        (time) =>
          time.status === SCHEDULE_STATUS.AVAILABLE &&
          time.startTime > currentTime
      );
    } else {
      date.times = date.times.filter(
        (time) => time.status === SCHEDULE_STATUS.AVAILABLE
      );
    }
    return date;
  });
}

export default filterAvailableDates;
