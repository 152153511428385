import { useEffect } from 'react';
import { Divider } from 'antd';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';
import {
  CONTENTA_GIFT_CARD,
  CONTENTA_REDIRECT,
  CONTENTA_SELECTED_SCHEDULE,
  CONTENTA_TIMER_CHECKOUT,
} from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import * as S from './styles';
import PaymentService from 'services/PaymentService';
import InvestmentValue from 'pages/TravellerPages/MentorDetails/MentorDetailsTabs/InvestmentValue';
import errorLogger from 'utils/errorLogger';

function MentorDetailsSchedule({ advisor }) {
  const { t } = useTranslation();
  const { isAuthenticated, userCurrency, whoAmI } = useUser();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const redirectToNextPage = ({ state }) => {
    const isRegistered = checkIsRegistered({ whoAmI });
    sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, new Date().getTime());
    if (isAuthenticated && isRegistered) {
      navigate('/checkout-gift', { state });
      return;
    } else {
      sessionStorage.setItem(CONTENTA_REDIRECT, '/checkout-gift');
      navigate('/login');
    }
  };

  const redirectUnloggedUser = ({ selectedSchedule }) => {
    sessionStorage.setItem(
      CONTENTA_SELECTED_SCHEDULE,
      JSON.stringify({
        ...selectedSchedule,
        advisor,
        isRedirect: true,
      })
    );

    sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, new Date().getTime());
    sessionStorage.setItem(CONTENTA_REDIRECT, window.location.pathname);
    navigate('/login');
  };

  const createClientSecretStripe = async () => {
    const formData = JSON.parse(sessionStorage.getItem(CONTENTA_GIFT_CARD));
    const data = {
      name: formData?.name,
      email: formData?.email,
      remark: formData?.message,
      amount: formData?.value,
      advisorId: advisor.id,
      currency: userCurrency?.currencyStandard,
      scheduledTo: new Date(formData?.sendDate).toISOString().split('T')[0],
    };

    if (!isAuthenticated) {
      redirectUnloggedUser({ selectedSchedule: data });
      return;
    }

    try {
      const response = await PaymentService.getClientSecretGiftCard({ data });
      redirectToNextPage({
        state: {
          data,
          clientSecret: response.clientSecret,
          protocol: response.protocol,
        },
      });
    } catch (error) {
      console.error(error);
      errorLogger({
        error,
        message: `[MentorDetailsSchedule] Error creating client secret stripe`,
      });
    }
  };

  useEffect(() => {
    const dataFromStorage = JSON.parse(
      sessionStorage.getItem(CONTENTA_SELECTED_SCHEDULE)
    );
    if (dataFromStorage?.isRedirect) {
      sessionStorage.removeItem(CONTENTA_REDIRECT);
      sessionStorage.removeItem(CONTENTA_SELECTED_SCHEDULE);
      createClientSecretStripe();
    }
  }, []);

  return (
    <S.CardSchedule>
      <h2>{t('mentorProfile.generalInfo')}</h2>
      <InvestmentValue />
      <Divider />

      <footer className="general-info__footer">
        <ContentaButtonPrimary onClick={createClientSecretStripe}>
          {t('giftCardModal.buttonChooseOption')}
        </ContentaButtonPrimary>
        <ContentaButtonPrimaryOutline onClick={handleBackClick}>
          {t('common.back')}
        </ContentaButtonPrimaryOutline>
      </footer>
    </S.CardSchedule>
  );
}

export default MentorDetailsSchedule;
