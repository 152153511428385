import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialtiesModal } from './styles';
import useSpecialties from 'hooks/Register/useSpecialities';

function SpecialtiesModalFilter({
  handleFilterClick,
  isModalOpen,
  setIsModalOpen,
}) {
  const { t } = useTranslation();
  const { specialties } = useSpecialties();
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSearch = () => {
    handleFilterClick(selectedFilters);
    setIsModalOpen(false);
  };

  const handleRemoveItem = (filter) => () => {
    setSelectedFilters(selectedFilters.filter((item) => item !== filter));
  };

  const handleClick = (id, option) => {
    if (selectedFilters.includes(option.label)) {
      return;
    }

    const filterOption = specialties.find((filter) => filter.id === id);

    setSelectedFilters([
      ...selectedFilters,
      {
        id,
        label: filterOption.name,
      },
    ]);
  };

  return (
    <SpecialtiesModal
      title={t('traveler.home.specialtiesModalTitle')}
      open={isModalOpen}
      onCancel={handleClose}
      width={800}
      wrapClassName="search-home__modal"
      footer={[
        <ContentaButtonPrimary onClick={handleSearch}>
          {t('traveler.home.specialtiesModalButton')}
        </ContentaButtonPrimary>,
      ]}
    >
      <div className="search-home__modal-search">
        <Select
          style={{
            width: '100%',
          }}
          placeholder={t('traveler.home.findSpecialty')}
          suffixIcon={<SearchOutlined />}
          options={specialties.map(({ name, id }) => ({
            label: t(`specialties.${name}`),
            value: id,
          }))}
          onChange={handleClick}
        />
      </div>

      <div className="search-home__selected-filters">
        {selectedFilters.map((filter) => (
          <span
            className="search-home__selected-filters-option"
            key={filter.label}
          >
            {t(`specialties.${filter.label}`)}
            <span onClick={handleRemoveItem(filter)} role="button">
              <CloseOutlined />
            </span>
          </span>
        ))}
      </div>
    </SpecialtiesModal>
  );
}

export default SpecialtiesModalFilter;
