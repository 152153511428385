import styled from 'styled-components';
import User from 'assets/images/user.png';

const Avatar = styled.img`
  object-fit: cover;
`;

Avatar.defaultProps = {
  onError: ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = User;
  },
  alt: 'Avatar',
};

export default Avatar;
