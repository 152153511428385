import { css } from 'styled-components';

const CommonTitle = css`
  font-family: ${({ theme }) => theme.fonts.texts};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    line-height: 36px;
  }
`;

const CommonParagraph = css`
  color: ${({ theme }) => theme.colors.grayTitle};
  font-family: ${({ theme }) => theme.fonts.texts};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    line-height: 24px;
  }
`;

export default {
  CommonTitle,
  CommonParagraph,
};
