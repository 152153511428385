import { StarOutlined } from '@ant-design/icons';
import { Rate } from 'antd';
import { ContentaText } from 'components/Styled/ContentaText';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import isMobile from 'utils/isMobile';

import { useMentor } from '../mentorContext';
import * as S from './styles';

const EvaluationCard = ({ evaluation }) => {
  const { t } = useTranslation();

  return (
    <div className="evaluation-card">
      <header className="evaluation-card__header">
        <img src="https://via.placeholder.com/150" alt="avatar" />
        <div className="evaluation-card__header-user">
          <ContentaText bold size="large">
            {evaluation?.author || 'Traveller'}
          </ContentaText>
          <ContentaText size="xsmall" bold color="secondary">
            {t('mentorProfile.mentor')}
          </ContentaText>
        </div>
      </header>

      <div className="evaluation-card__content">
        <div className="evaluation-card__rating">
          <ContentaText size="xxsmall" color="secondary">
            {t('common.avaliation')}:
          </ContentaText>
          <Rate
            allowHalf
            character={<StarOutlined />}
            className="evaluate-modal__rate-stars"
            value={evaluation?.average}
            style={{
              fontSize: 18,
              color: '#D82EA1',
              backgroundColor: 'transparent',
            }}
          />
        </div>

        <ContentaText
          size="xsmall"
          color="primary"
          className="evaluation-card__content-text"
        >
          {evaluation?.description}
        </ContentaText>
      </div>
    </div>
  );
};

const MentorEvaluations = () => {
  const { t } = useTranslation();
  const { advisor } = useMentor();

  if (!advisor?.assessments?.length) return null;

  return (
    <S.ScheduleMeetCardContainer>
      <ContentaText size="large" bold className="evaluation-card__title">
        {t('mentorProfile.rating')}
      </ContentaText>

      {isMobile() ? (
        <Swiper spaceBetween={16} slidesPerView={1.2}>
          {advisor?.assessments.map((evaluation) => (
            <SwiperSlide>
              <EvaluationCard evaluation={evaluation} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="evaluation-card__container">
          {advisor?.assessments.map((evaluation) => (
            <EvaluationCard evaluation={evaluation} />
          ))}
        </div>
      )}
    </S.ScheduleMeetCardContainer>
  );
};

export default MentorEvaluations;
