import { Col, Divider, Form, Row, Typography } from 'antd';
import { FlagMenu } from 'components/FlagMenu';
import RedirectLoader from 'components/RedirectingLoader';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';
import { COUNTRIES_LIST } from 'constants/countries';
import { STATE_SCHEDULE } from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import useTravellerInputs from 'hooks/Register/useTravellerInputs';
import { Suspense, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TravellerService from 'services/TravellerService';
import { errorHandler } from 'utils/errorHandler';
import errorLogger from 'utils/errorLogger';
import { successHandler } from 'utils/successHandler';
import AlreadyRegistered from './AlreadyRegistered';
import FirstStep from './StepsForm/FirstStep';
import SecondStep from './StepsForm/SecondStep';
import ThirdStep from './StepsForm/ThirdStep';
import TravellerRegisterHeader from './TravellerRegisterHeader';

import * as S from './styles';

export const TravellerRegister = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { whoAmI, isLoading } = useUser();
  const { inputs, formErrors, contactsAdressInput, addressValue } =
    useTravellerInputs({
      form,
    });

  const [formValues, setFormValues] = useState({});
  const [step, setStep] = useState(1);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [thirdStepForm, updateThirdStepForm] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { email: '', password: '', confirmPassword: '' }
  );

  const [acceptTermsConditions, setAcceptTermsConditions] = useState(null);

  const hasSomeErrorField = Object.keys(formErrors).length > 0;
  const isDisabled =
    hasSomeErrorField && Object.values(formErrors).some(Boolean);

  const formOptions = {
    form,
    layout: 'vertical',
    requiredMark: false,
  };

  const redirectToBriefing = () => {
    const urlBack = sessionStorage.getItem('url_back');
    const stateSchedule = JSON.parse(sessionStorage.getItem(STATE_SCHEDULE));
    if (stateSchedule) {
      window.location.href = urlBack;
    }
  };

  async function submitData(data) {
    try {
      await TravellerService.saveUser(data);
      successHandler({ message: 'register.success' });
      setSaving(false);
      setAlreadyRegistered(true);
      redirectToBriefing();
    } catch (error) {
      errorHandler({ error });
      errorLogger({
        error,
        message: `[TravellerRegister][submitData][error] - ${JSON.stringify(
          error
        )}`,
      });
    }
  }

  async function submitForm() {
    const values = await form.getFieldsValue();
    const data = {
      ...formValues,
      ...values,
      ...thirdStepForm,
      birthDate: formValues.birthDate?.format('YYYY-MM-DD'),
      address: {
        state: formValues.state,
        city: formValues.city,
        street: addressValue.label,
        zipcode: formValues.zipcode,
        complement: formValues.complement,
        country: {
          id: formValues?.country,
        },
      },
      nationality: {
        id: COUNTRIES_LIST.find((el) => el.code === formValues.nationality).id,
      },
      acceptedTerms: acceptTermsConditions,
    };

    delete data.state;
    delete data.city;
    delete data.street;
    delete data.zipcode;
    delete data.complement;
    delete data.country;

    submitData(data);
  }

  async function handleSaveForm() {
    const isFormTabValid = await form.validateFields();

    if (!isFormTabValid) return;

    if (step === 3 && isFormTabValid) {
      submitForm();
    }
  }

  const validateNextStep = async () => {
    const isFormTabValid = await form.validateFields();

    if (!isFormTabValid) return;

    if (step === 1 && isFormTabValid) {
      setFormValues((prev) => ({ ...prev, ...form.getFieldsValue() }));
      setStep(2);
    }

    if (step === 2 && isFormTabValid) {
      setFormValues((prev) => ({ ...prev, ...form.getFieldsValue() }));
      setStep(3);
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (whoAmI?.name && whoAmI?.surname) {
      setAlreadyRegistered(true);
      redirectToBriefing();
    }

    setSaving(false);
  }, [whoAmI, isLoading]);

  return (
    <Suspense fallback={<RedirectLoader />}>
      <S.Container>
        <TravellerRegisterHeader />

        <Row gutter={8}>
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 14 }}
          >
            <S.Content>
              <header className="form__header">
                <h2>{t('register.completeRegister')}</h2>
                <FlagMenu />
              </header>

              <Divider />

              {alreadyRegistered ? (
                <AlreadyRegistered />
              ) : (
                <Form {...formOptions} onFinish={() => handleSaveForm()}>
                  {step === 1 && (
                    <FirstStep inputs={inputs} formErrors={formErrors} />
                  )}
                  {step === 2 && (
                    <SecondStep
                      contactsAdressInput={contactsAdressInput}
                      acceptTermsConditions={acceptTermsConditions}
                      setAcceptTermsConditions={setAcceptTermsConditions}
                    />
                  )}
                  {step === 3 && (
                    <ThirdStep
                      form={thirdStepForm}
                      onUpdateForm={updateThirdStepForm}
                      setFormValues={setFormValues}
                    />
                  )}

                  {hasSomeErrorField &&
                    Object.entries(formErrors).map(([key, value]) => {
                      if (!value) return null;
                      return (
                        <div className="mb-24 mt-16">
                          <Typography.Text type="danger" key={key}>
                            {t(`errors.${key}`)}
                          </Typography.Text>
                          <br />
                        </div>
                      );
                    })}

                  <div className="traveler-register__actions">
                    {(step === 2 || step === 3) && (
                      <ContentaButtonPrimaryOutline
                        onClick={() => setStep(step - 1)}
                      >
                        {t('common.back')}
                      </ContentaButtonPrimaryOutline>
                    )}

                    {(step === 1 || step === 2) && (
                      <ContentaButtonPrimary
                        type="primary"
                        className={step === 1 ? 'w-100' : ''}
                        onClick={() => validateNextStep()}
                        disabled={isDisabled}
                      >
                        {t('common.continue')}
                      </ContentaButtonPrimary>
                    )}
                    {step === 3 && (
                      <ContentaButtonPrimary
                        type="primary"
                        onClick={() => handleSaveForm()}
                        className="mb-0"
                        loading={isSaving}
                      >
                        {t('common.register')}
                      </ContentaButtonPrimary>
                    )}
                  </div>
                </Form>
              )}
            </S.Content>
          </Col>
        </Row>
      </S.Container>
    </Suspense>
  );
};

export default TravellerRegister;
