import { Card } from 'antd';
import styled from 'styled-components';

const ContentaCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
`;

export { ContentaCard };
