import { Route } from 'react-router-dom';
import { lazy } from 'react';

const MentorAgenda = lazy(() =>
  import(/* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/Agenda')
);
const Home = lazy(() =>
  import(/* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/Home')
);
const Profile = lazy(() =>
  import(/* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/Profile')
);
const ScheduledTours = lazy(() =>
  import(
    /* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/ScheduledTours'
  )
);
const MeetingResume = lazy(() =>
  import(
    /* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/MeetingResume'
  )
);
const Resume = lazy(() =>
  import(/* webpackChunkName: "advisorChunk" */ 'pages/MentorPages/Resume')
);

const FormBriefing = lazy(() =>
  import(
    /* webpackChunkName: "advisorChunk" */ 'pages/TravellerPages/FormBriefing'
  )
);

export default [
  <Route
    path="advisor/agenda"
    exact
    element={<MentorAgenda />}
    key={'advisor-agenda'}
  />,
  <Route path="advisor/home" exact element={<Home />} key={'advisor-home'} />,
  <Route
    path="advisor/profile"
    exact
    element={<Profile />}
    key={'advisor-profile'}
  />,
  <Route
    path="advisor/scheduled-tours"
    exact
    element={<ScheduledTours />}
    key={'advisor-scheduled-tours'}
  />,
  <Route
    path="advisor/meeting-resume/:id"
    exact
    element={<MeetingResume />}
    key={'advisor-meeting-resume'}
  />,
  <Route
    path="advisor/resume/:id"
    exact
    element={<Resume />}
    key={'advisor-resume'}
  />,
  <Route
    path="advisor/briefing/:id"
    exact
    element={<FormBriefing />}
    key={'advisor-briefing'}
  />,
];
