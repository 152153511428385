import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  ${PaddingContainer};
  margin-bottom: 24px;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 32px;
    margin-top: 56px;
  }

  p,
  h1,
  h2,
  li {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
  }

  h1 {
    font-size: ${convertPxToRem(28)};
    font-weight: 800;
    margin-bottom: 24px;
  }

  p,
  li {
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'lnum';
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
    letter-spacing: 1.25px;
    line-height: 22px;
  }

  .spacing-left {
    margin-left: 24px;
  }

  .terms__subcontainer {
    p {
      margin-left: 24px;
    }
  }

  p + div.terms__subcontainer {
    margin-left: 24px;
  }

  .about-us__image {
    margin: 0 auto;
    display: block;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 500px;
    }

    &.bigger {
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 650px;
      }
    }
  }
`;
