import { Divider } from 'antd';
import User from 'assets/images/user.png';
import ScheduleModal from 'components/ScheduleModal';
import {
  ContentaButtonPrimary,
  ContentaButtonPrimaryGhost,
} from 'components/Styled/ContentaButton';
import { ContentaCard } from 'components/Styled/ContentaCard';
import { CONTENTA_GIFT_CARD } from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import useBookingAdvisor from 'hooks/useBookingAdvisor';
import useCurrencyParse from 'hooks/useCurrencyParse';
import useRedirectToAdvisorPage from 'hooks/useRedirectToAdvisorPage';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import findCountryNameById from 'utils/findCountryNameById';
import handleLastnameByRole from 'utils/handleLastnameByRole';
import isMobile from 'utils/isMobile';

import * as S from './styles';

const checkIfIsInternationalPurchase = ({
  loggedUserCountry,
  advisorCountry,
  addressCountry,
}) => {
  let advisorCountryLocal;
  if (addressCountry) {
    advisorCountryLocal = findCountryNameById({
      acronym: addressCountry?.acronym,
    });
  } else {
    advisorCountryLocal = findCountryNameById({
      acronym: advisorCountry?.acronym,
    });
  }

  const userLogged = findCountryNameById({
    acronym: loggedUserCountry?.acronym,
  });

  return advisorCountryLocal !== userLogged;
};
export function FoundMentor({ advisor, params }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAdvisorSchedules } = useBookingAdvisor();
  const { parseCurrencyValue } = useCurrencyParse();
  const { redirectToAdvisorPage } = useRedirectToAdvisorPage();
  const { userRole, whoAmI } = useUser();

  const [isOpenScheduleModal, setOpenScheduleModal] = useState(false);
  const [availableSchedules, setAvailableSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isPresential = params.scheduling_type
    ? params.scheduling_type === 'PRESENTIAL_SERVICE'
    : params.split('=').indexOf('PRESENTIAL_SERVICE') !== -1;
  const isInternationalPurchase = checkIfIsInternationalPurchase({
    loggedUserCountry: whoAmI?.address?.country,
    advisorCountry: advisor?.country,
    addressCountry: advisor?.addressCountry,
  });
  const IS_MOBILE = isMobile();

  const getAvailableSchedules = async (advisorId) => {
    setIsLoading(true);
    try {
      const dates = await getAdvisorSchedules(advisorId);
      setAvailableSchedules(dates);
    } catch (error) {
      setAvailableSchedules([]);
    }

    setIsLoading(false);
  };

  const handleClickBook = () => {
    const giftDataStorage = sessionStorage.getItem(CONTENTA_GIFT_CARD);
    const giftData = giftDataStorage ? JSON.parse(giftDataStorage) : null;
    if (giftData) {
      navigate(`/gift-advisor-profile/${advisor.id}`, {
        state: {
          advisor,
        },
      });

      return;
    }
    setOpenScheduleModal(true);
    getAvailableSchedules(advisor.id);
  };

  const handleSeeMoreClick = () => {
    const giftDataStorage = sessionStorage.getItem(CONTENTA_GIFT_CARD);
    const giftData = giftDataStorage ? JSON.parse(giftDataStorage) : null;
    if (giftData) {
      navigate(`/gift-advisor-profile/${advisor.id}`, {
        state: {
          advisor,
        },
      });

      return;
    }

    redirectToAdvisorPage(advisor, { advisor });
  };

  return (
    <>
      <S.FoundMentorContainer>
        <ContentaCard>
          <div className="found-mentor__picture">
            <S.MentorImg
              src={advisor?.imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = User;
              }}
            />
          </div>

          <div className="found-mentor__body">
            <h3>
              {advisor?.city},{' '}
              {findCountryNameById({ acronym: advisor?.country?.acronym })}
            </h3>
            <h2>
              {advisor?.name}
              {handleLastnameByRole({
                role: userRole,
                lastname: advisor?.surname,
              })}
            </h2>

            <div className="found-mentor__body-info">
              <div className="found-mentor__body-info__item">
                {isPresential ? (
                  <>
                    <div className="found-mentor__body-info__item">
                      <span className="info__item--title">
                        {t('guides.presentialTour')}
                      </span>
                      <span className="info__item--label">
                        {t('guides.packageValue')}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="info__item--title">
                      {t('guides.videoCallPrice')}
                    </span>
                    {advisor.valueHourVirtual > 0 ? (
                      <span className="info__item--label">
                        {parseCurrencyValue(advisor.valueHourVirtual)} /{' '}
                        {t('common.hour')}
                      </span>
                    ) : (
                      <span className="info__item--label">
                        {t('common.notInformed')}
                      </span>
                    )}
                  </>
                )}
              </div>

              {isInternationalPurchase && (
                <p className="found-mentor__international">
                  {t('traveler.home.internationalPurchase')}
                </p>
              )}

              <p className="found-mentor__about">
                {advisor?.summary?.length > 200
                  ? `${advisor.summary.substring(0, 200)}...`
                  : advisor?.summary}
              </p>

              {!IS_MOBILE && (
                <ContentaButtonPrimaryGhost
                  className="found-mentor__see"
                  onClick={handleSeeMoreClick}
                >
                  {t('buttons.seeMore')}
                </ContentaButtonPrimaryGhost>
              )}
            </div>
          </div>

          {IS_MOBILE && (
            <ContentaButtonPrimaryGhost
              className="found-mentor__see is-mobile"
              onClick={handleSeeMoreClick}
            >
              {t('buttons.seeMore')}
            </ContentaButtonPrimaryGhost>
          )}

          <ContentaButtonPrimary onClick={() => handleClickBook()}>
            {t('buttons.bookNow')}
          </ContentaButtonPrimary>
          {isInternationalPurchase && (
            <p className="found-mentor__international-info">
              {t('traveler.home.priceSubjectChange')}
            </p>
          )}
        </ContentaCard>
      </S.FoundMentorContainer>

      <ScheduleModal
        isOpen={isOpenScheduleModal}
        setOpen={setOpenScheduleModal}
        availableSchedules={availableSchedules}
        setAvailableSchedules={setAvailableSchedules}
        advisor={advisor}
        isLoading={isLoading}
      />
      <Divider />
    </>
  );
}

FoundMentor.propTypes = {
  advisor: PropTypes.shape({
    description: PropTypes.string,
    photo: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
