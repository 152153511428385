import { Divider } from 'antd';

import ContentaLogo from 'components/Icons/ContentaLogo';
import MailIcon from 'components/Icons/Mail';
import YoutubeIcon from 'components/Icons/SocialIcons/Youtube';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cadasturLogo from '../../../assets/images/logos/cadastur.png';
import WFTGALogo from '../../../assets/images/logos/WFTGA.png';
import CoinIcon from '../../Icons/CoinIcon';
import FacebookIcon from '../../Icons/SocialIcons/Facebook';
import InstagramIcon from '../../Icons/SocialIcons/Instagram';
import WorldIcon from '../../Icons/WorldIcon';

import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';
import * as S from './styles';

function FooterIconsContainer({
  label,
  icon,
  isSocial,
  href,
  auxClassName = '',
}) {
  if (isSocial) {
    return (
      <div className={`footer__icon-container ${auxClassName}`}>
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="footer__icon-container__link"
          aria-label={label}
        >
          {icon}
        </a>
      </div>
    );
  }
  return (
    <div className={`footer__icon-container ${auxClassName}`}>
      {icon}
      {label && !href && <span>{label}</span>}
      {href && (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="footer__icon-container__link"
          aria-label={label}
        >
          {label}
        </a>
      )}
    </div>
  );
}

export function FooterApp() {
  const { t } = useTranslation();
  const { userCurrency } = useUser();
  const { currentLanguage } = useLanguage();

  // const canShowAlert =
  //   localStorage.getItem('launchedRecently') !== 'false' ?? true;

  return (
    <>
      <S.FooterContainer>
        <div className="footer__top">
          <div className="footer__logo">
            <ContentaLogo />
            <div className="footer__logo-contact">
              <MailIcon />
              <span>
                <a href="mailto:contactus@contenta360.com">
                  contactus@contenta360.com
                </a>
              </span>
            </div>
          </div>

          <div className="footer__top--right">
            <address>
              <h3>{t('common.address')}</h3>
              <span>Alameda Lorena, 131</span>
              <span>Cj 31 Jardim Paulista</span>
              <span>São Paulo - SP</span>
              <span>01.424-000</span>
            </address>

            <div className="footer__links">
              <Link to="/faq">{t('footer.faq')}</Link>
              <Link to="/terms-and-conditions">
                {t('footer.termsConditions')}
              </Link>
              <Link to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
              <Link to="/about-us">{t('footer.aboutUs')}</Link>
            </div>
          </div>
        </div>
        <Divider />
        <div className="footer__content">
          <div className="footer__social-links">
            <span>© 2022 Contenta360.</span>
            <Link to="/privacy-policy">{t('footer.privacy')}</Link>
            <Link to="/terms-and-conditions">{t('footer.terms')}</Link>
            {/* <Link to="/">{t('footer.legal')}</Link> */}
          </div>

          <FooterIconsContainer
            label={`${userCurrency.currencyStandard} - ${userCurrency.name}`}
            icon={<CoinIcon />}
            auxClassName="extra-margin currency"
          />
          <FooterIconsContainer
            label={t(`common.languages.${currentLanguage}`)}
            icon={<WorldIcon />}
            auxClassName="--last extra-margin language"
          />

          <div className="footer__social-icons">
            <FooterIconsContainer
              icon={<YoutubeIcon />}
              href="https://www.youtube.com/channel/UCp4llvJRbRzmcWzvONE02iA"
              auxClassName="youtube"
              label=""
              isSocial
            />
            <FooterIconsContainer
              icon={<InstagramIcon />}
              href="https://www.instagram.com/contenta360/"
              auxClassName="instagram"
              label=""
              isSocial
            />
            <FooterIconsContainer
              icon={<FacebookIcon />}
              href="https://facebook.com/contenta360"
              auxClassName="facebook"
              label=""
              isSocial
            />
          </div>
        </div>
        <div className="footer__affiliates">
          <p>{t('footer.affiliates')}:</p>
          <div className="footer__affiliates-logos">
            <a
              href="https://cadastur.turismo.gov.br/hotsite/#!/public/capa/entrar"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={cadasturLogo}
                alt="cadastur logo"
                style={{ width: '60px' }}
              />
            </a>
            <a href="https://www.wftga.org/" target="_blank" rel="noreferrer">
              <img src={WFTGALogo} alt="WTFGA logo" style={{ width: '40px' }} />
            </a>
          </div>
        </div>
      </S.FooterContainer>
      {/* {canShowAlert && (
        <Space
          direction="vertical"
          style={{ width: '100vw', position: 'fixed', bottom: 0, zIndex: 9999 }}
        >
          <Alert
            message={t('footer.launchedRecently')}
            type="info"
            banner
            closable
            style={{ padding: '36px' }}
            onClose={() => {
              localStorage.setItem('launchedRecently', 'false');
            }}
          />
        </Space>
      )} */}
    </>
  );
}
