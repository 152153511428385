function IconBrazil(props) {
  return (
    <svg
      width={22}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <rect width={22} height={16} rx={2} fill="#249F58" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 2.133 18.857 8 11 13.867 3.143 8"
          fill="#FFDA2C"
        />
        <path
          d="M11 11.733c2.025 0 3.667-1.671 3.667-3.733 0-2.062-1.642-3.733-3.667-3.733S7.333 5.938 7.333 8c0 2.062 1.642 3.733 3.667 3.733Z"
          fill="#1A47B8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.429 9.6v1.067h1.047V9.6H9.43Zm2.095 0v1.067h1.047V9.6h-1.047ZM8.38 6.4s2.373.427 4.082 1.171l2.205.962"
          fill="#fff"
        />
        <path d="M8.38 6.4s2.373.427 4.082 1.171l2.205.962" stroke="#fff" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconBrazil;
