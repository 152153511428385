import { Route } from 'react-router-dom';
import CheckoutGiftCard from 'pages/GiftCardPages/CheckoutGiftCard';
import GiftAdvisorProfile from 'pages/GiftCardPages/GiftAdvisorProfile';

export default [
  <Route
    path="/checkout-gift"
    element={<CheckoutGiftCard />}
    key={'checkout-gift-card'}
  />,
  <Route
    path="/gift-advisor-profile/:id"
    element={<GiftAdvisorProfile />}
    key={'gift-advisor-profile'}
  />,
];
