import HttpClient from './utils/HttpClient';

class ScheduleService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async createSchedule({ advisorId, schedule }) {
    return this.httpClient.post(`/booking/${advisorId}/reserve`, schedule);
  }

  async updateScheduleMeetingPoint({ advisorId, scheduleId, meetingPoint }) {
    return this.httpClient.put(
      `/booking/${advisorId}/reserve/${scheduleId}/meeting-point`,
      meetingPoint
    );
  }
}

export default new ScheduleService();
