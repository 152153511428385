import styled from 'styled-components';
import { ContentaModal } from '../../Styled/ContentaModal';
import { ContentaButtonDanger } from '../../Styled/ContentaButton';

export const ModalContainer = styled(ContentaModal)`
  font-family: ${({ theme }) => theme.fonts.texts};
  width: 614px;

  .checkout-modal__terms {
    font-size: 10pt;
  }

  .checkout-modal__button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  border: 0 solid rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  font-family: ${({ theme }) => theme.fonts.texts};
  margin: 0.8rem 0;
  gap: 10px;
  padding-bottom: 10px;

  > p {
    margin-bottom: 0;
  }

  .content-row {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .checkout-modal__icon-wrapper {
    min-width: 52px;
    height: 52px;
    border-radius: 8px;
    background: ${({ theme, briefingType }) =>
      briefingType === 'presential'
        ? theme.colors.blue[40]
        : theme.colors.yellow[40]};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-modal__icon-wrapper-neutral-bg {
    background: transparent;
  }

  .col-label {
    font-size: 8pt;
    font-weight: 400;
  }

  .col-title {
    font-size: 12pt;
    font-weight: 700;
  }

  ul {
    padding-left: 1rem;

    > li {
      font-size: 10pt;
      font-weight: 400;
    }

    li::marker {
      font-size: 0.8em;
      line-height: 20px;
    }
  }
`;

export const ConfirmationButton = styled(ContentaButtonDanger)`
  background: ${({ theme, briefingType }) =>
    briefingType === 'presential'
      ? theme.colors.blue[40]
      : theme.colors.yellow[40]};
  width: 100%;
  margin: 15px 0;

  &:hover,
  &:focus {
    background-color: ${({ theme, briefingType }) =>
      briefingType === 'presential'
        ? theme.colors.blue[50]
        : theme.colors.error[40]};
    border: 1px solid
      ${({ theme, briefingType }) =>
        briefingType === 'presential'
          ? theme.colors.blue[50]
          : theme.colors.error[40]};
  }
`;
