import { Collapse } from 'antd';
import * as S from './styles';

const { Panel } = Collapse;

function MostAsked({ faq = [] }) {
  return (
    <S.MostAskedContainer>
      <h1>Perguntas frequentes</h1>

      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="end">
        {faq?.map((item) => (
          <Panel header={item.question} key={item.id}>
            <p>{item.answer}</p>
          </Panel>
        ))}
      </Collapse>

      <p className="most-asked__another-question">
        Se você tem qualquer outra pergunta, favor enviar e-mail para{' '}
        <a href="mailto:contactus@contenta360.com">contactus@contenta360.com</a>
      </p>
    </S.MostAskedContainer>
  );
}

export default MostAsked;
