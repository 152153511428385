import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_LANGUAGES, getLanguageObject } from '../constants/app-languages';

const LanguageContext = createContext(null);

export function LanguageProvider({ children, language }) {
  const { i18n, t } = useTranslation();
  const localStorageLanguage = sessionStorage.getItem('@contenta-language');
  const [languageObject, setLanguageObject] = useState(
    APP_LANGUAGES.find((idiom) => idiom.key === i18n?.language)
  );
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorageLanguage || language || 'en-US'
  );

  function handleChangeLanguage(idiom) {
    setCurrentLanguage(idiom);
    i18n.changeLanguage(idiom);
    sessionStorage.setItem('@contenta-language', idiom);
    setLanguageObject(APP_LANGUAGES.find((i) => i.key === idiom));
  }

  const onLanguageMenuClick = ({ key }) => {
    i18n.changeLanguage(key);
    sessionStorage.setItem('@contenta-language', key);
    setLanguageObject(APP_LANGUAGES.find((idiom) => idiom.key === key));
  };

  const languagesOptions = getLanguageObject(t).sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const value = useMemo(
    () => ({
      languagesOptions,
      currentLanguage,
      setCurrentLanguage,
      onLanguageMenuClick,
      handleChangeLanguage,
      languageObject,
      faqLanguage: languageObject?.value || 'EN',
    }),
    [currentLanguage, languageObject, languagesOptions]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);
