import { notification } from 'antd';
import RedirectLoader from 'components/RedirectingLoader';
import {
  CONTENTA_GIFT_CARD,
  CONTENTA_REDIRECT,
  CONTENTA_REGISTER_USER,
  CONTENTA_TOKEN,
  STATE_SCHEDULE,
} from 'constants/storages-values';
import { STATUS_TYPE, USER_TYPES } from 'constants/types';
import { useUser } from 'contexts/userContext';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from 'services/Auth0Service';
import { canViewAdmin, keyRole } from 'utils/isRole';

function redirectToBriefing(navigate, selectedSchedule) {
  const storageData = {
    selectedSchedule: {
      date: selectedSchedule.date,
      startAt: selectedSchedule.startAt,
      endAt: selectedSchedule.endAt,
      presentialTour: { ...selectedSchedule.presentialTour },
    },
    advisor: selectedSchedule.advisor,
    isRedirect: true,
  };

  sessionStorage.setItem(STATE_SCHEDULE, JSON.stringify(storageData));
  navigate('/register');
}

// useEffect(() => {
//   if (isLoading || !user) {
//     return;
//   }

//   sessionStorage.removeItem(CONTENTA_USER);
//   sessionStorage.setItem(CONTENTA_USER, JSON.stringify(user));
//   handleUserContext(user);

//   const pathname = sessionStorage.getItem(CONTENTA_REDIRECT);
//   sessionStorage.removeItem(CONTENTA_REDIRECT);

//   const isGiftAdvisorProfile =
//     pathname?.includes('/gift-advisor-profile/') ?? false;
//   if (isGiftAdvisorProfile) {
//     navigate(pathname);
//     return;
//   }

//   const isUnloggedPage = isUnloggedRoute(pathname);
//   if (
//     !isUnloggedPage &&
//     (pathname === '/checkout' || pathname === '/checkout-gift')
//   ) {
//     const contentKey =
//       pathname === '/checkout'
//         ? CONTENTA_SELECTED_SCHEDULE
//         : CONTENTA_GIFT_CARD;
//     const hasScheduleToRedirect = sessionStorage.getItem(contentKey);
//     if (hasScheduleToRedirect) {
//       try {
//         const state = JSON.parse(hasScheduleToRedirect);
//         redirectToBriefing(navigate, state);
//         return;
//       } catch (error) {
//         console.error('Error parsing stored schedule data:', error);
//       }
//     }
//   }

//   if (pathname && pathname !== '/redirecting') {
//     navigate(pathname);
//   }
// }, [isLoading, user, handleUserContext, navigate]);

export function Redirect() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    setAuthUser,
    setIsLoading: setIsFetchingUser,
    whoAmI,
    setToken,
  } = useUser();

  const handleAdvisorRedirect = () => {
    if (whoAmI.status === STATUS_TYPE.APPROVED) {
      navigate('/advisor/home');
    } else if (canViewAdmin(whoAmI)) {
      navigate('/admin');
    } else {
      navigate('/guide/register');
    }
  };

  const handleTravelerRedirect = () => {
    navigate('/home');
  };

  const handleRedirect = (user) => {
    const role = user[keyRole]?.[0];

    switch (role) {
      case USER_TYPES.advisor:
        handleAdvisorRedirect();
        break;
      case USER_TYPES.traveler:
        handleTravelerRedirect();
        break;
      case USER_TYPES.admin:
        navigate('/admin');
        break;
      default:
    }
  };

  const redirectByUserType = (user, urlParams = '') => {
    const role = user[keyRole]?.[0];

    switch (role) {
      case USER_TYPES.advisor:
        return '/advisor/home';
      case USER_TYPES.traveler:
        return '/home';
      case USER_TYPES.admin:
        return '/admin';
      default: {
        const redirectTo = urlParams.get('loginAs');
        if (redirectTo === 'advisor') {
          return '/guide/register';
        }

        return '/register';
      }
    }
  };

  const handleSocialLogin = async (user) => {
    const urlParams = new URLSearchParams(location.search);

    const hasConnection = urlParams.get('connection');
    const originType = urlParams.get('origin');
    const redirectTo = urlParams.get('redirect_to');

    if (redirectTo) {
      const urls = {
        advisor_profile: '/advisor/profile',
        traveler_profile: '/profile',
        advisor_home: '/advisor/home',
        traveler_home: '/home',
      };
      navigate(urls[redirectTo]);
      return;
    }

    const redirectSession = sessionStorage.getItem(CONTENTA_REDIRECT);
    if (redirectSession) {
      sessionStorage.removeItem(CONTENTA_REDIRECT);
      navigate(redirectSession);
      return;
    }

    if (
      originType === 'advisor-register-auth0' ||
      originType === 'traveler-register-auth0'
    ) {
      localStorage.setItem(CONTENTA_REGISTER_USER, JSON.stringify(user));
      window.close();
      return;
    }

    if (hasConnection) {
      window.close();
      window.opener.location = redirectByUserType(user, urlParams);
      return;
    }

    navigate(redirectByUserType(user));
  };

  const processHash = (hash) => {
    const handleError = (err) => {
      console.error(err);
      notification.error({
        message: t('common.error.generic'),
        description: err.errorDescription,
        duration: 4.5,
      });
      setIsFetchingUser(false);
      navigate(`/error?error=${err.error}`);
    };

    authService.parseHash({ hash }, (err, authResult) => {
      if (err) {
        handleError(err);
        return;
      }

      if (authResult) {
        setToken(authResult.accessToken);
        Cookies.set(CONTENTA_TOKEN, authResult.accessToken);
        authService.client.userInfo(authResult.accessToken, (err, user) => {
          if (err) {
            console.log('error:', err);
            handleError(err);
            return;
          }

          setAuthUser(user);
          setIsFetchingUser(false);
          handleSocialLogin(user);
        });
      }
    });
  };

  useEffect(() => {
    const hasGiftCard = sessionStorage.getItem(CONTENTA_GIFT_CARD) || {};

    if (Object.keys(hasGiftCard).length > 0) {
      navigate('/checkout-gift');
    }

    if (Object.keys(whoAmI).length > 0) {
      handleRedirect(whoAmI);
      if (whoAmI.token) {
        Cookies.set(CONTENTA_TOKEN, whoAmI.token);
      }
    }
  }, [whoAmI]);

  useEffect(() => {
    if (location.hash) {
      setIsFetchingUser(true);
      processHash(location.hash);
    }
  }, [location]);

  return <RedirectLoader />;
}

export default Redirect;
