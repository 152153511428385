import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpecialtiesService from 'services/SpecialtiesService';

const useSpecialties = () => {
  const { t } = useTranslation();
  const [specialtiesListOrdered, setSpecialtiesListOrdered] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSpecialties = async () => {
    try {
      setIsLoading(true);
      const fetchedSpecialties = await SpecialtiesService.getSpecialties();
      setSpecialties(fetchedSpecialties);
    } catch (error) {
      console.error('Erro ao buscar especialidades:', error);
      setSpecialties([]);
    }
    setIsLoading(false);
  };

  const sortSpecialties = (list) => {
    return list
      .map((specialty) => ({
        label: t(`specialties.${specialty.name}`),
        code: specialty.code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  useEffect(() => {
    const orderedList = sortSpecialties(specialties);
    setSpecialtiesListOrdered(orderedList);
  }, [specialties]);

  useEffect(() => {
    getSpecialties();
  }, []);

  return {
    specialties,
    specialtiesListOrdered,
    specialtiesLoading: isLoading,
    getSpecialties,
  };
};

export default useSpecialties;
