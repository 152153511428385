import FlagIcon from '../components/Styled/Flag';

export const APP_LANGUAGES = [
  {
    value: 'PT-BR',
    label: 'Português (Brasileiro)',
    key: 'pt-BR',
    flag: 'br',
    flagNode: <FlagIcon code="br" size="2x" />,
  },
  {
    value: 'EN',
    label: 'Inglês',
    key: 'en-US',
    flag: 'us',
    flagNode: <FlagIcon code="us" size="2x" />,
  },
];

export const getLanguageObject = (t) => [
  {
    value: 'PT-BR',
    label: t('common.languages.pt-BR'),
    key: 'pt-BR',
    flag: 'br',
    flagNode: <FlagIcon code="br" size="2x" />,
  },
  {
    value: 'EN',
    label: t('common.languages.en-US'),
    key: 'en-US',
    flag: 'us',
    flagNode: <FlagIcon code="us" size="2x" />,
  },
];
