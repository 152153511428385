import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { useLocation } from 'react-router-dom';

import AdvisorCard from 'components/AdvisorCard';
import DesktopView from '../DesktopView';
import MentorDetailsHeader from '../MentorDetailsHeader';
import MentorDetailsSchedule from '../MentorDetailsSchedule';
import MobileView from '../MobileView';
import ScrollToTop from 'components/ScrollToTop';

import { useMentor } from '../mentorContext';

import * as S from '../styles';

function MentorContainer() {
  const { state } = useLocation();

  const {
    availableSchedules,
    isLoading: isLoadingAdvisor,
    loading,
    mentor,
    setAvailableSchedules,
  } = useMentor();
  const [advisorInfo, setAdvisorInfo] = useState({});

  const advisorState = state?.advisor;

  const isDesktop = window.innerWidth >= 1024;
  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    setAdvisorInfo((advisor) => ({
      ...advisor,
      ...advisorState,
      ...mentor,
    }));
  }, [mentor, advisorState]);

  return (
    <>
      <ScrollToTop />

      {isDesktop && <MentorDetailsHeader />}

      <S.MentorDetailsContainer>
        <Row gutter={[24]}>
          <Col xs={24} lg={8}>
            <AdvisorCard
              advisor={advisorInfo}
              isLoadingAdvisor={isLoadingAdvisor}
            />

            {isDesktop && (
              <MentorDetailsSchedule
                advisor={mentor}
                isLoading={isLoadingAdvisor || loading}
                schedules={availableSchedules}
                setAvailableSchedules={setAvailableSchedules}
              />
            )}
          </Col>

          <Col xs={24} lg={16}>
            {isMobile && (
              <MobileView
                advisor={advisorInfo}
                loading={isLoadingAdvisor || loading}
              />
            )}

            {!isMobile && (
              <DesktopView
                advisor={advisorInfo}
                loading={isLoadingAdvisor || loading}
              />
            )}
          </Col>
        </Row>
      </S.MentorDetailsContainer>
    </>
  );
}

export default MentorContainer;
