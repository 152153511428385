import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentService from 'services/PaymentService';

const useGiftCard = ({ advisorId, protocol }) => {
  const { t } = useTranslation();
  const [errorGiftCard, setErrorGiftCard] = useState(false);
  const [giftCard, setGiftCard] = useState('');
  const [loadingGiftCard, setLoadingGiftCard] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [totalCheckoutValue, setTotalCheckoutValue] = useState(0);
  const [valueGiftCard, setValueGiftCard] = useState(undefined);
  const [minimumCharge, setMinimumCharge] = useState({
    minimumChargeApplied: false,
    minimumCharge: 0,
    currency: '',
  });

  const getSuccesMessage = (totalAmount) => {
    const messagesSuccess = {
      couponExtraValue: t('common.couponExtraValue').replace(
        '${amount}',
        totalAmount
      ),
      couponExaclyValue: t('common.couponExaclyValue').replace(
        '${amount}',
        totalAmount
      ),
      couponMissingValue: t('common.couponMissingValue').replace(
        '${amount}',
        totalAmount
      ),
    };

    if (totalAmount < 0) {
      return messagesSuccess.couponExtraValue;
    } else if (totalAmount === 0) {
      return messagesSuccess.couponExaclyValue;
    }

    return messagesSuccess.couponMissingValue;
  };

  const getGiftCardValue = async ({ paymentValue }) => {
    setErrorGiftCard(false);
    setLoadingGiftCard(true);

    try {
      const resp = await PaymentService.getGiftcard({
        protocol,
        data: {
          advisorId,
          coupon: giftCard,
        },
      });

      const giftCardValue = resp.amount;

      setSuccessMessage(getSuccesMessage(giftCardValue));

      setValueGiftCard(giftCardValue);

      setMinimumCharge({
        minimumChargeApplied: resp.minimumChargeApplied,
      });
    } catch (error) {
      console.error('[ERROR][useGiftCard]', error);
      setErrorGiftCard(true);
      setValueGiftCard(0);
      setTotalCheckoutValue(paymentValue);
    } finally {
      setLoadingGiftCard(false);
    }
  };

  const handleClickGiftCard = (paymentValue) => {
    getGiftCardValue({ paymentValue });
  };

  return {
    errorGiftCard,
    giftCard,
    loadingGiftCard,
    minimumCharge,
    successMessage,
    totalCheckoutValue,
    valueGiftCard,
    handleClickGiftCard,
    setGiftCard,
    setTotalCheckoutValue,
  };
};

export default useGiftCard;
