import { Card, Col, Divider, Form, Input, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FooterCard from 'components/Profile/FooterCard';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useUser } from 'contexts/userContext';
import usePersonalAddressInputs from 'hooks/Register/usePersonalAddress';
import IMentor from 'interfaces/Mentor';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'utils/errorHandler';
import findCountryNameById from 'utils/findCountryNameById';
import { CardTitle } from '../../styles';
import { ContentaInput } from 'components/Styled/ContentaInput';

export const KanaKanjiInputs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <CardTitle>Kana</CardTitle>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={12}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.name')}
              name="kanaName"
              className="initial-font-transform"
            >
              <ContentaInput />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
        <Col xs={12}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.surname')}
              name="kanaSurname"
              className="initial-font-transform"
            >
              <ContentaInput />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <CardTitle>Kanji</CardTitle>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={12}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.name')}
              name="kanjiName"
              className="initial-font-transform"
            >
              <ContentaInput />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
        <Col xs={12}>
          <ContentaInputGroup>
            <Form.Item
              label={t('guides.surname')}
              name="kanjiSurname"
              className="initial-font-transform"
            >
              <ContentaInput />
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      </Row>
    </>
  );
};

function Personal({ mentor, updateMentor, isLoading }) {
  const { address, addressInputs, inputs, zipcodeValues } =
    usePersonalAddressInputs(mentor);
  const { registerFieldsHelper } = useUser();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [phraseForm] = Form.useForm();
  const selectedNationality = Form.useWatch('nationality', form);

  const formOptions = {
    form,
    layout: 'vertical',
    requiredMark: false,
  };

  const savePersonalData = async () => {
    if (!registerFieldsHelper.isAdult) {
      errorHandler({ typeError: 'GENERIC', keyMessage: 'under18' });
      return;
    }

    try {
      let values = await form.validateFields();
      values = {
        ...values,
        address1: address?.label,
      };
      updateMentor({ values });
    } catch (error) {
      errorHandler({ error, t });
    }
  };

  const savePhrase = async () => {
    try {
      const values = await phraseForm.validateFields();
      updateMentor({ values });
    } catch (error) {
      errorHandler({ error, t });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...zipcodeValues,
      // ...addressValues,
    });
  }, [zipcodeValues]);

  useEffect(() => {
    if (Object.keys(mentor).length === 0 && mentor.constructor === Object) {
      return;
    }
    const mentorValues = new IMentor(mentor);
    const country = findCountryNameById({
      id: mentorValues?.country,
    });
    form.setFieldsValue({
      ...mentorValues,
      nationality: country,
    });
    phraseForm.setFieldsValue(mentorValues);
  }, [mentor]);

  return (
    <div className="tabs__personal-tab">
      <Form {...formOptions}>
        <Card bordered>
          <Row gutter={16}>
            <Col span={24}>
              <CardTitle>{t('mentorProfile.personalData')}</CardTitle>
            </Col>
          </Row>
          <Row gutter={12}>
            {inputs.map((input) => (
              <Col
                key={input.name}
                xs={input?.col?.xs || 24}
                sm={input?.col?.sm || 4}
                lg={input?.col?.lg || 8}
              >
                <ContentaInputGroup>
                  <Form.Item
                    hasFeedback={input.hasFeedback}
                    label={input.label}
                    name={input.name}
                    placeholder={input?.placeholder}
                    valuePropName={input?.valuePropName || 'value'}
                    rules={[
                      { required: input.isRequired, message: input?.message },
                    ]}
                  >
                    {input.input || <Input className={input?.className} />}
                  </Form.Item>
                  {!registerFieldsHelper.isAdult && input?.name === 'year' && (
                    <Typography.Text type="danger">
                      {t('guides.mustBeAdult')}
                    </Typography.Text>
                  )}
                  {!registerFieldsHelper.isValidDate &&
                    input?.name === 'year' && (
                      <Typography.Text type="danger">
                        {t('guides.invalidDate')}
                      </Typography.Text>
                    )}
                </ContentaInputGroup>
              </Col>
            ))}
          </Row>

          {selectedNationality === 'Japan' ||
            (selectedNationality === 109 && <KanaKanjiInputs />)}

          <Divider />

          <Row gutter={16}>
            <Col span={24}>
              <CardTitle>{t('mentorProfile.residence')}</CardTitle>
            </Col>
          </Row>

          <Row gutter={24}>
            {addressInputs.map((input) => (
              <Col key={input.name} xs={24} sm={input.col}>
                <ContentaInputGroup>
                  <Form.Item
                    hasFeedback={input.hasFeedback}
                    label={input.label}
                    name={input.name}
                    rules={[
                      { required: input.isRequired, message: input?.message },
                    ]}
                  >
                    {input.input || (
                      <Input
                        onBlur={input?.onBlur}
                        className={input?.className}
                      />
                    )}
                  </Form.Item>
                </ContentaInputGroup>
              </Col>
            ))}
          </Row>

          <FooterCard onClick={savePersonalData} isSaving={isLoading} />
        </Card>
      </Form>

      <Form {...formOptions} form={phraseForm}>
        <Card bordered>
          <Row gutter={16}>
            <Col span={24}>
              <CardTitle>{t('mentorProfile.profilePhrase')}</CardTitle>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24}>
              <ContentaInputGroup>
                <Form.Item
                  label={t('mentorProfile.profilePhrase')}
                  name="profilePhrase"
                  className="initial-font-transform"
                  rules={[
                    {
                      required: true,
                      message: t('guides.tellAboutYourHistoryRequired'),
                    },
                    {
                      max: 60,
                      message: t('guides.tellAboutYourHistoryCharLimit'),
                    },
                  ]}
                >
                  <TextArea rows={1} maxLength={60} showCount />
                </Form.Item>
              </ContentaInputGroup>
            </Col>
          </Row>

          <FooterCard onClick={savePhrase} isSaving={isLoading} />
        </Card>
      </Form>
    </div>
  );
}

export default Personal;
