import { Drawer } from 'antd';
import styled from 'styled-components';

export const LateralMenu = styled(Drawer)`
  border-radius: 16px;

  .ant-drawer-content-wrapper {
    padding: 24px;
    box-shadow: none !important;
    width: 100vw !important;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 420px;
    }
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 12px rgb(0 0 0 / 25%);
    border-radius: 16px;
  }

  .ant-drawer-body {
    display: flex;
    flex-flow: column nowrap;
  }

  .lateral-menu__logo {
    margin-bottom: 72px;
    margin-top: 24px;
    width: 190px;
  }
`;

export const MenuBody = styled.div`
  /* overflow-y: scroll; */
  .lateral-menu__item {
    align-items: center;
    margin-bottom: 24px;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;

    .anticon {
      font-size: 1rem;
      margin-right: 16px;
    }

    .lateral-menu__label {
      color: ${({ theme }) => theme.colors.grayTitle};
      font-family: ${({ theme }) => theme.fonts.texts};
    }
  }

  .lateral-menu__account {
    color: ${({ theme }) => theme.colors.grayTitle};
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 24px;
  }
`;

export const Separator = styled.div`
  align-items: center;
  border: 0.5px solid rgb(117 117 117 / 30%);
  margin: 36px auto;
  text-align: center;
`;

export const MenuFooter = styled.footer`
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  display: flex;
  margin-top: auto;
  padding: 16px;

  .menu-footer__avatar {
    border-radius: 50%;
    height: 64px;
    width: 64px;
    object-fit: cover;
  }

  .menu-footer__container {
    display: flex;
    flex-flow: column wrap;
    margin-left: 16px;

    .menu-footer__username {
      color: ${({ theme }) => theme.colors.grayTitle};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      margin-bottom: 4px;
    }

    .menu-footer__email {
      color: ${({ theme }) => theme.colors.grayTitle};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;
