import { Col, Form, Row, Typography } from 'antd';
import { ContentaCheckbox } from 'components/Styled/ContentaCheckbox';
import { ContentaInput } from 'components/Styled/ContentaInput';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { LANGUAGES_COUNTRIES } from 'constants/languages-countries';
import { useTranslation } from 'react-i18next';

import { useRegister } from '../context/useRegister';

function AdvisorIdioms() {
  const { t } = useTranslation();
  const { languageError, setFormValues } = useRegister();

  const onChange = (input, e) => {
    setFormValues((prevFormValues) => {
      const languagesArray = Array.isArray(prevFormValues.languages)
        ? prevFormValues.languages
        : [];

      const updatedLanguages = [...languagesArray];

      if (e.target.checked) {
        updatedLanguages.push({ code: input.value });
      } else {
        return {
          ...prevFormValues,
          languages: updatedLanguages.filter(
            (lang) => lang.code !== input.value
          ),
        };
      }

      return { ...prevFormValues, languages: updatedLanguages };
    });
  };

  return (
    <>
      <p className="label-title">{t('guides.languages')}</p>
      <Row gutter={24} className="languages-options">
        <Col xs={24} sm={16}>
          {LANGUAGES_COUNTRIES.map((input) => (
            <Col key={input.name} xs={24} sm={12}>
              <ContentaInputGroup>
                <Form.Item
                  name={input.key}
                  className="form-languages"
                  valuePropName="idiomGuide"
                >
                  <ContentaCheckbox onChange={(e) => onChange(input, e)}>
                    {t(`languages.${input.name}`)}
                  </ContentaCheckbox>
                </Form.Item>
              </ContentaInputGroup>
            </Col>
          ))}
        </Col>

        <Col xs={24} sm={8}>
          <ContentaInputGroup>
            <Form.Item label={t('guides.otherLanguages')} name="otherLanguages">
              <ContentaInput />
            </Form.Item>
          </ContentaInputGroup>
        </Col>

        {languageError && (
          <Col xs={24} sm={8}>
            <Typography.Text type="danger">
              {t('guides.languageRequired')}
            </Typography.Text>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AdvisorIdioms;
