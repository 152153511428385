import { useTranslation } from 'react-i18next';
import { MentorTabContainer } from './styles';
import { useMentor } from '../mentorContext';
import { Divider } from 'antd';
import useCurrencyParse from 'hooks/useCurrencyParse';

function InterestContent({ title, paragraph, inline = false }) {
  return (
    <div className={`interest-content ${inline ? 'inline' : ''}`}>
      <span className="interest-content__title">{title}</span>
      {paragraph && (
        <span className="interest-content__paragraph">{paragraph}</span>
      )}
    </div>
  );
}

function InvestmentValue() {
  const { t } = useTranslation();
  const { mentor } = useMentor();
  const { parseCurrencyValue } = useCurrencyParse();
  const hasSomePresentialValue = Boolean(
    mentor.valueFourHours || mentor.valueSixHours || mentor.valueEightHours,
    mentor.valueTenHours
  );

  return (
    <MentorTabContainer>
      <InterestContent title={t('traveler.resume.packages.onlineMeeting')} />
      <InterestContent
        title={t('traveler.resume.packages.package1h')}
        paragraph={parseCurrencyValue(mentor.valueHourVirtual)}
        inline
      />

      <Divider />

      {hasSomePresentialValue && (
        <>
          <InterestContent
            title={t('traveler.resume.packages.presentialMeeting')}
          />

          {mentor.valueFourHours && (
            <InterestContent
              title={t('traveler.resume.packages.package4h')}
              paragraph={parseCurrencyValue(mentor.valueFourHours)}
              inline
            />
          )}
          {mentor.valueSixHours && (
            <InterestContent
              title={t('traveler.resume.packages.package6h')}
              paragraph={parseCurrencyValue(mentor.valueSixHours)}
              inline
            />
          )}
          {mentor.valueEightHours && (
            <InterestContent
              title={t('traveler.resume.packages.package8h')}
              paragraph={parseCurrencyValue(mentor.valueEightHours)}
              inline
            />
          )}
          {mentor.valueTenHours && (
            <InterestContent
              title={t('traveler.resume.packages.package10h')}
              paragraph={parseCurrencyValue(mentor.valueTenHours)}
              inline
            />
          )}
        </>
      )}
    </MentorTabContainer>
  );
}

export default InvestmentValue;
