import { CheckCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { DatePicker, Form } from 'antd';
import { ContentaInputNumber } from 'components/Forms/ContentaInputNumber';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { CURRENCIES } from 'constants/currencies';
import { useUser } from 'contexts/userContext';
import { useTranslation } from 'react-i18next';
import localeEn from 'antd/es/date-picker/locale/en_US';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useLanguage } from 'contexts/languageContext';

function GiftCardValueForm({ form, howToGift, setHowToGift }) {
  const { t } = useTranslation();
  const { userCurrency } = useUser();
  const { currentLanguage } = useLanguage();

  const giftCardDataName = form.getFieldsValue()?.name;
  const countryCode = CURRENCIES.find(
    (c) => c.code === userCurrency.currencyStandard
  )?.countryCode;

  const handleChooseOption = (chooseOption) => {
    setHowToGift(chooseOption);
    form.setFieldsValue({
      howToGift: chooseOption,
    });
  };

  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <ContentaInputGroup>
        <Form.Item
          name="value"
          label={t('giftCardModal.desiredValue')}
          className="w-100 gift-modal__input-currency"
          rules={[
            {
              required: true,
              message: t('guides.chooseAOptionRequired'),
            },
          ]}
        >
          <ContentaInputNumber
            currency={userCurrency?.currencyStandard}
            locale={countryCode}
            className="w-100 is-number-input"
            onChange={(value) => {
              form.setFieldsValue({
                value: value,
              });
            }}
          />
        </Form.Item>
      </ContentaInputGroup>

      <ContentaInputGroup>
        <Form.Item
          label={t('giftCardModal.sendDate')}
          name={`sendDate`}
          rules={[
            {
              required: true,
              message: t('guides.chooseAOptionRequired'),
            },
          ]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="00/00/0000"
            locale={currentLanguage === 'PT-BR' ? locale : localeEn}
          />
        </Form.Item>
      </ContentaInputGroup>

      <div className="gift-modal__choose">
        <div
          className="gift-modal__choose-option"
          onClick={() => handleChooseOption('wantChooseAdvisor')}
        >
          {howToGift === 'wantChooseAdvisor' ? (
            <CheckCircleTwoTone twoToneColor="#00000066" />
          ) : (
            <div className="gift-modal__empty-circle" />
          )}
          <UserOutlined style={{ color: '#D82EA1', fontSize: '32px' }} />
          <span>{t('giftCardModal.wantChooseAdvisor')}</span>
        </div>

        <div
          className="gift-modal__choose-option"
          onClick={() => handleChooseOption('chooseOption')}
        >
          {howToGift === 'chooseOption' ? (
            <CheckCircleTwoTone twoToneColor="#00000066" />
          ) : (
            <div className="gift-modal__empty-circle" />
          )}
          <UserOutlined style={{ color: '#4036D3', fontSize: '32px' }} />
          <span>
            {t('giftCardModal.wantUserChoose').replace(
              '${USER}',
              giftCardDataName || t('giftCardModal.giftedName')
            )}
          </span>
        </div>
      </div>
    </Form>
  );
}

export default GiftCardValueForm;
