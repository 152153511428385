import ReactDOM from 'react-dom';
import * as S from './styles';

export function Loader() {
  return ReactDOM.createPortal(
    <S.Container>
      <div className="spinner-box">
        <div className="circle-border">
          <div className="circle-core" />
        </div>
      </div>
    </S.Container>,
    document.getElementById('loader-root')
  );
}
