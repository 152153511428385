import FILTERS_OPTIONS from 'constants/filters-options';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpecialtiesService from 'services/SpecialtiesService';

import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

function SearchFilterIcons({ handleFilterClick }) {
  const { t } = useTranslation();
  const [topics, setTopics] = useState([]);

  const getTopics = async () => {
    try {
      const data = await SpecialtiesService.getTopics();
      setTopics(data.sort((a, b) => a.topicOrder - b.topicOrder));
    } catch (error) {
      setTopics([]);
    }
  };

  useEffect(() => {
    getTopics();
  }, []);

  const getIcon = (code) => FILTERS_OPTIONS[code] || 'no icon';

  return (
    <S.SearchHomeFilters>
      <Swiper
        spaceBetween={16}
        slidesPerView={'auto'}
        className="search-filters"
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
      >
        {topics.map(({ name, code }) => (
          <SwiperSlide
            className="search-home__filter"
            key={code}
            onClick={() => {
              handleFilterClick([{ id: code, name }]);
            }}
          >
            {getIcon(code)}
            <span> {t(`specialties.${name}`)}</span>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.SearchHomeFilters>
  );
}

export default SearchFilterIcons;
