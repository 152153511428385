import IconBrazil from 'components/Icons/Flags/brazil';
import FranceIconFlag from 'components/Icons/Flags/france';
import GermanyIconFlag from 'components/Icons/Flags/germany';
import ItalyIconFlag from 'components/Icons/Flags/italy';
import JapanIconFlag from 'components/Icons/Flags/japan';
import KoreaIconFlag from 'components/Icons/Flags/korea';
import PortugalFlagIcon from 'components/Icons/Flags/portugal';
import IconSpain from 'components/Icons/Flags/spain';
import IconUSA from 'components/Icons/Flags/USA';

import brImage from 'assets/images/flags/BR.png';
import ptImage from 'assets/images/flags/PT.png';
import usImage from 'assets/images/flags/US.png';
import deImage from 'assets/images/flags/DE.png';
import esImage from 'assets/images/flags/ES.png';
import frImage from 'assets/images/flags/FR.png';
import itImage from 'assets/images/flags/IT.png';
import jpImage from 'assets/images/flags/JP.png';
import krImage from 'assets/images/flags/KR.png';

export const FlagsCode = {
  'PT-BR': <IconBrazil />,
  'PT-PT': <PortugalFlagIcon />,
  EN: <IconUSA />,
  DE: <GermanyIconFlag />,
  ES: <IconSpain />,
  FR: <FranceIconFlag />,
  IT: <ItalyIconFlag />,
  JA: <JapanIconFlag />,
  KO: <KoreaIconFlag />,
};

export const FlagsCodeImage = {
  'PT-BR': <img src={brImage} alt="Brazil" />,
  'PT-PT': <img src={ptImage} alt="Portugal" />,
  EN: <img src={usImage} alt="USA" />,
  DE: <img src={deImage} alt="Germany" />,
  ES: <img src={esImage} alt="Spain" />,
  FR: <img src={frImage} alt="France" />,
  IT: <img src={itImage} alt="Italy" />,
  JA: <img src={jpImage} alt="Japan" />,
  KO: <img src={krImage} alt="Korea" />,
};
