import { useLocation } from 'react-router-dom';
import { ContentaBreadcrumb } from '../../../../components/ContentaBreadcrumb';
import { useMentor } from '../mentorContext';

function MentorDetailsHeader() {
  const { mentor } = useMentor();
  const { state } = useLocation();
  const advisorState = state?.advisor;

  const breadcrumbItems = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/search-advisor',
      label: 'breadcrumb.advisors',
    },
    {
      label: mentor?.name || advisorState?.name,
    },
  ];

  return <ContentaBreadcrumb items={breadcrumbItems} />;
}

export default MentorDetailsHeader;
