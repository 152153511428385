import { Divider } from 'antd';
import {
  ContentaButtonDanger,
  ContentaButtonPrimary,
  ContentaButtonPrimaryOutline,
} from 'components/Styled/ContentaButton';
import { ContentaModal } from 'components/Styled/ContentaModal';
import useCurrencyParse from 'hooks/useCurrencyParse';
import { useTranslation } from 'react-i18next';
import { RescheduleModalContainer } from './styles';
import BookingService from 'services/BookingService';
import getTimeZone from 'utils/getTimeZone';
import { useState } from 'react';
import { errorHandler } from 'utils/errorHandler';
import { isUserTraveller } from 'utils/isRole';
import { useUser } from 'contexts/userContext';
import errorLogger from 'utils/errorLogger';

function RescheduleAlertModal({
  schedule,
  isModalOpen,
  handleClose,
  scheduleModalContent,
  confirmRescheduleAskedByTravellerAdvisor,
}) {
  if (!schedule || !isModalOpen || confirmRescheduleAskedByTravellerAdvisor)
    return null;
  const [isProcessing, setIsProcessing] = useState(false);

  const { t } = useTranslation();
  const { userRole } = useUser();
  const { parseCurrencyValue } = useCurrencyParse();

  let texts = {};
  if (scheduleModalContent?.isOnline && scheduleModalContent?.isLessThan48h) {
    texts = {
      paragraph1: t('agenda.rescheduleModal.paragraph1'),
      paragraph2: t('agenda.rescheduleModal.paragraph2'),
      paragraph3: t('agenda.rescheduleModal.paragraph3').replace(
        '${EXTRA_COST_VALUE}',
        parseCurrencyValue(schedule?.scheduleValue)
      ),
    };
  } else {
    texts = {
      paragraph1: t('agenda.rescheduleModal.paragraph1'),
    };
  }

  texts.title = t('agenda.rescheduleModal.title');

  const handleConfirmReschedule = async () => {
    setIsProcessing(true);
    const data = {
      startsAt: scheduleModalContent?.schedule.startAt,
      timeZone: getTimeZone(),
    };
    try {
      await BookingService.rescheduleBooking({ bookingId: schedule.id, data });
    } catch (error) {
      errorLogger({
        error,
        message: `[RESCHEDULE][TRAVELLER] Error rescheduling booking`,
      });

      errorHandler({
        error,
        typeError: 'FULL_MESSAGE',
        keyMessage: t('agenda.rescheduleModal.error'),
      });
    }
    setIsProcessing(false);
  };

  return (
    <ContentaModal
      open={isModalOpen}
      onCancel={handleClose}
      className="contenta-modal"
      hasFooter={false}
      footer={null}
      title={texts.title}
    >
      {isUserTraveller(userRole) ? (
        <RescheduleModalContainer>
          <p>{texts.paragraph1}</p>
          <p>{texts.paragraph2}</p>
          {texts.paragraph3 && <p>{texts.paragraph3}</p>}

          <Divider />

          <footer className="contenta-modal__footer w-100">
            <ContentaButtonDanger
              className="w-100"
              onClick={handleConfirmReschedule}
              loading={isProcessing}
            >
              {t('agenda.rescheduleModal.rescheduleButton')}
            </ContentaButtonDanger>

            <br />

            <p>
              {t('mentorHome.notMember.doubts')}
              <a href="mailto:contactus@contenta360.com">
                contactus@contenta360.com
              </a>
            </p>
          </footer>
        </RescheduleModalContainer>
      ) : (
        <RescheduleModalContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: t('agenda.rescheduleModal.advisorText').replace(
                'TRAVELLER_NAME',
                schedule?.traveller?.name
              ),
            }}
          />

          <Divider />

          <footer className="contenta-modal__footer w-100">
            <ContentaButtonPrimaryOutline onClick={handleClose}>
              {t('agenda.rescheduleModal.cancelSolicitation')}
            </ContentaButtonPrimaryOutline>
            <ContentaButtonPrimary isMentor onClick={handleConfirmReschedule}>
              {t('agenda.rescheduleModal.agree')}
            </ContentaButtonPrimary>

            <br />
          </footer>
        </RescheduleModalContainer>
      )}
    </ContentaModal>
  );
}

export default RescheduleAlertModal;
