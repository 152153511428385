import { Layout } from 'antd';
import styled from 'styled-components';

import bgImg from 'assets/images/background-mentor-app.png';

export const Container = styled(Layout)`
  min-height: calc(100vh - 255px);
`;

export const Wrapper = styled(Layout.Content).attrs({
  hasBgImg: false,
  isRegister: false,
})`
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  ${({ hasBgImg }) =>
    hasBgImg &&
    `
      background-image: url(${bgImg});
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
  `}

  /* if is not register page: */
  ${({ isRegister }) =>
    !isRegister &&
    `
      padding: 0 ${({ theme }) => theme.spacing.md};
  `}

  @media (${({ theme }) => theme.breakpoints.md}) {
    padding: 0 48px ${({ theme }) => theme.spacing.md};
  }

  @media (${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 60px 37px;
  }
`;
