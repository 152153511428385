import { format } from 'date-fns';
import { useMemo } from 'react';

function ScheduleSlot({
  availableTimesWithDays,
  handleClick,
  index,
  indexHour,
  selected,
  time,
}) {
  const presentialTour = selected?.presentialTour;
  const idSlot = `day${index}-hour${indexHour}`;
  const isSelected = idSlot === selected?.id ? 'selected' : '';
  const isInArray = presentialTour?.selectedSlots?.some(
    ({ id }) => id === idSlot
  );
  const isDisabled = isInArray ? '--disabled' : '';

  const formattedDate = useMemo(
    () => format(availableTimesWithDays[index].day, 'yyyy-MM-dd'),
    [index, availableTimesWithDays]
  );

  const timeLabel = useMemo(
    () => time.startTime?.split(':').slice(0, 2).join(':'),
    [time.startTime]
  );

  const handleClickWrapper = () => {
    handleClick({
      schedule: time,
      date: formattedDate,
      presentialTour,
      id: `day${index}-hour${indexHour}`,
    });
  };

  return (
    <div
      className={`available-schedules__time ${`day${index}-hour${indexHour}`} ${isSelected} ${isDisabled}`}
      onClick={handleClickWrapper}
    >
      <span>{timeLabel}</span>
    </div>
  );
}

export default ScheduleSlot;
