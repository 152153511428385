import { Layout } from 'antd';
import { FIXED_VALUES } from 'constants/css-fixed-values';
import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

const { pathname } = window.location;
const isHome = pathname === '/' || pathname === '/home';
const getBackgroundNavbar = ({ theme, isMentor, isUnlogged }) => {
  if (isHome && isUnlogged) {
    return theme.colors.neutralWhite[10];
  }

  if (!isUnlogged && !isMentor) {
    return theme.colors.linearGradient.pinkPurple;
  }

  if (isMentor && !isUnlogged) {
    return `linear-gradient(90deg, #1A00FF 0%, #3B6EFC 21%, #0DC9FA 66%, #1A00FF 89%)`;
  }

  return theme.colors.neutralWhite[10];
};

const getPhraseColor = ({ theme, isMentor, isUnlogged }) => {
  if (isHome && isUnlogged) {
    return theme.colors.neutralBlack[20];
  }
  if (isMentor) {
    return theme.colors.neutralWhite[10];
  }

  return theme.colors.neutralWhite[10];
};

const getIconColor = ({ theme, isMentor, isUnlogged }) => {
  if (isHome && isUnlogged) {
    return theme.colors.neutralBlack[20];
  }

  if (isUnlogged) {
    return theme.colors.pink[30];
  }

  if (isMentor) {
    return theme.colors.neutralWhite[10];
  }

  return theme.colors.neutralWhite[10];
};

export const Header = styled(Layout.Header)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.yellow[30]};
  position: relative;
  padding: 0;

  .header__content {
    padding-left: 4px;
    padding-right: 4px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-left: ${(props) => props.theme.spacing.md};
      padding-right: ${(props) => props.theme.spacing.md};
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      padding-left: ${(props) => props.theme.spacing.xl};
      padding-right: ${(props) => props.theme.spacing.xl};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      padding-left: ${(props) => props.theme.spacing.xxl};
      padding-right: ${(props) => props.theme.spacing.xxl};
    }

    align-items: center;
    background: ${({ theme, isMentor, isUnlogged }) =>
      getBackgroundNavbar({
        theme,
        isMentor,
        isUnlogged,
      })};
    display: flex;
    height: ${FIXED_VALUES.navbar}px;
    height: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    &.header-traveler {
      justify-content: flex-start;
    }

    &-leftside {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        justify-content: flex-start;
        width: 100%;
      }
    }

    .header__content-language {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
    }

    .header__content-language-flag {
      width: 24px;
      height: 24px;
    }

    .header__tag-version {
      align-items: center;
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
      display: flex;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: ${convertPxToRem(12)};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      text-align: center;

      &.--dark {
        color: ${({ theme }) => theme.colors.neutralBlack[10]};
      }
    }

    &-logo-container {
      display: flex;
      z-index: 3;

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }

    &-logo {
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        width: 30px;
      }

      margin-left: 16px;
      margin-right: 16px;
      width: 170px;
    }

    &-logo-phrase {
      display: none;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        color: ${({ theme, isMentor, isUnlogged }) =>
          getPhraseColor({ theme, isMentor, isUnlogged })};
        display: block;
        font-family: ${({ theme }) => theme.fonts.texts};
        font-size: ${convertPxToRem(16)};
        font-weight: 800;
        letter-spacing: 1.25px;
        font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      }

      &.--gray {
        color: ${({ theme }) => theme.colors.neutralBlack[20]};
        font-weight: 800;
      }
    }

    &-rightside {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      gap: 6px;
      justify-content: center;
      padding-right: 6px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        gap: 16px;
        padding-right: 0;
      }

      .ant-dropdown-trigger {
        display: flex;
      }
    }
  }

  .header__button {
    background: transparent;
    cursor: pointer;
    border: none;
    display: flex;
  }
`;

export const Username = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    color: ${({ theme, isGray }) =>
      isGray ? theme.colors.neutralBlack[20] : theme.colors.neutralWhite[10]};
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    position: relative;
  }
`;

export const MenuOptions = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    flex-flow: row nowrap;
  }

  .menu-options__option {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  .menu-options__icon {
    margin-right: 4px;
  }

  .flag-menu {
    .flag-menu__language {
      color: ${({ theme }) => theme.colors.light};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(12)};
    }
    .anticon {
      color: ${({ theme }) => theme.colors.light};
    }
    .flag-icon {
      position: relative;
      top: 2px;
    }
  }

  .menu-options__label {
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    display: flex;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    line-height: 14px;

    .anticon {
      font-size: 8px;
      margin-left: 2px;
    }
  }
`;
