import { Tabs } from 'antd';
import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const MentorTabs = styled(Tabs)`
  margin-top: 24px;

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.blue[20]};
  }
  .ant-tabs-tab-btn,
  .ant-tabs-tab-remove {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.blue[40]};
    font-weight: 800;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 48px;
    }
  }

  .interest-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: ${convertPxToRem(16)};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

    &.inline {
      flex-direction: row;
      gap: 8px;
    }

    &__title {
      color: ${(props) => props.theme.colors.neutralBlack[40]};
      font-style: normal;
      font-weight: 800;
      letter-spacing: 1.25px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__paragraph {
      font-style: normal;
      font-weight: 400;
      color: ${(props) => props.theme.colors.neutralBlack[20]};

      line-height: 22px;
      letter-spacing: 1.25px;
    }
  }
`;

export const MentorTabContainer = styled.div`
  .mentor-tab__description {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: 1rem;
    font-weight: 400;
  }

  .mentor-tab__evaluation {
    .mentor-tab__evaluation-header {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 16px;

      .mentor-tab__evaluation-header__user-name {
        color: ${({ theme }) => theme.colors.neutralBlack[30]};
        font-family: ${({ theme }) => theme.fonts.texts};
        font-size: ${convertPxToRem(16)};
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 125% */
        letter-spacing: 1.25px;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: 1.25px;
    }
  }
`;
