import HttpClient from './utils/HttpClient';

class AssessmentService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async submitAssessment({ data, bookingId, isTraveler }) {
    if (isTraveler) {
      return await this.httpClient.post(
        `/assessment/${bookingId}/advisor`,
        data
      );
    }
    return await this.httpClient.post(
      `/assessment/${data.bookingId}/traveler`,
      data
    );
  }

  async getAssessment({ id, isTraveler }) {
    if (isTraveler) {
      return await this.httpClient.get(`/assessment/${id}/advisor`);
    }
    return await this.httpClient.get(`/assessment/${id}/traveler`);
  }

  // GET /assessment/{advisorId}/advisor/list?page=0&size=10
  async getAssessmentList({ userId, page, size }) {
    return this.httpClient.get(
      `/assessment/${userId}/advisor/list?page=${page}&size=${size}`
    );
  }
}

export default new AssessmentService();
