import { Divider } from 'antd';

import AdvisorIdioms from './AdvisorIdioms';
import ConsultancyInputs from './ConsultancyInputs';
import ScrollToTop from 'components/ScrollToTop';

export function ThirdStep() {
  return (
    <>
      <ScrollToTop />
      <ConsultancyInputs />
      <Divider />
      <AdvisorIdioms />
    </>
  );
}
