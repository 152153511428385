import { Divider } from 'antd';
import { ContentaButton } from 'components/Styled/ContentaButton';
import { useTranslation } from 'react-i18next';
import { authService } from 'services/Auth0Service';
import googleIcon from '../../../../assets/images/icons/google.png';

const REDIRECT_URI = `${window.location.origin}/redirecting`;

const LoginByGoogle = ({ queryParam, onClosePopup }) => {
  const { t } = useTranslation();
  const onLoginByGoogle = () => {
    let url = `${REDIRECT_URI}?connection=google-oauth2`;
    if (queryParam) {
      url += queryParam;
    }

    authService.popup.authorize(
      {
        redirectUri: url,
        connection: 'google-oauth2',
      },
      (err) => {
        if (err) {
          console.error('Google Login error:', err);
          onClosePopup();
          return;
        }
      }
    );
  };

  return (
    <>
      <Divider />

      <ContentaButton
        className="w-100"
        onClick={() => onLoginByGoogle()}
        aria-label="Login with Google"
      >
        <img src={googleIcon} alt="Google icon" className="mr-8" width="16" />
        {t('login.loginGoogle')}
      </ContentaButton>
    </>
  );
};

export default LoginByGoogle;
