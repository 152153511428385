import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const SingleRatingStarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  margin-top: ${(props) => (props.noMargin ? '0' : '16px')};

  .rating__container {
    height: auto;
    width: 25px;
  }

  .rating__value {
    color: ${({ theme }) => theme.colors.neutralBlack[30]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(12)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1.25px;
    line-height: 20px;
  }
`;
