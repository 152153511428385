import { useMentor } from '../../mentorContext';
import MentorEvaluations from '../../MentorEvaluations';
import MentorScheduleMeet from '../../MentorScheduleMeet';
import ScheduleModal from 'components/ScheduleModal';

import bgImg from 'assets/images/default-city.webp';

const Prices = () => {
  const {
    advisor,
    availableSchedules,
    isModalVisible,
    loadingSchedules,
    setAvailableSchedules,
    setIsModalVisible,
  } = useMentor();
  const { coverUrl } = advisor;
  return (
    <div>
      <div
        className="mentor-about__banner"
        style={{ backgroundImage: `url(${coverUrl || bgImg})` }}
      />
      <MentorScheduleMeet />

      <MentorEvaluations />

      <ScheduleModal
        advisor={advisor}
        availableSchedules={availableSchedules}
        isLoading={loadingSchedules}
        isOpen={isModalVisible}
        setAvailableSchedules={setAvailableSchedules}
        setOpen={setIsModalVisible}
      />
    </div>
  );
};

export default Prices;
