import { useTranslation } from 'react-i18next';
import ScheduleSlots from './ScheduleSlots';
import SkeletonLoading from './SkeletonLoading';
import { v4 as uuidv4 } from 'uuid';
import * as S from './styles';

function AvailableSchedules({
  availableSchedules,
  availableTimesWithDays,
  handleSelectPresentialSlot,
  isLoading,
  loadingDates,
  onClick,
  presentialTour,
  selected,
  setSelected,
}) {
  const { t } = useTranslation();

  const handleClick = ({ schedule, date, presentialTour, id }) => {
    if (presentialTour) {
      setSelected({ id, time: schedule.startTime, date });
      handleSelectPresentialSlot({
        id,
        time: schedule.startTime,
        date,
      });
    } else {
      setSelected({ id, time: schedule.startTime, date });
    }

    onClick(schedule, date, presentialTour, id);
  };

  if (isLoading || loadingDates) {
    return <SkeletonLoading />;
  }

  if (!availableSchedules?.length && !loadingDates && !isLoading) {
    return <div>{t('common.noAvailableSchedules')}</div>;
  }

  return (
    <S.AvailableScheduleContainer className="contenta-scroll">
      {availableSchedules?.map((schedule, index) => (
        <ScheduleSlots
          availableTimesWithDays={availableTimesWithDays}
          handleClick={handleClick}
          index={index}
          key={uuidv4()}
          presentialTour={presentialTour}
          schedule={schedule}
          selected={selected}
        />
      ))}
    </S.AvailableScheduleContainer>
  );
}

export default AvailableSchedules;
