import { format, parseISO } from 'date-fns';

function formatDateCompleteFormat({ date, time }) {
  const timeString = `${date}T${time}`;

  const isoDate = parseISO(timeString);

  return format(isoDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}

export default formatDateCompleteFormat;
