import { Loader } from 'components/Loader';
import ScrollToTop from 'components/ScrollToTop';
import { useState } from 'react';
import unloggedFetcher from 'services/unloggedFetcher';
import useSWR from 'swr';
import useDebounce from '../../hooks/useDebounce';
import FAQHeader from './Header';
import MostAsked from './MostAsked';
import { useLanguage } from 'contexts/languageContext';

export default function FAQ() {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { faqLanguage } = useLanguage();

  const { data, isValidating } = useSWR(
    `/faq?languageCode=${faqLanguage}&page=-1&question=${debouncedSearch}`,
    unloggedFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  return (
    <>
      <ScrollToTop />
      {isValidating && <Loader />}
      <FAQHeader setSearch={setSearch} search={search} />
      <MostAsked faq={data?.content || []} />
    </>
  );
}
