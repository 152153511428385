import { Skeleton } from 'antd';
import ScheduleModal from 'components/ScheduleModal';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useTranslation } from 'react-i18next';
import MentorAbout from '../MentorAbout';
import { useMentor } from '../mentorContext';
import MentorEvaluations from '../MentorEvaluations';
import MentorScheduleMeet from '../MentorScheduleMeet';
import MentorSpecialties from '../MentorSpecialties';

import * as S from './styles';

const MobileView = () => {
  const {
    advisor,
    availableSchedules,
    isModalVisible,
    loading,
    loadingSchedules,
    setAvailableSchedules,
    setIsModalVisible,
  } = useMentor();

  const videoUrl = advisor?.videoUrl || null;
  const { t } = useTranslation();

  const renderCTA = () => {
    if (loading) {
      return (
        <div className="mentor-details__cta">
          <Skeleton.Button active size="large" />
        </div>
      );
    }

    return (
      <div className="mentor-details__cta">
        <ContentaButtonPrimary onClick={() => setIsModalVisible(true)}>
          {t('mentorProfile.bookTime')}
        </ContentaButtonPrimary>
      </div>
    );
  };

  return (
    <>
      {renderCTA()}

      {videoUrl && (
        <S.AdvisorMobileVideo>
          <video controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </S.AdvisorMobileVideo>
      )}

      <MentorSpecialties />

      <MentorAbout />

      <MentorScheduleMeet />

      <MentorEvaluations />

      <div className="mb-24">{renderCTA()}</div>

      <ScheduleModal
        advisor={advisor}
        availableSchedules={availableSchedules}
        isLoading={loadingSchedules}
        isOpen={isModalVisible}
        setAvailableSchedules={setAvailableSchedules}
        setOpen={setIsModalVisible}
      />
    </>
  );
};

export default MobileView;
