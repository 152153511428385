import { useTranslation } from 'react-i18next';
import * as S from './styles';
import ScrollToTop from 'components/ScrollToTop';

function Privacy() {
  const { t } = useTranslation();
  return (
    <S.Container>
      <ScrollToTop />
      <h1>{t('privacyPolicy.title')}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: t('privacyPolicy.content') }}
        className="privacy__content"
      />
    </S.Container>
  );
}

export default Privacy;
